<template>
  <div>
    <!-- 批量验车 -->
    <WindowModal :windowShow="batchOperateShow" @statusShow="closeWindow">
      <template #titleContent>
        <div>
          批量操作
        </div>
      </template>
      <template #leftContent>
        <BatchTable ref="batchTableRef" v-if="batchOperateShow" :show="batchOperateShow" @operateData="operateData" />
      </template>
      <template #rightContent>
        <BatchOperate v-if="batchOperateShow" ref="batchOperateRef" :batchData="batchData" @status="loadTable" />
      </template>
    </WindowModal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import WindowModal from '@/views/components/windowModal/index.vue'
import BatchTable from '@/views/components/transport/smallVehicleOperate/batchTable'
import BatchOperate from '@/views/components/transport/smallVehicleOperate//batchOperate.vue'


const batchOperateShow = ref(false)
const batchTableRef = ref(null)
const batchOperateRef = ref(null)
const batchData = ref({})  // 批量操作组件传递数组

// 批量操作传递数据
const operateData = e => {
  if (e.orderId) {
    batchData.value = e
    setTimeout(() => {
      batchOperateRef.value.getData()
    }, 100)
  }
}
const loadTable = () => {
  batchOperateShow.value = false
}
const closeWindow = e => {
  batchOperateShow.value = e
  setTimeout(() => {
    if (batchTableRef.value) batchTableRef.value.clearData()
  }, 100)
}

defineExpose({
  batchOperateShow
})
</script>

<style lang="less" scoped>
</style>