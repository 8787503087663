<template>
  <div>
    <a-modal :visible="visible" title="报价查询" width="80%" footer="" @cancel="cancel" :bodyStyle="{padding:0,background:'#F4F5F7'}">
      <div class="flex ju-between">
        <div class="leftBox">
          <Inquiry @queryHistory="queryHistory"></Inquiry>
        </div>
        <div class="leftBox">
          <div class="title">
            <div style="font-weight: 700;" class="fz-16">历史发运数据</div>
            <span style="color:#6B778C">默认显示最近10条，数据时间较长的请咨询您的组长</span>
          </div>
          <div class="content">
            <template v-if="loading">
             <div class="flex ju-center al-center" style="height: 200px;width: 100%;">
              <a-spin />
             </div>
            </template>
            <template v-if="!loading">
            <div v-for="item in historyData" :key="item.id" class="itemBox">
              <div class="flex ju-between">
                {{ item.carrierName }}<span>{{ item.departureTime }}</span>
              </div>
              <div class="flex  m-t1 ju-between">
               <div class="flex ">
                <div style="width: 200px;">
                  {{ item.departure }}
                </div>
                <div>
                  ￥{{ item.transportFee }}
                </div>
               </div>
                <div>
                  时效：<span v-if="item.ageingDay">{{item.ageingDay}}天</span><span v-if="item.ageingHour">{{item.ageingHour}}小时</span>
                </div>
              </div>
            </div>
          </template>
          </div>
        </div>
      </div>

    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { SwapOutlined, SearchOutlined } from '@ant-design/icons-vue';
import { offerTransportHis } from '@/api/newTransport/newTransport'
import Inquiry from './inquiry.vue'

const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const loading = ref(false)
const historyData = ref([])
const queryHistory = (e) => {
  reqOfferTransportHis(e)
}
const reqOfferTransportHis = (e) => {
  loading.value = true
  offerTransportHis({
    startAreaId: e.startAreaId,
    startCityId: e.startCityId,
    startProvinceId: e.startProvinceId,
    endAreaId: e.endAreaId,
    endCityId: e.endCityId,
    endProvinceId: e.endProvinceId,
  }).then(res => {
    if (res.code !== 10000) return
    historyData.value = res.data
  }).finally(() => {
    loading.value = false
  })
}



const cancel = () => {
  emit('update:visible', false)
}
</script>

<style lang="less" scoped>
.leftBox {
  width: 49.5%;
  height: 100%;
  background: #fff;
}
.iconBox {
  width: 120px;
  height: 32px;
  background: #0066cc;
  border-radius: 2px;
}
.tabBox {
  width: 80px;
  height: 35px;
  border: 1px solid #c1c7d0;
  border-right: transparent;
}
.bor-right {
  border-right: 1px solid #c1c7d0;
}

.changeStyle {
  background: #0066cc;
  border-color: #0066cc;
  color: #fff;
}

.title {
  background: #fff;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.content {
  padding: 10px;
  min-height: 200px;
}

.itemBox {
  background: #eeeeee;
  padding: 8px 15px;
  margin-top: 10px;
}
.info{
  div {
    width: 33%;
  }
}
</style>