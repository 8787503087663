<template>
  <div class="monthly">
    <div class="flex al-center ju-between">
      <div class="flex al-center">
        <span class="title">月指标</span>
        <a class="m-l2" @click="setting">+设置</a>
      </div>
      <div>
        <SearchList @searchAdd="searchAdd" :loading="loading" @resetBtn="resetBtn" :iptData="iptData"></SearchList>
      </div>
    </div>
    <div>
      <a-table size="small" :columns="columns" bordered :row-key="record => record.id" :loading="loading" :data-source="dataSource" @change="handleTableChange"  :pagination="pagination">
        <template #add="{ record }">
          <a @click="edit(record)">编辑</a>
        </template>
      </a-table>
    </div>
    <a-modal :confirmLoading="loadingBtn" v-model:visible="visible" :title="title" width="25%" @ok="submit" :destroyOnClose="true" @cancel="cancel">
      <FormList @select="select" @getVal="getVal" ref="formListRef" @search="search" :disabledDate="disabledDate" :fromList="fromList"></FormList>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { mtcPage, mtcAdd, mtcEdit, detailsId } from '@/api/marketingConsole/marketingConsole'
import SearchList from '@/components/searchList'
import FormList from '@/components/formList'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { transitionTimeYMD, transitionTimeYM,debounce, IfNull } from '@/utils/util'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { employeeList } from '@/api/crmManagement/setRules'
const store = useStore()

const empId = ref('')
const title = ref('')
const editID = ref(null)
const dataSource = ref([])
const loadingBtn = ref(false)
const formListRef = ref(null)
const loading = ref(false)
const visible = ref(false)
const searchMsg = ref({})
const state= reactive({
  timer:null
})
const pagination = ref({
  current: 1,
  pageSizeOptions: ['10', '20', '30'],
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const handleTableChange = (pag, filters, sorter) => {
  pagination.value.current = pag.current
  pagination.value.pageSize = pag.pageSize
  reqMonthTargetPage()
}
const select = (e)=>{
  empId.value = e.title
}
const search = (e) => {
  if(!IfNull(e)) return
  debounce(state,getEmployeeList,1000,e)
}
const getEmployeeList = (e) => {
  employeeList({
    name: e,
  }).then(res => {
    if (res.code === 10000) {
      let list = []
      list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      fromList.value[0].opt = list
    }
  })
}

const edit = (e) => {
  detailsId(e.id).then( res => {
    if(res.code!== 10000) return
    editID.value = e.id
    fromList.value[0].disabled = true
    fromList.value[0].value = res.data.empName
    fromList.value[1].disabled = true
    fromList.value[1].value = moment(res.data.month)
    fromList.value[2].list = res.data.channelSubIds
    fromList.value[2].value = []
    fromList.value[3].value = String(res.data.clueNum)
    fromList.value[4].value = Number(res.data.consumption)
    title.value = '编辑月指标'
    visible.value = true
  })
}
const setting = () => {
  fromList.value.forEach(item => {
    item.value = null
    item.disabled = false
    item.list = []
  })
  fromList.value[0].opt = []
  title.value = '添加月指标'
  editID.value = null
  visible.value = true
}
// 禁用时间
const disabledDate = current => {
  return current && current < moment().subtract(1, 'months')
}

const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = cloneDeep(e)
  searchMsg.value.month = transitionTimeYM(e.month) 
  reqMonthTargetPage()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  reqMonthTargetPage()
}
const getVal = (e) => {
  let msg = cloneDeep(e)
  msg.month = transitionTimeYM(e.month)
  msg.channelSubIds = fromList.value[2].list
  msg.empId = empId.value
  if(msg.channelSubIds.length === 0){
    message.error('请添加关联渠道')
    return
  }
  loadingBtn.value = true
  if (!editID.value) {
    mtcAdd({
      ...msg
    }).then(res => {
      if (res.code !== 10000) return
      message.success('添加成功')
      visible.value = false
      reqMonthTargetPage()
    }).finally(() => {
      loadingBtn.value = false
    })
  } else {
    mtcEdit({
      ...msg,
      id: editID.value
    }).then(res => {
      if (res.code !== 10000) return
      message.success('编辑成功')
      visible.value = false
      reqMonthTargetPage()
    }).finally(() => {
      loadingBtn.value = false
    })
  }


}
const reqMonthTargetPage = () => {
  loading.value = true
  mtcPage({
    current:pagination.value.current,
    size: pagination.value.pageSize,
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const submit = () => {
  formListRef.value.getValRef()
}
onMounted(() => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  reqMonthTargetPage()
})

const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "选择月份",
    value: moment(new Date()),
    prop: "month",
    width: 160,
  },
])
const fromList = ref([
  {
    label: "业务员",
    name: "empName",
    type: "selectSearch",
    placeholder: "请选择业务员",
    value: null,
    prop: "empName",
    width: '100%',
    opt:[],
    rules: [{ required: true, message: "请选择业务员", trigger: "blur",type: "string" }],
  },
  {
    label: "选择月份",
    name: "month",
    type: "selectTimeYM",
    placeholder: "请选择月份",
    value: null,
    prop: "month",
    width: '100%',
    rules: [{ required: true, message: "请选择月份", trigger: "blur", type: "object" }],
    disabled: false
  },
  {
    label: "关联渠道",
    name: "channelSubIds",
    type: "cascaderMore",
    placeholder: "请选择关联渠道",
    value: null,
    prop: "channelSubIds",
    width: '100%',
    opt: store.state.enumAll.ChannelTypeEnum,
    list:[],
  },
  {
    label: "线索数",
    name: "clueNum",
    type: "input",
    placeholder: "请输入线索数",
    value: null,
    prop: "clueNum",
    width: '100%',
    rules: [{ required: true, message: "请输入线索数", trigger: "blur", }],
  },
  {
    label: "消费",
    name: "consumption",
    type: "inputNum",
    placeholder: "输入目标金额(元)",
    value: null,
    prop: "consumption",
    width: '100%',
    rules: [{ required: true, message: "请填写", trigger: "blur", type: "number" }],
  },
])
const columns = ref([
  {
    title: '业务员',
    dataIndex: 'empName',
    align: 'center',
  },
  {
    title: '月份',
    dataIndex: 'month',
    align: 'center',
  },
  {
    title: '关联渠道名',
    dataIndex: 'channelSubNames',
    align: 'center',
    width:250
  },

  {
    title: '线索数',
    dataIndex: 'clueNum',
    align: 'center',
  },
  {
    title: '消费',
    dataIndex: 'consumption',
    align: 'center',
  },
  {
    title: '最近修改时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    },
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.monthly {
  padding: 20px;
  background: #fff;
}
.title {
  font-size: 18px;
  color: #344563;
  font-weight: 700;
}
</style>