import { getRequest, putRequest, postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/trans/order/page', params)
}
export function abroadPage (params) {
  return postBodyRequest('/trans/order/abroad-page', params)
}

export function detail (id, params) {
  return getRequest(`/trans/order/${id}`, params)
}

export function add (params) {
  return postBodyRequest('/trans/order/internal', params)
}

export function edit (params) {
  return putRequest('/trans/order/internal', params)
}

export function appletModify (params) {
  return putRequest('/trans/applet/order/modify', params)
}

export function pushEdit (params) {
  return putRequest('/trans/order/push/modify', params)
}

export function push (id, params) {
  return postBodyRequest(`/trans/order/${id}/push`, params)
}

export function del (id) {
  return postBodyRequest(`/trans/order/${id}/delete`)
}

export function customerVehicles (customerId, params) {
  return postBodyRequest(`/trans/order/vehicles/${customerId}`, params)
}

export function orderChange (params) {
  return postBodyRequest('/trans/order/change', params)
}

export function orderOffer (params) {
  return postBodyRequest('/trans/order/offer', params)
}

export function orderOfferNoConfirm (params) {
  return postBodyRequest('/trans/order/offer-no-confirm', params)
}

export function evaluationPage (params) {
  return postBodyRequest('/trans/order/orderRemark/page', params)
}

export function getCooper (customerId, params) {
  return getRequest(`/market/crm/cooper/${customerId}/order`, params)
}

export function copyOrder (id, params) {
  return postBodyRequest(`/trans/order/copy/${id}`, params)
}

// 确认订单
export function confirmTransport (orderId, params) {
  return postBodyRequest(`/trans/order/${orderId}/confirm`, params)
}

//获取主体列表
export function getSubjectList (id) {
  return getRequest(`/trans/invoice/subject/list?subjectId=${id}`)
}