<template>
  <div class="">
    <div class="leftBox">
      <div v-for="item in list" :key="item.id" class="itemBox">
        <div class="title flex ju-between al-center">
          {{ item.title }}
          <Screen @change="change($event,item)" :idx="item.idx" v-if="item.subList.length>0" :clueDataStatisticsList="item.subList"></Screen>
        </div>
        <div>
          <div v-for="opt in screenAdd(item.subList,item.idx,item.listLabel)" :key="opt.id" class="nameBox flex ju-between">
            <div>{{ opt.cityName || opt.cityLineName || opt.tagName }}</div>
            <div>{{ opt.num }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Screen from '../../../components/screen'
import { hotRecommend } from '@/api/marketingConsole/marketingConsole'


const list = ref([
  {
    title: '热门起点城市',
    idx: 0,
    subList: [
    ],
    key:'startCityRankList',
    listLabel:'cityNumList'
  },
  {
    title: '热门终点城市',
    idx: 0,
    subList: [
    ],
    key:'endCityRankList',
    listLabel:'cityNumList'
  },
  {
    title: '热门线路',
    idx: 0,
    subList: [
    ],
    key:'cityLineRanList',
     listLabel:'cityLineNumList'
  },
  {
    title: '标签排行榜',
    idx: 0,
    subList: [
    ],
    key:'labelRankList',
    listLabel:'labelNumDTOList'
  }
])
const change = (val, item) => {
  item.idx = val
}

const reqHotRecommend = () => {
  hotRecommend({}).then(res => {
    if (res.code !== 10000) return
    list.value.forEach( item => {
      item.subList = res.data[item.key]
      item.idx =  item.subList[0]?.timeType.value
    })
  })
}
const screenAdd = (data, idx,listLabel) => {
  let list = []
  data.forEach(item => {
    if (item.timeType.value === idx) {
      list = item[listLabel]
    }
  })
  return list
}
onMounted(() => {
  reqHotRecommend()
})
</script>

<style lang="less" scoped>
.leftBox {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
}
.itemBox {
  width: 49%;
  padding: 10px;
  border: 1px solid #eee;
  margin-top: 10px;
}

.title {
  color: #333;
  font-weight: bold;
  font-size: 15px;
}

.nameBox {
  background: #edf0f9;
  padding: 10px;
  margin-top: 10px;
}
</style>