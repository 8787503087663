import * as echarts from 'echarts';



export const ConversionStatistics = (box, data, open) => {
  var chartDom = document.getElementById(box);
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        params = params[0];
        return (
          params.data.str
        );
      },
      axisPointer: {
        animation: false
      }
    },
    grid: {
      left: '2%',   // 左边距为容器宽度的10%
      right: '3%',
      top: '3%',
      containLabel: true // 包含坐标轴的标签
    },
    backgroundColor: '#fff',
    xAxis: {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      axisLabel:{
        interval:0,
        rotate:-90
      }
      
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    series: [
      {
        name: 'Fake Data',
        type: 'bar',
        showSymbol: false,
        barMaxWidth: '100',
        data: data,
        itemStyle: {
          color: function (params) {
            return params.data.color
          }
        }
      }
    ]
  };
  myChart.on('click', function (params) {
    if (open) {
      open(params)
    }
  })
  option && myChart.setOption(option)
}

