<template>
  <div>
    <!-- 新增 -->
    <a-modal :visible="visible" width="30%" @ok="onAddForm" @cancel="cancel" :confirmLoading="loading">
      <template #title>
        <span class="title">新增</span>
      </template>
      <div>
        <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
          <a-form-item label="客户电话" name="phoneNumber">
            <a-input v-model:value="addForm.phoneNumber" placeholder="填写电话" />
          </a-form-item>
          <a-form-item label="客户微信">
            <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
          </a-form-item>
          <a-form-item label="起始地点">
            <div style="display:flex;justify-content: space-around;align-items:center">
              <a-cascader style="width:45%" v-model:value="start" :show-search="{}" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="setStartAddress" placeholder="选择起始地" />
              <div>——</div>
              <a-cascader style="width:45%" v-model:value="end" :show-search="{}" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="setEndAddress" placeholder="选择目的地" />
            </div>
          </a-form-item>
          <a-form-item label="渠道来源" name="channelSubIds">
            <a-cascader v-model:value="addForm.channelSubIds" :options="$store.state.enumAll.ChannelTypeEnum" :changeOnSelect="true" placeholder="选择渠道" />
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea :rows="3" placeholder="填写备注" v-model:value="addForm.remark" />
          </a-form-item>
        </a-form>

      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { addPublic } from '@/api/crmManagement/comSea'
import { message } from 'ant-design-vue'
import { mobileValidate } from '@/utils/validate'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  tips: {
    type: String,
    default: ''
  }
})
const formRef = ref(null)
const emit = defineEmits(['update:visible', 'submit'])
const start = ref([])
const end = ref([])
const loading = ref(false)
const addForm = ref({
  wechat: '',
  phoneNumber: '',
  channelSubIds: [],
  startAddress: {},
  endAddress: {},
  channelId: '',
  channelSubId: ''
},
)
const rules = ref({
  phoneNumber: [{ required: false, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }],
  wechat: [{ required: false, message: '请填写微信号码', trigger: 'blur' }, { pattern: /[a-zA-Z0-9]{6,}/, message: '请填写正确的微信号码', trigger: 'blur' }],
  channelSubIds: { required: true, message: '请选择渠道来源', trigger: 'change', type: 'array' }
})
const setStartAddress = (value, selectedOptions) => {
     addForm.value.startAddress.province = value[0]
      if (value.length > 1) {
        addForm.value.startAddress.city = value[1]
      } else {
        addForm.value.startAddress.city = null
      }
      if (value.length > 2) {
        addForm.value.startAddress.area = value[2]
      } else {
        addForm.value.startAddress.area = null
      }

    }
    const setEndAddress = (value, selectedOptions) => {
      addForm.value.endAddress.province = value[0]
      if (value.length > 1) {
        addForm.value.endAddress.city = value[1]
      } else {
        addForm.value.endAddress.city = null
      }
      if (value.length > 2) {
        addForm.value.endAddress.area = value[2]
      } else {
        addForm.value.endAddress.area = null
      }
    }
const cancel = () => {
  emit('update:visible', false)
}
// 新增
const onAddForm = () => {
  if (addForm.value.phoneNumber !== '' || addForm.value.wechat !== '') {
    formRef.value.validate()
      .then(() => {
        const channelArr = addForm.value.channelSubIds
        addForm.value.channelId = channelArr[0]
        addForm.value.channelSubId = channelArr[1]
        if (addForm.value.endAddress && addForm.value.endAddress.province === undefined) delete addForm.value.endAddress
        if (addForm.value.startAddress && addForm.value.startAddress.province === undefined) delete addForm.value.startAddress
        loading.value = true
       
        addPublic(addForm.value).then(res => {
          if (res.code === 10000) {
            message.success(res.msg + props.tips)
            cancel()
            emit('submit')
          }
        }).finally(() => {
          loading.value = false
        })
      }).catch(err => { console.log(err) })

  } else message.error('电话和微信号必须填写其中一个')
}
</script>

<style lang="less" scoped>
</style>