<template>
  <div>
    <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="title" v-if="total?.clueDeal">
      线索总数 <a>{{ total.clueTotal }}</a> 个&nbsp;&nbsp;
      总成交 <a>{{ total.clueDeal }}</a> 个&nbsp;&nbsp;
      总毛利 <a>￥{{ total.grossProfit }}</a> 元
    </div>
    <div style="width: 100%; height:500px" id="main">

    </div>
    <a-modal v-model:visible="visible" title="无效统计" destroyOnClose footer="" width="60%">
      <SalesInvd :month="month" :paramId="paramId"></SalesInvd>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import { ConversionStatistics } from './echarts';
import SearchList from '@/components/searchList'
import { salesStatistics, salesInvalid } from '@/api/marketingConsole/marketingConsole'
import { useStore } from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash-es';
import { transitionTimeYM } from '@/utils/util'
import SalesInvd from './salesInvd.vue'
import { message } from 'ant-design-vue';
const loading = ref(false)
const store = useStore()
const searchMsg = ref({})
const details = ref([])
const total = ref({})
const visible = ref(false)
const month = ref('')
const paramId = ref('')
const open = (e) => {
  if (!e.data.temporaryNum) return
  paramId.value = e.data.paramId
  visible.value = true
}
const resetBtn = () => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  reqCvsStatistics()
}
const searchAdd = (e) => {
  if (!e.month) {
    message.error('请先选择月份再进行搜索')
    return
  }
  let msg = cloneDeep(e)
  msg.month = transitionTimeYM(msg.month)
  month.value = msg.month
  if (msg.channelSubId) {
    msg.channelSubId = msg.channelSubId[1]
  }
  searchMsg.value = msg
  reqCvsStatistics()
}
const randomColor = () => {
  // 生成三个随机数作为 RGB 值
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);
  var color = 'rgb(' + r + ',' + g + ',' + b + ')';
  return color
}
const GenerateGraph = (details) => {
  let data = [];
  details.forEach(item => {
    let color = randomColor()
    let str = 
    '业务员：' + item.xname
    + '<br/>' +
    '领取数量：' + item.clueTotal + '个'
      + '<br/>' +
      '总成交：' + item.clueDeal + '个'
      + '<br/>' +
      '转化率：' + item.rate + '%'
      + '<br/>' +
      '无效：' + item.temporaryNum + '个'
      + '<br/>' +
      '毛利：' + '￥' + item.grossProfit + '元'
    data.push({
      color: color,
      str: str,
      temporaryNum: item.temporaryNum,
      paramId: item.paramId,
      value: [item.xname, item.clueDeal],
    })
  })
  ConversionStatistics('main', data, open)
}
const reqCvsStatistics = () => {
  loading.value = true
  salesStatistics({
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    total.value = res.data.total
    details.value =  res.data.details
      GenerateGraph(details.value)
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  month.value = searchMsg.value.month
  reqCvsStatistics()
})

const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "线索月份",
    value: moment(new Date()),
    prop: "month",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt: store.state.enumAll.ChannelTypeEnum
  },
])
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  color: #6b778c;
  padding: 20px 10px;
  background: #fff;
  font-weight: 700;
}
</style>