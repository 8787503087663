<template>
<div>
  <a-modal v-model:visible="detailShow" footer="" width="55%" :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
    <template #title>
      <WindowDetail v-if="detail.orderVehicleId" :detailData="detail" />
    </template>
    <div class="left-content" style="width:auto">
      <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detail" :detailType="true"  />
    </div>
  </a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
import WindowDetail from '@/views/components/vinNo/windowDetail.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'

const detailShow = ref(false)
const detail = ref({})
const transportModelRef = ref(null)
const okk = () => {
  transportModelRef.value.getTaskListData()
}

defineExpose({
  detailShow,
  detail,
  okk
})
</script>

<style lang="less" scoped>

</style>