<template>
  <div class="monthly">
    <div class="flex al-center ju-between">
      <div class="flex al-center">
        <span class="title">指标-线索-消费 统计</span>
      </div>
      <div>
        <SearchList @searchAdd="searchAdd" :loading="loading" @resetBtn="resetBtn" :iptData="iptData"></SearchList>
      </div>
    </div>
    <div>
      <a-table :loading="loading" size="small" :columns="columns" bordered :row-key="record => record.id" :pagination="false" :data-source="dataSource">
         <template #clueRate="{ record }">
          {{record.clueRate}}%
         </template>
         <template #consumptionRate="{ record }">
          {{record.consumptionRate}}%
         </template>
      </a-table>
    </div>

  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import SearchList from '@/components/searchList'
import FormList from '@/components/formList'
import { cloneDeep } from 'lodash-es';
import moment from 'moment'
import { transitionTimeYMD, transitionTimeYM } from '@/utils/util'
import { targetClueConsumption } from '@/api/marketingConsole/marketingConsole'
import { message } from 'ant-design-vue';
import { fuzzy } from '@/api/crmManagement/customer'
import { debounce, compute, IfNull } from '@/utils/util'

const loadingBtn = ref(false)
const title = ref('')
const searchMsg = ref({})
const editNum = ref(0)
const editID = ref(null)
const loading = ref(false)
const visible = ref(false)
const dataSource = ref([])
const month = ref(null)
const customerId = ref(null)
const searchLoading = ref(false)
const carriers = ref([])
const state = reactive({
  name: ''
})

const searchAdd = (e) => {
  searchMsg.value = cloneDeep(e)
  searchMsg.value.month = transitionTimeYM(e.month) 
  reqCustomerPage()
}
const resetBtn = () => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  reqCustomerPage()
}



const reqCustomerPage = () => {
  loading.value = true
  targetClueConsumption({
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  reqCustomerPage()
})


const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "选择月份",
    value: moment(new Date()),
    prop: "month",
    width: 160,
  },
])
const columns = ref([
  {
    title: '员工',
    dataIndex: 'empName',
    align: 'center',
  },
  {
    title: '渠道',
    dataIndex: 'channelSubNames',
    align: 'center',
    width:250
  },
  {
    title: '预算',
    dataIndex: 'consumption',
    align: 'center',
  },
  {
    title: '消费',
    dataIndex: 'realConsumption',
    align: 'center',
  },
  {
    title: '预算使用率',
    dataIndex: 'consumptionRate',
    slots:{
      customRender: 'consumptionRate'
    },
    align: 'center',
  },
  {
    title: '付费线索量',
    dataIndex: 'consumptionNum',
    align: 'center',
  },
  {
    title: '付费单价',
    dataIndex: 'consumptionUnitPrice',
    align: 'center',
  },
  {
    title: '线索目标',
    dataIndex: 'clueNum',
    align: 'center',
  },
  {
    title: '完成线索',
    dataIndex: 'realClueNum',
    align: 'center',
  },
  {
    title: '达成率',
    dataIndex: 'clueRate',
    align: 'center',
    slots:{
      customRender: 'clueRate'
    }
  },
  {
    title: '新线索量',
    dataIndex: 'realNewNum',
    align: 'center',
  },
  {
    title: '重复线索',
    dataIndex: 'realRepetitiveNum',
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.monthly {
  padding: 20px;
  background: #fff;
}
.title {
  font-size: 18px;
  color: #344563;
  font-weight: 700;
}
.reqStyle {
  color: red;
  font-family: SimSun, sans-serif;
  margin-right: 5px;
}

.delBox {
  height: 30px;
  width: 50px;
  border: 1px solid #d9001b;
  color: #d9001b;
}
</style>