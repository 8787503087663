import { ref } from 'vue'
export const  columns1 = ref([
    {
      title: '客户名称',
      dataIndex: 'customerName',
      key: 'customerName',
      align:'center'
    },
    {
      title: '部门',
      dataIndex: 'orgName',
      key: 'orgName',
      align:'center'
    },
    {
      title: '业务员',
      dataIndex: 'salesman',
      key: 'salesman',
      align:'center'
    },
    {
      title: '本月指标(元)',
      dataIndex: 'monthTargetAmt',
      key: 'monthTargetAmt',
      slots:{
        customRender: 'monthTargetAmt'
      },
      align:'center'
    },
    {
      title: '本月已完成(元)',
      dataIndex: 'completeTargetAmt',
      key: 'completeTargetAmt',
      align:'center'
    },
    {
      title: '完成率',
      dataIndex: 'completeRate',
      key: 'completeRate',
      slots:{
        customRender: 'completeRate'
      },
      align:'center'
    },
    {
      title: '本周预计指标',
      dataIndex: 'weekTargetAmt',
      key: 'weekTargetAmt',
      align:'center'
    },
    {
      title: '本周已完成',
      dataIndex: 'weekCompleteAmt',
      key: 'weekCompleteAmt',
      align:'center'
    },
    {
      title: '当日预计指标',
      dataIndex: 'dayTargetAmt',
      key: 'dayTargetAmt',
      align:'center'
    },
    {
      title: '当日已完成',
      dataIndex: 'dayCompleteAmt',
      key: 'dayCompleteAmt',
      align:'center'
    },
   
])
export const  columns2 = ref([
  {
    title: '业务员',
    dataIndex: 'salesman',
    key: 'salesman',
    align:'center'
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    key: 'orgName',
    align:'center'
  },
  {
    title: '本月私海新增总数',
    dataIndex: 'privateNew',
    key: 'privateNew',
    align:'center'
  },
  {
    title: '本月合作新增总数',
    dataIndex: 'cooperationNew',
    key: 'cooperationNew',
    align:'center'
  },
  {
    title: '本月指标',
    dataIndex: 'monthTargetAmt',
    key: 'monthTargetAmt',
    slots:{
      customRender: 'monthTargetAmt'
    },
    align:'center'
  },
  {
    title: '本月已完成(元)',
    dataIndex: 'completeTargetAmt',
    key: 'completeTargetAmt',
    align:'center'
  },
  {
    title: '完成率',
    dataIndex: 'completeRate',
    key: 'completeRate',
    slots:{
      customRender: 'completeRate'
    },
    align:'center'
  },
  {
    title: '本周预计指标',
    dataIndex: 'weekTargetAmt',
    key: 'weekTargetAmt',
    align:'center'
  },
  {
    title: '本周已完成',
    dataIndex: 'weekCompleteAmt',
    key: 'weekCompleteAmt',
    align:'center'
  },
  {
    title: '当日预计指标',
    dataIndex: 'dayTargetAmt',
    key: 'dayTargetAmt',
    align:'center'
  },
  {
    title: '当日已完成',
    dataIndex: 'dayCompleteAmt',
    key: 'dayCompleteAmt',
    align:'center'
  },
  {
    title: '明细',
    dataIndex: 'add',
    key: 'add',
    slots:{
      customRender: 'add'
    },
    align:'center'
  },
])

export const  columns3 = ref([

  {
    title: '部门',
    dataIndex: 'orgName',
    key: 'orgName',
    align:'center'
  },
  {
    title: '本月私海新增总数',
    dataIndex: 'privateNew',
    key: 'privateNew',
    align:'center'
  },
  {
    title: '本月合作新增总数',
    dataIndex: 'cooperationNew',
    key: 'cooperationNew',
    align:'center'
  },
  {
    title: '本月指标',
    dataIndex: 'monthTargetAmt',
    key: 'monthTargetAmt',
    slots:{
      customRender: 'monthTargetAmt'
    },
    align:'center'
  },
  {
    title: '本月已完成(元)',
    dataIndex: 'completeTargetAmt',
    key: 'completeTargetAmt',
    align:'center'
  },
  {
    title: '完成率',
    dataIndex: 'completeRate',
    key: 'completeRate',
    slots:{
      customRender: 'completeRate'
    },
    align:'center'
  },
  {
    title: '本周预计指标',
    dataIndex: 'weekTargetAmt',
    key: 'weekTargetAmt',
    align:'center'
  },
  {
    title: '本周已完成',
    dataIndex: 'weekCompleteAmt',
    key: 'weekCompleteAmt',
    align:'center'
  },
  {
    title: '当日预计指标',
    dataIndex: 'dayTargetAmt',
    key: 'dayTargetAmt',
    align:'center'
  },
  {
    title: '当日已完成',
    dataIndex: 'dayCompleteAmt',
    key: 'dayCompleteAmt',
    align:'center'
  },
  {
    title: '明细',
    dataIndex: 'add',
    key: 'add',
    slots:{
      customRender: 'add'
    },
    align:'center'
  },
])