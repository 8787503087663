<template>
  <div>
    <div class="titleBox">
      <div class="flex ju-between">
        <div class="flex-d ">
          <span>总线索(个)</span>
          <div class="flex al-center">
            <a @click="lookDetail('')" >
              <CountTo color="#0066CC" fontSize="18px" :endVal="item.totalClueNum" />
            </a>
            <RisingFalling v-if="idx === 1" :num="item.totalClueCompare" />
          </div>
        </div>
        <div class="flex-d ">
          <span>总消费(元)</span>
          <div >{{ item.consumptionSum }}</div>
        </div>
        <div class="flex-d ">
          <span>单价(元)</span>
          <div >{{ item.unitPrice }}</div>
        </div>
      </div>
      <div class="flex proportion m-t1">
        <div style="width: 60%">
          总占比{{item.totalClueNumRate}}%
        </div>
        <div>
          成交率{{item.dealRate}}%
        </div>
      </div>
      <div class="flex proportion ">
        <div style="width: 60%">
          新成交 <a @click="dealOpen(0)"> {{item.dealNewClue}}</a> 个
        </div>
        <div>
          重复成交 <a @click="dealOpen(1)"> {{item.dealRepeatClue}}</a> 个
        </div>
      </div>
    </div>
    <div class="contentBox">
      <div>
        <div v-for="opt in item.customerClassClueList" :key="opt.id" class="optBox flex ju-between m-t1">
          <div class="flex al-center ju-between" style="width: 55%;">
            <span>{{ opt.customerClass.label }}</span>
            <span @click="lookDetail(opt)">
              <div v-if="opt.customerClass.value === 0" class="cur-p" :style="{color:changeColor(opt.clueRate)}">{{ opt.clueNum }}</div>
              <a  v-else>{{ opt.clueNum }}</a>
              </span>
          </div>
          <span>{{ opt.clueRate }}%</span>
        </div>
      </div>
    </div>
    <div class="flex ju-between m-t2 timeSlot"> 
      <div v-for="(opt,index) in item.workPeriodList" :key="opt.id">
        <span>{{ opt.title }}</span>
        <div class="clueNum flex al-center">&nbsp;{{ opt.clueNum }} <RisingFalling v-if="idx === 1" :num="opt.compareRate" /></div>  
        <div class="proportion">占比{{opt.clueRate}}%&nbsp;&nbsp;成交 {{ opt.dealNum }} 个</div>
      </div>
    </div>
    <template v-if="visible">
      <Table  v-model:visible="visible" :title="title" 
     @theBottom='theBottom' :x="x" @setRemaekOk="setRemaekOk"
    :colorIdx="colorIdx" :totalClueNum="item.totalClueNum" :item="item" :dealID="dealID" @changeBtn="changeBtn" :loading="loading" :dataLength="data.length" @searchAdd="searchAdd" @resetBtn="resetBtn" :iptData="iptData" :customerClassClueList="item.customerClassClueList" :columns="columns" :data="dataSource"></Table>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Table from '../table'
import CountTo from '@/components/countTo'
import { clueList } from '@/api/marketingConsole/marketingConsole'
import { cloneDeep } from 'lodash-es'
import { transitionTimeYMD } from '@/utils/util'
import { useStore } from 'vuex'
import RisingFalling from '../../components/risingFalling' 
import {
  carryiptData0, carrycolumns0, carryiptData1, carrycolumns1, carryiptData2, carrycolumns2, carryiptData3, carrycolumns3, carryiptData4, carrycolumns4,
  carryiptData5, carrycolumns5, carryiptData6, carrycolumns6
} from './styleOne'
const store = useStore()
const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  title: {
    type: String,
    default: ''
  },
  channelSubId: {
    type: String,
    default: ''
  },
  idx:{
    type: Number,
    default: null
  }
})
const x = ref(0)
const dealID = ref(null) //成交id
const loading = ref(false)
const visible = ref(false)
const iptData = ref([])
const columns = ref([])
const data = ref([])
const dataSource = ref([])
const colorIdx = ref(null)
const searchMsg = ref({})
const customerClass = ref('')
const pageSize = ref(50)
const lookDetail = (opt) => {
  let str = ''
  if (!opt) {
    colorIdx.value = null
    processingData(null)
  } else {
    str = opt.customerClass.value
    colorIdx.value = opt.customerClass.value
    processingData(opt.customerClass.value)
  }
  dealID.value = null
  searchMsg.value = {}
  ResetScroll()
  reqChannelList(str)
  visible.value = true
}
const setRemaekOk = (e) => {
  dataSource.value.forEach((item,index) => {
    if(item.customerId === e){
      dataSource.value.splice(index,1)
    }
  })
}
const ResetScroll = () => {
  pageSize.value = 50
  dataSource.value = []
}
const resetBtn = () => {
  ResetScroll()
  searchMsg.value = {}
  reqChannelList(customerClass.value)
}
//搜索
const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.clueCreateTimeStart = transitionTimeYMD(msg.clueCreateTimeStart)
  msg.clueCreateTimeEnd = transitionTimeYMD(msg.clueCreateTimeEnd)
  msg.receiveTimeStart = transitionTimeYMD(msg.receiveTimeStart)
  msg.receiveTimeEnd = transitionTimeYMD(msg.receiveTimeEnd)
  if(msg.channelSubId){
    msg.channelSubId =  msg.channelSubId[1]
  }
  ResetScroll()
  searchMsg.value = msg
  reqChannelList(customerClass.value)
}
const changeBtn = (e) => {
  if (dealID.value !== null) {
    dealID.value = e
    reqChannelList('')
    return
  }
  ResetScroll()
  searchMsg.value = {}
  dealID.value = null
  processingData(e)
  reqChannelList(e)
}
const dealOpen = (type) => {
  dealID.value = type
  iptData.value = carryiptData6.value
  columns.value = carrycolumns6.value
  x.value = 2000
  reqChannelList('')
  visible.value = true
}
const changeColor = (value) => {
  if (value >= 0&& value <= 5) {
    return '#0066CC'
  } else if(value > 5&& value <= 10) {
    return '#7948ea'
  }else{
    return '#D9001B'
  }
}
const processingData = (value) => {
  switch (value) {
    case -1:
      iptData.value = cloneDeep(carryiptData4.value)
      columns.value = carrycolumns4.value
      x.value = 0
      break;
    case 0:
      iptData.value = cloneDeep(carryiptData5.value)
      columns.value = carrycolumns5.value
      x.value = 0
      break;
    case 1:
      iptData.value = cloneDeep(carryiptData1.value)
      columns.value = carrycolumns1.value
      x.value = 0
      break;
    case 2:
      iptData.value = cloneDeep(carryiptData2.value)
      columns.value = carrycolumns2.value
      x.value = 0
      break;
    case 3:
      iptData.value = cloneDeep(carryiptData3.value)
      columns.value = carrycolumns3.value
      x.value = 2500
      break;
    case null:
      iptData.value = cloneDeep(carryiptData0.value) 
      columns.value = carrycolumns0.value
      x.value = 2500
      break;
  }
 
}

const reqChannelList = (str) => {
  if(!props.channelSubId){
      iptData.value.forEach(item => {
      if (item.prop === 'fallReason') {
        item.opt = store.state.enumAll.InvalidClue
      }
      if(item.prop === 'channelSubId'){
        item.opt = store.state.enumAll.ChannelTypeEnum
      }
    })
  }else{
    iptData.value.forEach((item,index) => {
      if (item.prop === 'fallReason') {
        item.opt = store.state.enumAll.InvalidClue
      }
      if(item.prop === 'channelSubId'){
        iptData.value.splice(index,1)
      }
    })
  }
  if (dealID.value !== null) {
    searchMsg.value.isRepetitive = dealID.value
    searchMsg.value.isDeal = 1
  }
  customerClass.value = str
  loading.value = true
  clueList({
    timeType: props.item.timeType,
    channelSubId: props.channelSubId ? props.channelSubId : null,
    customerClass: str,
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
</script>

<style lang="less" scoped>
.titleBox {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  span {
    color: #6b778c;
  }
}
.proportion {
  color: #6b778c;
  font-size: 12px;
}
.timeSlot {
  span {
    color: #6b778c;
  }
}
.optBox {
  background: #edf0f9;
  padding: 2px 6px;
  border-radius: 5px;
}
:deep(.countTo) {
  padding: 0;
}

.clueNum {
  margin-top: -5px;
  font-weight: 700;
  font-size: 18px;
}
</style>