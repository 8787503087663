<template>
  <div>
    <a-select :bordered="false" v-model:value="value"  @change="change" size="small" style="min-width:70px;max-width: 85px" :options="options"></a-select>
  </div>
</template>

<script setup>
import { onMounted, ref, watch,nextTick } from 'vue'
const props = defineProps({
  clueDataStatisticsList: {
    type: Array,
    default: []
  },
  idx:{
    type: Number,
    default:0
  }
})
const emit = defineEmits(['change'])
const value = ref(null)
const options = ref([])

const change = (val) => {
  emit('change', val)
}
const processingData = () => {
  let opt = []
  props.clueDataStatisticsList.forEach(item => {
    let msg = {
      value: item.timeType.value,
      label: item.timeType.label
    }
    opt.push(msg)
  })
  options.value = opt
  value.value = props.idx
}
onMounted(() => {
  processingData()
})
watch(()=> props.clueDataStatisticsList.length,(newVal,val)=>{
  processingData()
})

watch(()=>props.idx, (newVal,val)=>{
  value.value = props.idx
})
</script>

<style lang="less" scoped>
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector){
  font-weight: normal;
}

</style>