<template>
<div>
  <div style="width: 100%; height:500px" id="Invalid"></div>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { salesInvalid } from '@/api/marketingConsole/marketingConsole'
import * as echarts from 'echarts';
const props = defineProps({
  month: {
    type: String,
    default: ''
  },
  paramId:{
    type: String,
    default: ''
  }
})
const  randomColor = () => {
  // 生成三个随机数作为 RGB 值
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);
  var color = 'rgb(' + r + ',' + g + ',' + b + ')';
 return color
}
const reqSalesInvalid = () => {
  salesInvalid({
    month: props.month,
    salesId: props.paramId
  }).then(res => {
    if (res.code !== 10000) return
    let data = []
    res.data.forEach(item => {
      let color =randomColor()
      let str = '总数：' + item.num + '个'
      data.push({
        color:color,
        str: str,
        value: [item.xname, item.num],
      })
    })
    ConversionStatistics('Invalid', data)
  })
}
onMounted(() => {
  reqSalesInvalid()
})
 const ConversionStatistics = (box, data, open) => {
  var chartDom = document.getElementById(box);
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        params = params[0];
        return (
          params.data.str
        );
      },   
      axisPointer: {
        animation: false
      }
    },
    grid: {
      left: '2%',   // 左边距为容器宽度的10%
      right:'3%',
      top:'3%',
      containLabel: true // 包含坐标轴的标签
    },
    backgroundColor: '#fff',
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    series: [
      {
        name: 'Fake Data',
        type: 'bar',
        showSymbol: false,
        barMaxWidth: '100',
        data: data,
        itemStyle:{
          color: function (params) {
            return params.data.color
          }
        }
      }
    ]
  };
  myChart.on('click', function (params) {
   if(open){
    open(params)
   }
  })
  option && myChart.setOption(option)
}

</script>

<style lang="less" scoped>

</style>