<template>
  <div >
    <div >
      <MonthlyIndicators></MonthlyIndicators>
    </div>
    <div >
      <CustomerIndicators></CustomerIndicators>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import MonthlyIndicators from './monthlyIndicators'
import CustomerIndicators from './customerIndicators.vue';

</script>

<style lang="less" scoped>
</style>