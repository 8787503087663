<template>
  <div>
    <div class="box1 flex al-center pos-rel">
      <div ref="numRef1" class="pos-abs num" :style="{left: ( timeRate > 100 ? 100 :  timeRate)   + '%',color:colorReturn(timeRate,widths),marginLeft: -changeNum1 + 'px'}">
        {{ timeRate }}%
      </div>
    </div>
    <div class="box1 flex al-center pos-rel">
      <div ref="numRef" class="pos-abs num" :style="{left: ( widths > 100 ? 100 :  widths)   + '%',color:color,marginLeft: -changeNum + 'px'}">
        {{ widths }}%
      </div>
    </div>
    <div class="box flex al-center pos-rel">
      <div class="back-content ">
        <div class="content " style="z-index:9" :style="{width: ( widths > 100 ? 100 :  widths) + '%',background: color}">
        </div>
        <div class="content" :style="{width: ( timeRate > 100 ? 100 :  timeRate) + '%',background:colorReturn(timeRate,widths)}">
        </div>
      </div>
      <div class="title pos-abs"  style="z-index:9" :style="{left: ( widths > 100 ? 100 : widths) + '%',background: color}">
      </div>
      <div class="title1 pos-abs" :style="{left: ( timeRate > 100 ? 100 : timeRate) + '%',background: colorReturn(timeRate,widths)}">
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, nextTick } from 'vue'
import { compute } from '@/utils/util'
const prosp = defineProps({
  widths: {
    type: Number,
    default: 0
  },
  timeRate:{
    type: Number,
    default: 0
  },
  color:{
    type: String,
    default:'#00a7eb'
  }
})
const numRef = ref(null)
const numRef1 = ref(null)
const changeNum = ref(0)
const changeNum1 = ref(0)

const getWid = () => {
  nextTick(() => {
    if(prosp.widths > 99){
      changeNum.value = numRef.value.clientWidth
    }
    else if(prosp.widths < 1){
      changeNum.value = 0
    }else{
      changeNum.value = (numRef.value.clientWidth / 2) - 0.5 
    }

    if(prosp.timeRate > 99){
      changeNum1.value = numRef1.value.clientWidth
    }  else if(prosp.timeRate < 1){
      changeNum1.value = 0
    }else{
      changeNum1.value = (numRef1.value.clientWidth / 2) - 0.5 
    }
  })

}
const colorReturn = (num1,num2) => {
  if(compute('-',num1,num2).result<=0){
    return `rgba(197, 3, 3, 1)`
  }
  if(compute('-',num1,num2).result>0&&compute('-',num1,num2).result<21){
    return `rgba(197, 3, 3, 0.6)` 
  }else if(compute('-',num1,num2).result>=21&&compute('-',num1,num2).result<=50){
    return`rgba(197, 3, 3, 0.8)`
  }else if(compute('-',num1,num2).result>50){
    return `rgba(197, 3, 3, 1)`
  }
}
watch(() => prosp.widths, (newVal, oldVal) => {
  getWid()
})


</script> 

<style lang="less" scoped>
.box1 {
  height: 16px;
  width: 100%;
 
}
.box {
  height: 24px;
  width: 100%;
 
}
.back-content {
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background: #ededed;
  position: relative;

  .content {
    height: 100%;
    background: #00a7eb;
    border-radius: 100px 0 0 100px ;
    transition: width 1s;
    max-width: 100%;
    position: absolute;
    left: 0;
  }
}
.title {
  width: 3px;
  height: 24px;
  background: #00a7eb;
  transition: left 1s;
}
.title1 {
  width: 3px;
  height: 40px;
  background: #00a7eb;
  transition: left 1s;
}
.num {
  transition: left 1s;
  margin-bottom: 10px;
}
</style>