<template>
  <div style="display: flex; padding: 10px">
    <div class="follow-add">
      <div class="follow-add-form" style="padding-bottom: 10px">
        <div class="label-content" style="margin-top: 10px; flex-wrap: wrap">
          <div style="width: 100%">
            <div style="display: flex">
              <div class="title-label">跟进方式：</div>
              <a-radio-group v-model:value="chooseLabel.followType" button-style="solid">
                <a-radio-button
                  v-for="item in labelData.followType"
                  :key="item.value"
                  :value="item.value"
                  @click="chooseChange(1, item.value)"
                  >{{ item.label }}</a-radio-button
                >
              </a-radio-group>
            </div>
            <a-radio-group
              v-show="chooseLabel.followType === 1"
              v-model:value="chooseLabel.followTelType"
            >
              <a-radio-button
                v-for="item in labelData.followTypeStatus"
                :key="item.value"
                :value="item.value"
                @click="chooseChange(0, item.value)"
                >{{ item.label }}</a-radio-button
              >
            </a-radio-group>
          </div>
          <div class="label-content" v-show="chooseLabel.followType === 1">
            <div style="display: flex">
              <div class="title-label">加微信：</div>
              <a-radio-group v-model:value="chooseLabel.isWechat" button-style="solid">
                <a-radio-button
                  v-for="item in labelData.wechat"
                  :key="item.value"
                  :value="item.value"
                  @click="chooseChange(2, item.value)"
                  >{{ item.label }}</a-radio-button
                >
              </a-radio-group>
            </div>
          </div>
          <div class="label-content">
            <div class="title-label">托运时间：</div>
            <a-radio-group
              v-model:value="chooseLabel.consignmentType"
              button-style="solid"
            >
              <a-radio-button
                v-for="item in labelData.transportation"
                :key="item.value"
                :value="item.value"
                @click="chooseChange(3, item.value)"
                >{{ item.label }}</a-radio-button
              >
            </a-radio-group>
          </div>
          <div class="label-content">
            <div class="title-label">意向等级：</div>
            <a-radio-group
              v-model:value="chooseLabel.intentionLevel"
              button-style="solid"
            >
              <template v-for="item in labelData.intentionLevel" :key="item.value">
              <a-popover>
                <template #content>
                  <p style="width: 200px; padding: 0 10px">{{item.describe}}</p>
                </template>
                <a-radio-button
                  :key="item.value"
                  :value="item.value"
                  @click="chooseChange(4, item.value)"
                >{{ item.label }}</a-radio-button>
              </a-popover>
              </template>
            </a-radio-group>
          </div>
          <div class="label-content">
            <div class="title-label">托运用途：</div>
            <a-radio-group
              v-model:value="chooseLabel.consignmentPurpose"
              button-style="solid"
            >
              <a-radio-button
                v-for="item in labelData.consignment"
                :key="item.value"
                :value="item.value"
                @click="chooseChange(5, item.value)"
                >{{ item.label }}</a-radio-button
              >
            </a-radio-group>
          </div>
          <div class="label-content" v-if="receiverArray?.length > 0">
            <div class="title-label">对接人：</div>
            <a-radio-group
              v-model:value="chooseLabel.receiverType"
              button-style="solid"
            >
              <a-radio-button
                v-for="item in receiverArray"
                :key="item.id"
                :value="item.id"
                @click="chooseReceiver(item)"
                >{{ item.name }}</a-radio-button
              >
            </a-radio-group>
          </div>
          <!--  <div class="label-content">
            <div class="title-label">同行车辆：</div>
            <a-radio-group v-model:value="followType" button-style="solid">
              <a-radio-button v-for="item in labelData.followType" :key="item.value" :value="item.value">{{
                item.label
              }}</a-radio-button>
            </a-radio-group>
            <a-input v-model:value="value1" style="width:100px;margin-left:15px" />
          </div>
          <div class="label-content">
            <div class="title-label">客户顾虑：</div>
            <a-radio-group v-model:value="followType" button-style="solid">
              <a-radio-button v-for="item in labelData.transportation" :key="item.value" :value="item.value">{{
                item.label
              }}</a-radio-button>
            </a-radio-group>
          </div> -->
          <div style="height: 20px;width: 100%">
            <div class="title-empty"></div>
          </div>
          <div style="width:100%" class="flex">
            <span style="color:red;top:-4px;margin-right:2px;font-family: SimSun, sans-serif" class="pos-rel">*</span>
            <a-textarea
            placeholder="跟进内容(必填)"
            v-model:value="addForm.content"
            :maxlength="200"
            :minlength="5"
            :autosize="{ minRows:4 }"
          />
          </div>
        </div>
        <!-- <a-row :gutter="[26, 24]">
          <a-col :span="26" class="search">
            <a-form ref="addRecord" layout="inline" :model="addForm" :rules="rules">
              <a-col :span="4" class="search">
                <a-form-item name="followTime">
                  <a-date-picker showTime placeholder="跟进时间" v-model:value="addForm.followTime" @change="timeChange"
                    format="YYYY-MM-DD HH:mm:ss" style="width:100%" />
                </a-form-item>
              </a-col>
              <a-col :span="4" class="search" v-if="followTypeShow">
                <a-form-item name="followType">
                  <a-select :options="$store.state.enumAll.FollowTypeEnum" v-model:value="addForm.followType"
                    @change="followTypeChange" class="ransport_search_input" placeholder="跟进方式">
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4" class="search">
                <a-form-item name="intentionType">
                  <a-select :options="$store.state.enumAll.IntentionEnum" v-model:value="addForm.intentionType"
                    class="ransport_search_input" placeholder="意向程度">
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3" class="search">
                <a-form-item name="followLabelId">
                  <a-select :options="$store.state.enumAll.FollowUpEnum" v-model:value="addForm.followLabelId"
                    placeholder="跟进标签">
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3" class="search">
                <a-form-item>
                  <a-select v-model:value="addForm.receiver" class="ransport_search_input" placeholder="对接人">
                    <a-select-option v-for="item in receiverArray" :key="item.id" :value="item.id"
                      @click="chooseReceiver(item)">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6" class="search">
                <a-form-item>
                  <a-date-picker show-time placeholder="下次跟进时间" :disabled-time="disabledDateTime" style="width:100%"
                    v-model:value="addForm.followNextTime" @change="nexTimeChange" format="YYYY-MM-DD HH:mm:ss" />
                </a-form-item>
              </a-col>
              <a-col :span="18" class="search" style="margin-top:5px">
                <a-form-item name="content">
                  <a-textarea placeholder="跟进内容" v-model:value="addForm.content" :maxlength="200" />
                </a-form-item>
              </a-col>
              <a-col :span="6" class="search" style="margin-top:5px">
                <a-form-item>
                  <a-textarea placeholder="下次跟进内容" style="width:100%" v-model:value="addForm.followNextContent"
                    :maxlength="200" />
                </a-form-item>
              </a-col>
            </a-form>
          </a-col>
        </a-row> -->
      </div>
      <div class="row-div">
        <div class="row-div-upload">
          <a-upload
            multiple
            v-model::file-list="fileList"
            :disabled="uploadDisabled"
            :remove="handleRemove"
            list-type="picture-card"
            :before-upload="beforeUpload"
          >
            <div>
              <plus-outlined></plus-outlined>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <div
            v-if="fileList.length > 0"
            style="display: flex; align-items: flex-end; margin-bottom: 8px"
          >
            <a-button :loading="fileLoading" @click="handleChange()"
              >请点击上传保存</a-button
            >
          </div>
        </div>
        <div class="row-div-button">
          <a-date-picker
            show-time
            placeholder="下次跟进时间"
            :disabled-time="disabledDateTime"
            style="width: 30%"
            v-model:value="addForm.followNextTime"
            @change="nexTimeChange"
            format="YYYY-MM-DD HH:mm:ss"
          />
          <a-space>
            <!-- <div @click="show = true">设置下次联系时间</div> -->
            <a-button class="searchLoading-button" :loading="fileLoading" @click="onAdd()">添加跟进记录</a-button>
          </a-space>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="show" width="500px" title="设置下次跟进时间" @ok="onNext">
      <a-form layout="vertical" ref="formRef" :model="addForm">
        <a-row :gutter="[24, 48]">
          <a-col :span="24">
            <a-form-item>
              <template #label>
                <div>下次跟进时间<span style="color: #f00">*</span></div>
              </template>
              <a-date-picker
                placeholder="下次跟进时间"
                v-model:value="addForm.followNextTime"
                @change="nexTimeChange"
                format="YYYY-MM-DD"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="下次跟进内容">
              <a-textarea
                placeholder="下次跟进内容"
                v-model:value="addForm.followNextContent"
                :maxlength="200"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24"></a-col>
        </a-row>
      </a-form>
    </a-modal>
    <Follow ref="followListRef" :customerId="cid" v-if="followShow" @data="receive" />
  </div>
</template>

<script>
import {
  add,
  getCustomerLabelAll,
  addCustomerLabel,
  getLabelAll
} from '@/api/crmManagement/follow'
import moment from 'moment'
import { reactive, toRefs, onMounted, defineComponent, ref } from 'vue'
import { contactCustomerIdPage } from '@/api/crmManagement/contact'
import Follow from './index'
import { message } from 'ant-design-vue'
import { fileUpload as upload } from '@/api/marketing/modList'

export default defineComponent({
  props: {
    customerId: String,
    followType: Number
  },
  components: {
    Follow
  },
  setup (props,{emit}) {
    const addRecord = ref()
    const state = reactive({
      followType: null,
      value1: null,
      //= ----------------
      fileList: [],
      receiverArray: [],
      fileLoading: false,
      uploadDisabled: false,
      followTypeShow: true,
      allowAdd: true,
      show: false,
      followListRef: ref(null),
      followShow: true,
      cid: props.customerId,
      rules: {
        followTime: {
          required: true,
          message: '请选择跟进时间',
          trigger: 'blur',
          type: 'date'
        },
        followType: {
          required: true,
          message: '请选择跟进方式',
          trigger: 'blur',
          type: 'number'
        },
        intentionType: { required: true, message: '请选择意向程度', trigger: 'blur' },
        followNextTime: {
          required: true,
          message: '请选择下次跟进时间',
          trigger: 'blur',
          type: 'string'
        },
        // followLabelId: { required: true, message: '请选择跟进标签', trigger: 'blur', type: 'string' },
        content: { required: true, message: '请填写跟进内容', trigger: 'blur' }
      },
      chooseLabel: {
        consignmentPurpose: null, // 托运用途
        followType: null, // 跟进方式
        intentionLevel: null, // 意向等级
        isWechat: null, // 是否添加微信
        consignmentType: null, // 托运标签
        followTelType: null, // 电话状态类型
        receiverType: null,
        receiver: null,
        receiverMobile: null
      },
      mirrorLabel: {},
      labelData: {
        followType: [],
        wechat: [],
        followTypeStatus: [],
        transportation: [],
        consignment: [],
        intentionLevel: [],
        docker: [],
        peerVehicle: [],
        worry: []
      },
      addForm: {
        customerId: props.customerId,
        followNext: 0,
        receiver: null,
        receiverMobile: '',
        followTime: null,
        followNextTime: null,
        followType: null,
        intentionType: null,
        fileIds: []
      },
      mirrorAddForm: {}
    })
    onMounted(() => {
      state.mirrorLabel = JSON.stringify(state.chooseLabel)
      getLabelAll().then((res) => {
        if (res.code === 10000) {
          state.labelData.followType = res.data.FOLLOW
          state.labelData.followTypeStatus = res.data.FOLLOW_TEL
          state.labelData.wechat = res.data.WECHAT
          state.labelData.transportation = res.data.CONSIGNMENT_TYPE
          state.labelData.consignment = res.data.CONSIGNMENT_PURPOSE
          state.labelData.intentionLevel = res.data.INTENTION_LEVEL.splice(1, 4)
        }
      })
      if (props.customerId) state.cid = props.customerId
      if (props.followType) {
        state.addForm.followType = 9
        state.followTypeShow = false
      }
      state.mirrorAddForm = JSON.stringify(state.addForm)
      getContachList()
    })
    const range = (start, end) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }

    const disabledDateTime = () => {
      return {
        // disabledHours: () => range(0, 24).splice(4, 20),
        // disabledMinutes: () => range(30, 60),
        // disabledSeconds: () => [55, 56],
      }
    }
    const chooseReceiver = (item) => {
      if (state.chooseLabel.receiverType === item.id) {
        setTimeout(() => {
          state.chooseLabel.receiverType = null
        }, 100)
      } else {
        state.chooseLabel.receiver = item.name
        state.chooseLabel.receiverMobile = item.mobile
      }
    }
    const getContachList = () => {
      contactCustomerIdPage(state.cid, {
        size: 9999,
        current: 1
      }).then((res) => {
        if (res.code === 10000) {
          state.receiverArray = res.data.records
        }
      })
    }
    const onAdd = () => {
     
      if (state.allowAdd === true) {
        // 跟进方式为 电话时，下面方式必须选择
        if (state.chooseLabel.followType === 1) {
          if (state.chooseLabel.followTelType === undefined || state.chooseLabel.followTelType === null) {
            message.error('跟进方式为 电话时,下面方式必须选择')
            return
          }
        }
        if (!state.chooseLabel.intentionLevel) {
          message.error('请选择意向等级')
          return
        }
        if (!state.addForm.content) {
          message.error('跟进内容必须值')
          return
        }
        if(state.fileList.length > 0) {
          message.warn('请先点击上传保存按钮')
          return
        }
        state.fileLoading = true
       
        const arr = []
        state.addForm.fileIds.forEach((item) => {
          if (item.id) arr.push(item.id)
        })
        
        state.addForm.fileIds = arr
        add({ ...state.addForm, ...state.chooseLabel })
          .then((res) => {
            if (res.code === 10000) {
              emit('addSuccess',state.addForm.content)
              state.followShow = false
              message.success(res.msg)
              state.addForm = JSON.parse(state.mirrorAddForm)
              state.chooseLabel.receiver = null
              state.chooseLabel.receiverMobile = null
              state.addForm.fileIds = []
              state.addForm.customerId = props.customerId
              if (state.followTypeShow === false) state.addForm.followType = 9
              state.chooseLabel = JSON.parse(state.mirrorLabel)
              deleteEvery()
              setTimeout(() => {
                // state.followListRef.loadData()
                state.uploadDisabled = false
              }, 50)
             
              state.allowAdd = true
            }
          })
          .finally(() => {
            state.followShow = true
            state.fileLoading = false
          
          })
      } else message.error('请上传至少一张图片')
    }
    const onNext = () => {
      if (
        state.addForm.followNextTime !== undefined &&
        state.addForm.followNextTime !== null &&
        state.addForm.followNextTime !== ''
      ) {
        state.addForm.followNext = 1
        state.show = false
      } else message.error('请选择跟进时间')
    }
    const timeChange = (e, v) => {
      state.addForm.followTime = v
    }
    const nexTimeChange = (e, v) => {
      state.addForm.followNextTime = v
    }
    const followTypeChange = (e, v) => {
      // state.addForm.followType = v
    }
    // 图片上传
    const handleChange = () => {
      const len = state.fileList.length
      state.fileLoading = true
      updateBatch(0, len, state.fileList)
    }
    // 图片移除
    const handleRemove = (file) => {
      const index = state.fileList.indexOf(file)
      const newfileList = state.fileList.slice()
      newfileList.splice(index, 1)
      state.fileList = newfileList
      state.addForm.fileIds.forEach((item, index) => {
        if (item.name === file.originFileObj.name) state.addForm.fileIds.splice(index, 1)
      })
    }
    // 删除所有图片
    const deleteEvery = () => {
      const images = document.querySelectorAll('.ant-upload-list-picture-card-container')
      images.forEach((item) => {
        item.remove()
      })
      state.fileList = []
    }
    // 选中图片
    const beforeUpload = (file) => {
      state.fileList.push(file)
      return false
    }
    // 图片上传
    const updateBatch = (index, len, imageList) => {
      if (index <= len - 1) {
        const formData = new FormData()
        formData.append('file', imageList[index])
        upload(formData).then((res) => {
          if (res.code === 10000) {
            state.addForm.fileIds.push(res.data)
            // data.fileUrl = res.data.previewUrl
            if (index === len - 1) {
              setTimeout(() => {
                state.fileList = []
                message.success('图片上传成功')
                state.allowAdd = true
                state.fileLoading = false
                state.uploadDisabled = true
              }, 1000)
            } else {
              updateBatch(++index, len, imageList)
            }
          }
        })
      } else {
        return false
      }
    }
    // 接收组件数据
    const receive = (e) => {
      if (e === '' || e === null || e === undefined || e.length <= 0) {
        state.allowAdd = true
      } else state.allowAdd = true
    }
    const chooseChange = (type, value) => {
      switch (type) {
        case 1:
          if (state.chooseLabel.followType === value) {
            setTimeout(() => {
              state.chooseLabel.followType = null
            }, 100)
          }
          break
        case 0:
          if (state.chooseLabel.followTelType === value) {
            setTimeout(() => {
              state.chooseLabel.followTelType = null
            }, 100)
          }
          break
        case 2:
          if (state.chooseLabel.isWechat === value) {
            setTimeout(() => {
              state.chooseLabel.isWechat = null
            }, 100)
          }
          break
        case 3:
          if (state.chooseLabel.consignmentType === value) {
            setTimeout(() => {
              state.chooseLabel.consignmentType = null
            }, 100)
          }
          break
        case 4:
          if (state.chooseLabel.intentionLevel === value) {
            setTimeout(() => {
              state.chooseLabel.intentionLevel = null
            }, 100)
          }
          break
        case 5:
          if (state.chooseLabel.consignmentPurpose === value) {
            setTimeout(() => {
              state.chooseLabel.consignmentPurpose = null
            }, 100)
          }
          break
      }
    }
    return {
      ...toRefs(state),
      onAdd,
      onNext,
      moment,
      disabledDateTime,
      deleteEvery,
      timeChange,
      chooseChange,
      nexTimeChange,
      followTypeChange,
      receive,
      chooseReceiver,
      addRecord,
      handleChange,
      handleRemove,
      beforeUpload,
      updateBatch
    }
  }
})
</script>

<style lang="less" scoped>
@import "../../tool/index.less";
@import "./index.less";

:deep(.ant-input) {
  // height: 32px !important;
  // line-height: 32px;
  // margin-top: 15px;
}

.ant-radio-group-solid {
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  :deep(.ant-radio-button-wrapper) {
    width: 120px;
    border: none;
    text-align: center;
    margin-top: 15px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 12px;
    background-color: #f0f0f0;
  }
}

.ant-radio-group-outline {
  margin-top: 10px;
  margin-left: 100px;

  :deep(.ant-radio-button-wrapper) {
    padding: 0;
    width: 80px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    text-align: center;
    margin-left: 15px;
    border-radius: 8px;
    border-left: 1px solid #d9d9d9;
  }

  :deep(.ant-radio-button-wrapper-checked) {
    color: #1890ff;
    border-left: 1px solid #1890ff;
    background-color: #fff !important;
  }
}

.ant-radio-button-wrapper::before {
  display: none !important;
}

:deep(.ant-upload-select-picture-card) {
  height: 80px !important;
  width: 80px !important;
}

:deep(.ant-upload-list-picture-card-container) {
  height: 80px !important;
  width: 80px !important;
}

:deep(.ant-upload-list-item) {
  height: 80px !important;
  width: 80px !important;
}

.follow-add-form {
  :deep(.ant-row) {
    justify-content: space-between;
  }

  :deep(.ant-col-26) {
    flex: 1;
  }
}

:deep(.ant-upload-picture-card-wrapper) {
  display: flex !important;
  width: auto !important;
}
</style>
