<template>
  <div>
    <div class="itemTitle m-t1 flex ju-between al-center">
      <div><span style="font-weight: bold;"> {{ item.title }}</span>&nbsp;
        <span @click="openList">
          <CountTo color="#0066CC" class="cur-p" fontSize="16px" :endVal="item.num" />
        </span>
      </div>
      <a-tooltip :title="item.define">
        <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
      </a-tooltip>
    </div>
    <div class="itemBox" style="margin-top: 0;font-size: 13px;">
      <div class=" flex wrap ju-between">
        <div v-for="opt in item.subItemList" @click="openList(opt)" :key="opt.id" :style="{borderColor:colorType[opt.colorType]}" class="cur-p flex al-center stateStyle ju-between">
          {{ opt.title }}&nbsp;&nbsp;<span :style="{color:colorType[opt.colorType]}">{{ opt.num }}</span>
        </div>
      </div>
    </div>
    <div v-if="visible">
      <PickModal @theBottom="theBottom"   @changeBtn="changeBtn" @resetBtn="resetBtn" @searchAdd="searchAdd" 
      :title="title" :colorIdx="colorIdx" :info="item" :typeKey="typeKey"
       :loading="loading" v-model:visible="visible" :dataLength="data.length" :iptData="iptData" :data="dataSource" :columns="columns"></PickModal>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import PickModal from './components/PickModal.vue'
import {
  carryiptData1, carrycolumns1, carryiptData2, carrycolumns2, carryiptData3, carrycolumns3, carryiptData4, carrycolumns4, carryiptData5, carrycolumns5,
  carryiptData6, carrycolumns6,
  carryiptData7, carrycolumns7,
  carryiptData8, carrycolumns8,
  carryiptData9, carrycolumns9
} from './components/PickModal.js'
import { colorType } from '@/utils/enum';
import CountTo from '@/components/countTo'
import { pickLoading, pickNo, pickTrans, transportLoading, transportNo, transportTrans, deliveryLoading, deliveryNo, deliveryTrans } from '@/api/newTransport/newTransport'
import { cloneDeep } from 'lodash-es';
import { addressTran, transitionTimeYMD } from '@/utils/util'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  vehicleNumType:{
    type: Number,
    default: 0
  }
})
const typeKey = ref('')
const title = ref('')
const iptData = ref(null)
const columns = ref(null)
const data = ref([])
const dataSource = ref([])
const visible = ref(false)
const reqDataUrl = ref(null)
const loading = ref(false)
const colorIdx = ref(null)
const searchMsg = ref({
  vehicleNum:1
})
const pageSize = ref(50)

const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
const openList = (opt) => {
  switch (props.item.pageType) {
    case 41:
      reqDataUrl.value = pickNo
      title.value = '提-待安排'
      typeKey.value = '提'
      iptData.value = carryiptData1.value
      columns.value = carrycolumns1.value
      break;
    case 42:
      reqDataUrl.value =  pickLoading
      title.value = '提-待发运'
      typeKey.value = '提'
      iptData.value = carryiptData2.value
      columns.value = carrycolumns2.value
      break;
    case 43:
      reqDataUrl.value = pickTrans
      title.value = '提-已发运'
      typeKey.value = '提'
      iptData.value = carryiptData3.value
      columns.value = carrycolumns3.value
      break;
    case 51:
      reqDataUrl.value = transportNo
      title.value = '干-待安排'
      typeKey.value = '干'
      iptData.value = carryiptData4.value
      columns.value = carrycolumns4.value
      break;
    case 52:
      reqDataUrl.value =  transportLoading
      title.value = '干-待发运'
      typeKey.value = '干'
      iptData.value = carryiptData5.value
      columns.value = carrycolumns5.value
      break;
    case 53:
      reqDataUrl.value = transportTrans
      title.value = '干-已发运'
      typeKey.value = '在途'
      iptData.value = carryiptData6.value
      columns.value = carrycolumns6.value
      break;
      case 54:
      reqDataUrl.value = transportNo
      title.value = '干-待安排'
      typeKey.value = '干'
      iptData.value = carryiptData4.value
      columns.value = carrycolumns4.value
      break;
    case 61:
      reqDataUrl.value =  deliveryNo
      title.value = '送-待安排'
      typeKey.value = '送'
      iptData.value = carryiptData7.value
      columns.value = carrycolumns7.value
      break;
    case 62:
      reqDataUrl.value = deliveryLoading
      title.value = '送-待发运'
      typeKey.value = '送'
      iptData.value = carryiptData8.value
      columns.value = carrycolumns8.value
      break;
    case 63:
      reqDataUrl.value = deliveryTrans
      title.value = '送-已发运'
      typeKey.value = '送'
      iptData.value = carryiptData9.value
      columns.value = carrycolumns9.value
      break;
  }
  colorIdx.value = opt ? opt.colorType : null
  searchMsg.value = {
    vehicleNum:1
  }
  if (props.vehicleNumType === 2) {
    searchMsg.value = {
    vehicleNum:props.vehicleNumType
  }
  iptData.value.forEach(item => {
      if(item.prop === 'vehicleNum'){
        item.value = props.vehicleNumType
      }
    })
  }
  reqData()
  visible.value = true
}
const reqData = () => {
  loading.value = true
  reqDataUrl.value({
    ...searchMsg.value,
   subType:colorIdx.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const ResetScroll = () => {
  pageSize.value = 50
  dataSource.value = []
}

 const changeBtn = (e) => {
  ResetScroll()
  colorIdx.value = e
  reqData()
 }
 const searchAdd = (e) => {
  ResetScroll()
  let msg = cloneDeep(e)
  msg.preDepartureTime = transitionTimeYMD(msg.preDepartureTime)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  msg.placeInAddress = addressTran(msg.placeInAddress)
  msg.nowCityId = msg.nowCityId ? msg.nowCityId.pop() : null
  searchMsg.value = msg
  reqData()
 }
const resetBtn = () => {
  ResetScroll()
  searchMsg.value = {
    vehicleNum:props.vehicleNumType
  }
  reqData()
}
</script>

<style lang="less" scoped>
.itemTitle {
  padding: 10px 0 0 10px;
  background: #f7f8fc;
  border-radius: 5px 5px 0 0;
}
.itemBox {
  padding: 0 10px;
  background: #f7f8fc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  padding-bottom: 10px;
  border-radius: 0 0 5px 5px;
  .tips {
    font-size: 12px;
    color: #999;
  }
}
.stateStyle {
  padding: 4px 8px;
  background: #edf0f9;
  border-radius: 4px;
  width: 48%;
  margin-top: 10px;
  border-left: 5px solid transparent;
}
.arrBox {
  padding: 2px 8px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 12px;
}
.tipsIcon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  cursor: pointer;
}
</style>