<template>
  <div>
    <a-modal :visible="visible" title="配置" @cancel="cancel" width="35%" @ok="submit" :confirmLoading="btnLoading" :bodyStyle="{padding:'10px'}">
      <div class="m-b1">区域配置</div>
      <div v-if="loading1" class="flex ju-center">
        <a-spin />
      </div>
      <div v-else class="flex wrap ">
        <div v-for="item in ProvinceList" :key="item.id" style="width:25%;margin-bottom:5px">
          <a-checkbox v-model:checked="item.checked" :value="item.id">{{ item.label }}</a-checkbox>
        </div>
      </div>
      <div class="m-b1 m-t1">代班配置</div>
      <div v-if="loading2" class="flex ju-center">
        <a-spin />
      </div>
      <div v-else class="flex wrap ">
        <div v-for="item in employee" :key="item.id" style="width:25%;margin-bottom:5px">
          <a-checkbox v-model:checked="item.checked" :value="item.id">{{ item.empName }}</a-checkbox>
        </div>
      </div>
      <div class="m-b1 m-t1">台量配置</div>
      <a-radio-group v-model:value="vehicleNumType">
      <a-radio :value="0">全部</a-radio>
      <a-radio :value="1">散车</a-radio>
      <a-radio :value="2">批量车</a-radio>
    </a-radio-group>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { onlyProvince, configAdd, allGroupEmployee } from '@/api/newTransport/newTransport'
import { message } from 'ant-design-vue'

const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  selProvince: {
    type: Array,
    default: []
  },
  selEmployee: {
    type: Array,
    default: []
  },
  vehicleNumType:{
    type: Number,
    default: 0
  }
})
const vehicleNumType = ref(0)
const ProvinceList = ref([])
const employee = ref([])
const loading1 = ref(false)
const loading2 = ref(false)
const btnLoading = ref(false)
const cancel = () => {
  emit('update:visible', false)
}
const submit = () => {
  btnLoading.value = true
  let list = ProvinceList.value.filter(item => item.checked)
  let dispatchProvinceList = list.map(item => item.value)
  let list2 = employee.value.filter(item => item.checked)
  let reliefList = list2.map(item => item.empId)
  configAdd({
    dispatchProvinceList: dispatchProvinceList,
    reliefList: reliefList,
    vehicleNumType:vehicleNumType.value
  }).then(res => {
    if (res.code !== 10000) return
    emit('success')
    message.success('保存成功')
    cancel()
  }).finally(() => {
    btnLoading.value = false
  })

}
onMounted(() => {
  vehicleNumType.value = props.vehicleNumType
  loading1.value = true
  onlyProvince().then(res => {
    if (res.code === 10000) {
      if (props.selProvince?.length > 0) {
        props.selProvince.forEach(item => {
          res.data.forEach(item2 => {
            if (item.value == item2.value) {
              item2.checked = true
            }
          })
        })
      }
      ProvinceList.value = res.data
    }
  }).finally(() => {
    loading1.value = false
  })
  loading2.value = true
  allGroupEmployee().then(res => {
    if (res.code === 10000) {
      if (props.selEmployee?.length > 0) {
        props.selEmployee.forEach(item => {
          res.data.forEach(item2 => {
            if (item == item2.empId) {
              item2.checked = true
            }
          })
        })
      }
      employee.value = res.data
    }
  }).finally(() => {
    loading2.value = false
  })
})
</script>

<style lang="less" scoped>
</style>