<template>
<div>
  <LocationQuery :subItemList="subItemList"></LocationQuery>
</div>
</template>

<script setup>
import { ref } from 'vue'
import LocationQuery from '../../components/LocationQuery'
const props = defineProps({
  subItemList:{
    type:Object,
    default:[]
  }
})

</script>

<style lang="less" scoped>

</style>