<template>
  <div class="small-car ">
    <div class="data-statistics">
      <div v-for="(item, index) in statisticsData" :key="index" class="data-statistics-content">
        <div @click="statisticsSearch(item, index)" class="statistics-content" :class="index == globalIndex?'changeSty':''" :style="{ flex: index === statisticsData.length - 1 ? '1' : '' }">
          <div class="statistics-title">{{ item.taskStatus?.label }}</div>
          <div class="data-statistics-data">{{ item.num }}</div>
          <div v-show="item.status === 1" class="data-status">待审核 00</div>
        </div>
        <a-divider type="vertical" style="height: 60px; background-color: #eee" />
      </div>
    </div>
    <a-divider style="height: 10px;margin-top:0; background-color: #EEEEEE;border:none" />
    <div style="margin-top: -20px;">
      <SearchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    </div>
    <a-divider style="height: 1px;margin-top:0; background-color: #EEEEEE;border:none" />
    <div class="content">
      <a-button class="m-b1" v-if="!type" @click="sendShow = true" type="primary">派单</a-button>
      <a-table size="small" :columns="columns" bordered :loading="loading" :row-key="record => record.id" :data-source="orderList" @change="handleTableChange" :pagination="pagination" :scroll="{ x: 1600, y: 400 }">
        <template #add="{ record }">
          <a @click="lookAdd(record)">任务操作</a>
        </template>
      </a-table>
    </div>

    <!-- 派单模块 -->
    <SendModal @status="status" v-model:visible="sendShow" v-if="sendShow"></SendModal>
    <!-- 详情模块 -->
    <DetailsModal v-model:visible="detailsShow" :lookID="lookID" @statusAdd="status" v-if="detailsShow"></DetailsModal>

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { vehicleTaskStatistics, vehicleTaskPage  } from '@/api/transport/transport'
import SearchList from '@/components/searchList'
import SendModal from './sendModal';
import DetailsModal from './detailsModal';
import { cloneDeep } from 'lodash-es';
import { addressTran } from '@/utils/util'
const props  = defineProps({
  type:{
    type: String,
    default: ''
  },
 })

const searchMsg = ref({})
const statisticsData = ref([])
const orderList = ref([])
const sendShow = ref(false)
const detailsShow = ref(false)
const orderInfo = ref({})
const loading = ref(false)
const globalIndex = ref(null)
const taskStatus = ref(null)
const lookID = ref('')
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const handleTableChange = (e) => {
  pagination.value = e
  reqVehicleTaskPage()
}
// 查看
const lookAdd = (record) => {
  lookID.value = record.taskId
  detailsShow.value = true
 
}

const statisticsSearch = (item, index) => {
  if(index === globalIndex.value){
    pagination.value.current = 1
    taskStatus.value = null
    searchMsg.value.taskStatus = null
    globalIndex.value = null
    reqVehicleTaskPage()
    return
  }
  taskStatus.value = item.taskStatus.value
  globalIndex.value = index
  searchMsg.value.taskStatus = item.taskStatus.value
  pagination.value.current = 1
  reqVehicleTaskPage()
}


// 统计信息
const getStatistics = () => {
  vehicleTaskStatistics({}).then(res => {
    if (res.code === 10000 && res.data) {
      statisticsData.value = res.data
    }
  })
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  searchMsg.value.taskStatus = taskStatus.value
  reqVehicleTaskPage()
}
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = cloneDeep(e)
  searchMsg.value.startAddress = addressTran(searchMsg.value.startAddress)
  searchMsg.value.endAddress = addressTran(searchMsg.value.endAddress)
  searchMsg.value.taskStatus = taskStatus.value
  reqVehicleTaskPage()
}
// 分页
const reqVehicleTaskPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    page: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  vehicleTaskPage(msg).then(res => {
    if (res.code !== 10000) return
    orderList.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const status = () => {
  getStatistics()
  reqVehicleTaskPage()
}


onMounted(() => {
  getStatistics()
  reqVehicleTaskPage()
})
const columns = ref([
  {
    title: '操作',
    align: 'center',
    width: '6%',
    slots: {
      customRender: 'add'
    }
  }, {
    title: '任务号',
    align: 'center',
    dataIndex: 'taskId',
    width: '10%'
  },
  {
    title: '承运商名称',
    align: 'center',
    dataIndex: 'carrierName',
  },
  {
    title: '任务段',
    dataIndex: 'taskName',
    align: 'center',
  },
  {
    title: '派单价',
    dataIndex: 'taskAmt',
    align: 'center',
  },
  {
    title: '派单时间',
    dataIndex: 'scheduleTime',
    width: '8%',
    align: 'center',
  },
  {
    title: '派单人',
    dataIndex: 'arranger',
    align: 'center',
  },
  {
    title: '起运地',
    dataIndex: 'lineStart',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'lineEnd',
    align: 'center',
  },
  {
    title: '车牌号',
    dataIndex: 'vinNos',
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'taskStatus.label',
    align: 'center',
  },
  {
    title: '接单时间',
    dataIndex: 'receivingTime',
    align: 'center',
  },
  {
    title: '最晚完成时间',
    dataIndex: 'comFinishTime',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
  },
  {
    title: '是否超时',
    dataIndex: 'timeOut',
    align: 'center',
  },
]
)
const iptData = ref([
  {
    type: "address",
    placeholder: "派单路线起",
    value: null,
    prop: "startAddress",
    changeOn: true,
    width: 150,
  },
  {
    type: "address",
    placeholder: "派单路线止",
    value: null,
    prop: "endAddress",
    changeOn: true,
    width: 150,
  },
  {
    type: "input",
    placeholder: "任务号",
    value: null,
    prop: "taskId",
    width: 190,
    opt: [],
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "carrierName",
    width: 150,
    opt: [],
  },

  {
    type: "input",
    placeholder: "派单人",
    value: null,
    prop: "arranger",
    width: 150,
    opt: [],
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
    opt: [],
  },
]) 
</script>

<style lang="less" scoped>
.data-statistics {
  display: flex;
  align-items: flex-end;
  position: relative;
  left: 0;
  transition: all 0.5s;

  .data-statistics-content {
    display: flex;
    flex: 0 0 8vw;
    align-items: center;
    justify-content: center;

    .statistics-content {
      width: 100%;
      position: relative;
      display: flex;
      color: #344563;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 15px 0px 25px 0px;

      .data-statistics-data {
        font-weight: 400;
        font-size: 25px;
        font-family: 'Arial Normal', 'Arial', sans-serif;
      }

      .data-status {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        text-align: center;
        color: #d9001b;
        font-size: 12px;
      }
    }
  }
}

.content {
  padding: 10px;
  margin-top: -20px;
  :deep(.ant-btn-primary) {
    background: #0066cc;
    border-color: #0066cc;
  }
}

.changeSty {
color: #ffffff !important;
  background: rgba(0, 102, 204, 0.8);
  border-radius: 8px;
}
</style>