import * as echarts from 'echarts';



export const ConversionStatistics = (box,xname,data1,data2,data3,data4 ) => {
  var chartDom = document.getElementById(box);
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      // formatter: function (params) {
      //   console.log(params);
      //   params = params[0];
      //   return (
      //     '总成交：' + params.data.clueDeal + '个'
      //     + '<br/>' +
      //     '转换率：' + params.data.rate + '%'
      //     + '<br/>' +
      //     '毛利：' + '￥' + params.data.grossProfit + '元'
      //   );
      // },
      // axisPointer: {
      //   animation: false
      // }
    },
    grid: {
      left: '2%',   // 左边距为容器宽度的10%
      right:'3%',
      top:'6%',
      containLabel: true // 包含坐标轴的标签
    },
    legend: {},
    backgroundColor: '#fff',
    xAxis: {
      type: 'category',
      scale: true,
      nameLocation: 'end',
      boundaryGap: false,
      data: xname
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    series: [
      {
        name: '总成交',
        type: 'line',
        showSymbol: true,
        smooth: true,
        data:data1
      },
      {
        name: '总线索',
        type: 'line',
        showSymbol: false,
        smooth: true,
        data: data2
      },
      {
        name: '转换率',
        type: 'line',
        showSymbol: false,
        smooth: true,
        data: data3
      },
      {
        name: '毛利',
        type: 'line',
        showSymbol: false,
        smooth: true,
        data: data4
      }
    ]
  };

  option && myChart.setOption(option)
}
