<template>
  <div style="height: 640px;">
    <div id="mapDiv" style="width:100%; height:100%"></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
var map;
var zoom = 8;
onMounted(() => {
//   map = new T.Map('mapDiv');
//   map.centerAndZoom(new T.LngLat( 80.427017,44.15362), zoom);
//   map.setMapType(T.MapType.VECTOR);
//  let  points = [];
//             points.push(new T.LngLat( 80.427017,44.15362));
//             points.push(new T.LngLat( 75.387299,40.543006));
//             points.push(new T.LngLat(75.957214, 41.228161));
//             points.push(new T.LngLat( 76.745437, 43.228142));
//             //创建线对象
//             var line = new T.Polyline(points,{
//               weight:3,
//               color:'#0066CC',
//               lineStyle:'solid', 
//               opacity:1
//             })
//             points.forEach(item => {
//               var marker = new T.Marker(item);// 创建标注
//               marker.setZIndexOffset({num:1})
//               map.addOverLay(marker);
//               var label = new T.Label({
//                 text: "天地图：",
//                 position: item,
//                 offset: new T.Point(-9, -45)
//             });
//             label.setZindex(10000)
//             map.addOverLay(label);
//             })
            
         
//             // 向地图上添加线
//             map.addOverLay(line);
//                 //创建对象
//                 var ctrl = new T.Control.MapType();
//             //添加控件
//             map.addControl(ctrl);
})
</script>


<style lang="less" scoped>


</style>