<template>
  <div class="flex ju-between m-t1">
    <div v-for="item in StatisticsData" :key="item.id" class="itemBox">
      <div class="title flex ju-between m-b1" >
        <span class="itemTitle">{{item.title}}</span>
        <span>{{item.progressText}}:{{ item.timeRate }}%</span>
      </div>
      <lyStatistics :widths="item.completeRate" :timeRate="item.timeRate" :color="item.color"></lyStatistics>
      
      <div class="flex ju-between m-t2 text">
         <div>
          <span>{{ item.overallIndicatorsText }}</span>
          <div class="num">￥{{ item.targetAmt }}</div>
         </div>
         <div>
          <span>{{ item.playAmountText }}</span>
          <div class="num" :style="{color: item.color}">￥{{ item.completeTargetAmt }}</div>
         </div>
         <div>
          <span v-if="item.unfinishedTargetAmt<0" >已超额完成(元)</span>
          <span v-else>{{ item.unaccomplishedText }}</span>
          <div class="num" :style="{color: item.unfinishedTargetAmt<0 ? item.color : '#c50303' }" > {{ returnText(item.unfinishedTargetAmt)  }}</div>
         </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import lyStatistics from '@/components/ly-statistics'

const props = defineProps({
  StatisticsData: {
    type: Array,
    default: () => []
  }
})

const returnText = (num) => {
  if(num>=0){
    return `￥${num}`
  }else{
    return `￥${Math.abs(num)}`
  }
}


</script>

<style lang="less" scoped>
.itemBox {
  padding: 20px 10px;
  background: #fff;
  width: 33%;
}
.title {
  color: #6b778c;
  .itemTitle{
    color: #344563;
    font-size: 18px;
    font-weight: 700;
  }
}
.text{
  span{
    color: #6b778c;
  }
  .num{
    color: #344563;
    font-size: 18px;
    font-weight: 700;
  }
}
</style>