import { ref } from 'vue'


//  0超时
export const carryiptData0 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt: []
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepetitive",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },

])
export const carrycolumns0 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '超时',
    dataIndex: 'overTime',
    slots: {
      customRender: 'overTime'
    },
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
    width:80
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '跟进',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'distribution',
    slots:{
      customRender:'distribution'
    },
    align: 'center',
    width: 80,
  },
])