<template>
  <div style="padding-right:10px">
    <div class="arranged-content">
      <h3>已安排车</h3>
      <a-table :columns="arrangedColumns" size="small" bordered :row-key="record => record.orderVehicleId"
        :data-source="arrangedList" :pagination="false">
        <template #brand="{ record }">
          <div>
            {{ record.brand }}{{ record.model }}
          </div>
        </template>
        <template #operation="{ record }">
          <div>
            <a v-show="!confirmStatus" @click="deleteData(record)">移除</a>
            <span class="disabled" v-show="confirmStatus">移除</span>
          </div>
        </template>
      </a-table>
    </div>
    <div style="text-align:right;margin-top:10px">
      <a-button type="primary" v-show="!confirmStatus" :disabled="arrangedList.length <= 0"
        @click="abnormalReport">异常上报</a-button>
    </div>
    <div>
      <h3>选择小车</h3>
      <div style="display:flex;margin-bottom:10px">
        <a-input style="width:200px;margin-right:10px" v-model:value="searchForm.orderId" placeholder="订单号" allowClear/>
        <a-input style="width:120px;margin-right:10px" v-model:value="searchForm.vinNo" placeholder="车牌号" allowClear/>
        <a-input style="width:120px" v-model:value="searchForm.salesman" placeholder="业务员" allowClear/>
        <!-- <a-cascader style="margin-left:10px" v-model:value="startData" :show-search="{ filter }"
          :options="$store.state.app.cityDict" :changeOnSelect="true" @change="setStartAddress" placeholder="选择起始地" />
        <a-cascader :show-search="{ filter }" v-model:value="endData" :options="$store.state.app.cityDict"
          :changeOnSelect="true" @change="setEndAddress" placeholder="选择目的地" /> -->
        <a-button style="margin-left:10px" type="primary" @click="searchData">搜索</a-button>
        <!-- <a-button style="margin-left:10px" @click="resetData">重置</a-button> -->
      </div>
      <a-table :columns="allowColumns" size="small"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, onSelect: onSelect, getCheckboxProps: checkBox }"
        :row-key="record => record.orderVehicleId" :data-source="allowList" bordered :pagination="pagination"
        :loading="loading" @change="handleTableChange">
        <template #brand="{ record }">
          <div>
            {{ record.brand }}{{ record.model }}
          </div>
        </template>
        <template #address="{ record }">
          <div>
            <div>起：{{ record.startAddress }}</div>
            <div>终：{{ record.endAddress }}</div>
          </div>
        </template>
        <template #operation="{ record }">
          <div>
            <a v-show="!confirmStatus" @click="loadVeh(record)">选择</a>
            <span v-show="confirmStatus" class="disabled">选择</span>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 异常上报 -->
    <AddReport ref="addReportRef" :windowShow="addReportShow" :vehicleDetail="{}" @reportData="addReportData" />
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, ref } from 'vue'
import { addReport } from '@/api/transport/transport'
import { smallVehcileAll } from '@/api/transport/truck'
import AddReport from '../comon/addReport.vue'
import { message } from 'ant-design-vue'
import { IfNull } from '@/utils/util'

export default {
  components: {
    AddReport
  },
  setup (porps, context) {
    const addReportRef = ref(null)
    const state = reactive({
      loading: false,
      addReportShow: false,
      confirmStatus: false,
      searchForm: {
        orderId: '',
        vinNo: '',
        salesman: null,
        startAddress: {},
        endAddress: {}
      },
      mirrorSearchForm: {},
      allowColumns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo'
        },
        {
          title: '车型',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId'
        },
        // {
        //   title: '调度员',
        //   dataIndex: 'dispatchType'
        // },
        {
          title: '业务员',
          dataIndex: 'salesman'
        },
        {
          title: '运送地址',
          dataIndex: 'address',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '下单时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      arrangedColumns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo'
        },
        {
          title: '车型',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId'
        },
        {
          title: '调度员',
          dataIndex: 'dispatcher'
        },
        {
          title: '业务员',
          dataIndex: 'salesman'
        },
        {
          title: '运送地址',
          dataIndex: 'endAddress',
          slots: {
            customRender: 'endAddress'
          }
        },
        {
          title: '下单时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      startData: [],
      endData: [],
      allowList: [],
      arrangedList: [],
      selectedRowKeys: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const searchData = () => {
      if(!IfNull(state.searchForm.orderId) && !IfNull(state.searchForm.vinNo) && !IfNull(state.searchForm.salesman)){
        message.warning('请选择一个查询条件')
        return
      }
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.allowList = []
      state.startData = []
      state.endData = []
      state.pagination.current = 1
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const loadData = () => {
      state.loading = true
      clearData()
      smallVehcileAll({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.allowList = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
      state.searchForm.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
      state.searchForm.endAddress.area = value[2]
    }
    const loadVeh = record => {
      let status = null
      if (state.arrangedList.length > 0) status = state.arrangedList.every(item => item.orderVehicleId !== record.orderVehicleId)
      else status = true
      setTimeout(() => {
        if (status === true) {
          if (state.arrangedList.length > 0) {
            state.arrangedList.push(record)
            state.selectedRowKeys.push(record.orderVehicleId)
          } else {
            state.arrangedList.push(record)
            state.selectedRowKeys.push(record.orderVehicleId)
          }
        } else message.error('该车已经是选中状态了')
      }, 10)
    }
    const deleteData = record => {
      let index = state.arrangedList.findIndex(item => item.vinNo == record.vinNo)
      state.arrangedList.splice(index, 1)
      state.selectedRowKeys.splice(index, 1)
    }
    const checkBox = record => {
      let obj = {}
      if (state.confirmStatus === true) {
        obj.disabled = true
      } else {
        obj.disabled = false
      }
      return obj
    }
    const onSelect = e => {
      console.log(e);
    }
    const onSelectChange = (e, v) => {
      state.arrangedList = v
      state.selectedRowKeys = e
    }
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }
    const clearData = () => {
      state.arrangedList = []
      state.selectedRowKeys = []
      state.confirmStatus = false
    }
    const abnormalReport = () => {
      state.addReportShow = true
      setTimeout(() => {
        addReportRef.value.getData()
      })
    }
    const addReportData = e => {
      if (e.reportType !== null && e.reportType !== undefined && e.reportType !== 0) {
        delete e.belong
        let submit = {
          ...e,
          orderVehicleIds: state.selectedRowKeys
        }
        addReport(submit).then(res => {
          if (res.code === 10000) {
            state.addReportShow = false
            message.success('批量异常上报成功')
            state.arrangedList = []
            state.selectedRowKeys = []
            loadData()
          }
        }).finally(() => {
          addReportRef.value.confirmLoading = false
        })
      } else state.addReportShow = false
    }
    return {
      ...toRefs(state),
      addReportRef,
      handleTableChange,
      loadData,
      onSelectChange,
      addReportData,
      searchData,
      setStartAddress,
      setEndAddress,
      onSelect,
      checkBox,
      loadVeh,
      deleteData,
      resetData,
      clearData,
      abnormalReport
    }
  }
}
</script>

<style lang="less" scoped>
.arranged-content {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: no-drop;
}
</style>
