<template>
<div>
  <!-- 批量上报 -->
  <a-modal title="批量上报" :visible="batchReportShow" width="60%" footer="" :bodyStyle="{ paddingRight: 0 }" destroyOnClose @cancel="cancel">
      <BatchReport ref="batchReportRef" />
    </a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
import BatchReport from '@/views/components/transport/smallVehicleOperate/batchReport'

const emit = defineEmits(['update:batchReportShow'])
const props = defineProps({
  batchReportShow: {
    type: Boolean,
    default: false
  }
})

const batchReportRef = ref(null)

const cancel = () => {
  emit('update:batchReportShow', false)
}
</script>

<style lang="less" scoped>

</style>