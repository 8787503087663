<template>
  <div>
    <a-modal :visible="visible" title="本月指标看板" width="90%" wrapClassName="modal-box" :bodyStyle="{padding: '10px',background: '#F0F2F5',}" footer="" @cancel="cancel">

      <div class="totalBox flex">
        <div style="width: 28%;" class="flex ju-between ">
          <div class="flex al-center">
            <div class="imgBox">
              <img src="@/assets/images/dataCenter/u117.png" alt="">
            </div>
            <div class="textBox m-l1">
              <div class="title">私海新增总数</div>
              <div class="num">{{ dataMsg.privateNew }}</div>
            </div>
          </div>
          <div class="flex al-center ">
            <div class="imgBox" style="background: #E2F8F6">
              <img src="@/assets/images/dataCenter/u114.png" alt="">
            </div>
            <div class="textBox m-l1">
              <div class="title">合作新增总数</div>
              <div class="num">{{ dataMsg.cooperationNew }}</div>
            </div>
          </div>
        </div>
        <!-- <div style="width: 72%;" class="flex ju-end  al-center">
          <div >
            <a-month-picker :allowClear="false" :style="{ width: 120 + 'px'}" @change="changeTime" v-model:value="monthValue" placeholder="请选择" />
          </div>
        </div> -->
      </div>

      <div>
        <Statistics :StatisticsData="StatisticsData"></Statistics>
      </div>

      <div class="m-t1">
        <StatisticalTables @lookAdd="lookAdd" :loading="loadingLables" :columns="columns" :dataSource="dataSource"></StatisticalTables>
      </div>

      <div v-if="loading" class="coverLayer flex ju-center al-center">
        <div>
          <a-spin size="large" />
        </div>
      </div>

      <!-- 查看部门 -->
      <a-modal v-model:visible="orgVisible" :title="titleOrg" footer="" width="85%" :destroyOnClose="true" :zIndex="9999">
        <StatisticalTables titleText="" :scrollNum="700" @lookAdd="lookAOrg" :loading="loadingLables" :columns="columnsOrg" :dataSource="dataOrg"></StatisticalTables>
        <!-- 查看个人 -->
      </a-modal>
      <a-modal v-model:visible="manVisible" :title="titleMan" footer="" width="80%" :destroyOnClose="true" :zIndex="99999">
        <StatisticalTables titleText="" :scrollNum="700" @lookAdd="lookMan" :loading="loadingLables" :columns="columnsMan" :dataSource="dataMan"></StatisticalTables>
      </a-modal>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { columns1, columns2, columns3 } from './indicatorDashboard.js'
import Statistics from './statistics.vue';
import moment from "moment";
import StatisticalTables from './statisticalTables.vue';
import { statisticBoard, customerList, salesmanList, orgList } from '@/api/dataCenter/indicatorDashboard'
import {  transitionTimeYM,  } from '@/utils/util'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['update:visible'])
const columns = ref([])
const changeId = ref(null)
const monthValue = ref(moment(new Date()))
const dataMsg = ref({})
const loading = ref(false)
const dataSource = ref([])
const loadingLables = ref(false)
const orgVisible = ref(false)
const columnsOrg = ref([])
const dataOrg = ref([])
const titleOrg = ref('')
const manVisible = ref(false)
const columnsMan = ref([])
const dataMan = ref([])
const titleMan = ref('')
const cancel = () => {
  emit('update:visible', false)
}
//明细
const lookAdd = (item) => {
  if (changeId.value === 1) {
    lookAOrg(item)
    return
  }
  loadingLables.value = true
  titleOrg.value = item.orgName
  columnsOrg.value = columns2.value
  orgVisible.value = true
  salesmanList({
    targetDate: transitionTimeYM(monthValue.value) + '-01',
    dataType: changeId.value,
    orgId: item.orgId
  }).then(res => {
    if (res.code !== 10000) return
    dataOrg.value = res.data
  }).finally(() => {
    loadingLables.value = false
  })
}
const lookAOrg = (item) => {
  loadingLables.value = true
  titleMan.value = item.orgName ? item.orgName + '——' + item.salesman : item.salesman
  columnsMan.value = columns1.value
  manVisible.value = true
  customerList({
    targetDate: transitionTimeYM(monthValue.value)  + '-01',
    dataType: changeId.value,
    salesmanId: item.salesmanId
  }).then(res => {
    if (res.code !== 10000) return
    dataMan.value = res.data
  }).finally(() => {
    loadingLables.value = false
  })
}
const changeTime = () => {
  reqStatisticBoard()
}


const StatisticsData = ref([
  {
    title: '本月完成情况',
    progressText: "本月时间进度",
    timeRate: 0,
    completeRate: 0,
    color: '#396afc',
    overallIndicatorsText: '本月总指标(元)',
    targetAmt: 0,
    playAmountText: '已完成金额(元)',
    completeTargetAmt: 0,
    unaccomplishedText: '未完成(元)',
    unfinishedTargetAmt: 0
  },
  {
    title: '本周完成情况',
    progressText: "本周时间进度",
    timeRate: 0,
    completeRate: 0,
    color: '#7948EA',
    overallIndicatorsText: '预计指标(元)',
    targetAmt: 0,
    playAmountText: '已完成金额(元)',
    completeTargetAmt: 0,
    unaccomplishedText: '未完成(元)',
    unfinishedTargetAmt: 0
  },
  {
    title: '当日完成情况',
    progressText: "当日时间进度",
    timeRate: 0,
    completeRate: 0,
    color: '#00BAAD',
    overallIndicatorsText: '预计指标(元)',
    targetAmt: 0,
    playAmountText: '已完成金额(元)',
    completeTargetAmt: 0,
    unaccomplishedText: '未完成(元)',
    unfinishedTargetAmt: 0
  },
])
const screenData = ref([
  {
    title: '个人',
    id: 0
  },
  {
    title: '部门',
    id: 1
  },
  {
    title: '公司',
    id: 9
  },
])

const reqStatisticBoard = () => {
  loading.value = true
  statisticBoard({
    dataType: changeId.value,
    targetDate: transitionTimeYM(monthValue.value)  + '-01'
  }).then(res => {
    if (res.code !== 10000) return
    dataMsg.value = res.data
    getKey(res.data.monthComplete, StatisticsData.value[0])
    getKey(res.data.weekComplete, StatisticsData.value[1])
    getKey(res.data.dayComplete, StatisticsData.value[2])
    if (!changeId.value && changeId.value !== 0) {
      changeId.value = res.data.authType
      if (changeId.value === 0) {
        columns.value = columns1.value
      }
      if (changeId.value === 1 || changeId.value === 2) {
        columns.value = columns2.value
        screenData.value = screenData.value.slice(0, 2)
      }
      if (changeId.value === 9) {
        columns.value = columns3.value
      }
    }

    reqCustomerList()
  }).finally(() => {
    loading.value = false
  })
}
const getKey = (obj1, obj2) => {
  for (let key in obj1) {
    for (let key2 in obj2) {
      if (key === key2) {
        obj2[key] = obj1[key2]
      }
    }
  }
}
const reqCustomerList = () => {
  loadingLables.value = true
  let req = null
  if (changeId.value === 0) {
    req = customerList
  }
  if (changeId.value === 1 || changeId.value === 2) {
    req = salesmanList
  }
  if (changeId.value === 9) {
    req = orgList
  }
  req({
    targetDate: transitionTimeYM(monthValue.value)  + '-01',
    dataType: changeId.value,
  }).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data
  }).finally(() => {
    loadingLables.value = false
  })

}


onMounted(() => {
  reqStatisticBoard()
})

</script>
   
<style lang="less" scoped>
:global(.modal-box .ant-modal){
    top:40px
}
.totalBox {
  padding: 20px;
  background: #fff;
}
.imgBox {
  width: 68px;
  height: 68px;
  border-radius: 8px;
  background: #d5e4fa;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 46px;
    height: 46px;
  }
}

.screenBox {
  width: 90px;
  height: 40px;
  border: 1px solid #c1c7d0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screenBox:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.screenBox:nth-child(2) {
  border-left: none;
  border-right: none;
}
.screenBox:nth-child(3) {
  border-radius: 0 5px 5px 0;
}

.screenBox1 {
  width: 90px;
  height: 40px;
  border: 1px solid #c1c7d0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.screenBox1:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.screenBox1:nth-child(2) {
  border-left: none;
  border-radius: 0 5px 5px 0;
}

.active {
  border-color: #396afc;
  background: #396afc;
  color: #fff;
}
:deep(.ant-input) {
  padding: 8px 11px;
}

.num {
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
}

.coverLayer {
  width: 100%;
  // height: 80vh;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 99;
}
</style>