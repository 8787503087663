<template>
  <div>
    <WindowModal :windowShow="batchGiveShow" @statusShow="closeWindow" v-if="batchGiveShow">
      <template #titleContent>
        <div>
          批量交车
        </div>
      </template>
      <template #leftContent>
        <BatchGiveTable ref="batchGiveTableRef" @operateData="operateData" />
      </template>
      <template #rightContent>
        <HandInVehicle ref="handInVehicleRef" v-show="handInShow" :vehicleArr="batchGiveList" @statusShow="handInStatus" />
      </template>
    </WindowModal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import WindowModal from '@/views/components/windowModal/index.vue'
import BatchGiveTable from '@/views/components/transport/smallVehicleOperate/batchGiveTable'
import HandInVehicle from '@/views/components/transport/comon//handInVehicle.vue'
import { uploadVehicle as upload } from '@/api/global'
import { batchDelivery as batchDeliveryData } from '@/api/transport/transport'
import { message } from 'ant-design-vue'
const emit = defineEmits(['success'])

const handInShow = ref(false)
const batchGiveShow = ref(false)
const batchGiveTableRef = ref(null)
const handInVehicleRef = ref(null)
const batchGiveList = ref({})  // 批量操作组件传递数组

const handInStatus = e => {
  handInVehicleRef.value.confirmLoading = true
  if (!e.formData) handInShow.value = false
  else {
    handInVehicleRef.value.confirmLoading = true
    let orderVehicleIds = batchGiveList.value.map(item => item.orderVehicleId)
    upload(e.formData).then(res => {
      if (res.code === 10000) {
        let fileId = res.data.id
        let submit = {
          deliveryOfficeOrg: e.deliveryOfficeOrg,
          deliveryType: e.deliveryType,
          officeOrgId: e.officeOrgId,
          remark: e.remark,
          fileId: fileId,
          orderVehicleIds: orderVehicleIds
        }
        batchDeliveryData(submit).then(res => {
          if (res.code === 10000) {
            message.success('交车成功')
            handInVehicleRef.value.cancelClose()
            batchGiveShow.value = false
            emit('success')
          }
        }).finally(() => {
          if (handInVehicleRef.value) {
            handInVehicleRef.value.confirmLoading = false
          }
        })
      }
    })
  }
}

const reqData = () => {
  setTimeout(() => {
  batchGiveTableRef.value.loadData()
  },100)
}
const operateData = e => {
  batchGiveList.value = e
  handInShow.value = true
}
const closeWindow = e => {
  batchGiveShow.value = e
  setTimeout(() => {
    if (batchGiveTableRef.value) batchGiveTableRef.value.clearData()
  }, 100)
}

defineExpose({
  batchGiveShow,
  reqData
})
</script>

<style lang="less" scoped>
</style>