<template>
  <div>
    <a-modal :visible="visible" :title="title" centered footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">

      <div class="statusBox flex">
        <div v-for="(item,index) in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          <span v-if="index !== 0"> </span>{{ item.name }}&nbsp;&nbsp;{{ item.total }}
        </div>
      </div>
      <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a>{{ dataLength }}</a>&nbsp;条</div>
        <a-table ref="tableRef" size="small" :dataSource="data" :rowKey="(item,index) => index" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500,x:x?x:false}">
          <template #sort="{ index }">
            {{index+1}}
          </template>
          <template #receiveTime="{record}">
            {{ record.receiveTime }}
            <div>{{ record.firstSaleName }}</div>
          </template>
          <template #overTime="{record}">
            <span style="color:#DE0505" v-if="record.overTime">已超时{{ record.overTime }}分钟</span>
          </template>
          <template #clueCreateTime="{record}">
            {{ record.clueCreateTime  }} 
            <div>{{record.clueCreator }}</div>
          </template>
          <template #dealTime="{record}">
            <span v-if="record.isDeal.value === 1">{{ record.dealTime }}天</span>
          </template>
          <template #receivedTime="{record}">
            <span v-if="record.receivedTime">{{ record.receivedTime }}分钟</span>
          </template>
          <template #totalGrossProfit="{record}">
            {{ record.totalGrossProfit }}<span v-if="record.totalGrossProfit">元</span>
          </template>
          <template #faller="{record}">
            <span v-if="record.customerClass.value ===0 || record.customerClass.value ===-1">{{ record.faller }}</span>
          </template>
          <template #startAddress="{record}">
            <span v-if="record.startAddress">起：{{ record.startAddress }}</span>
            <div v-if="record.endAddress"> 终：{{ record.endAddress }}</div>
          </template>
          <template #remark="{record}">
            <a-tooltip v-show="record.remark" color="#fff">
              <template #title>
                <span style="color:#000">{{ record.remark }}</span>
              </template>
              <div class="remark">{{ record.remark }}</div>
            </a-tooltip>
          </template>
          <template #add="{record}">
            <span>
              {{ record.followContent }}
            </span>
            <a @click="remind(record)">跟进</a>
          </template>
          <template #distribution="{record}">
            <a @click="mandatory=true,customerId = record.customerId">分配</a>
          </template>
        </a-table>
      </div>
      <!-- 提醒跟进记录 -->
      <a-modal title="跟进记录" width="80%" placement="right" v-model:visible="reminShow" :destroyOnClose="true" :footer="false">
        <FollowAdd :customerId="customerId" :followType="9" @addSuccess="addSuccess" />
      </a-modal>
      <!-- 强制分配 -->
      <a-modal v-model:visible="mandatory" width="40%" title="强制分配" @ok="distribution" @cancel="name1 = []">
        <div> 选择负责人<span style="color:#f00">*</span></div>
        <a-select style="width:100%" mode="multiple" :value="name1" :not-found-content="fild ? undefined : null" :filter-option="false"
         show-search placeholder="请输入员工姓名或关键字" @inputKeyDown="search" @search="assignment" @change="memberChange">
          <a-select-option v-for="item in options" :key="item.id" :value="item.name">{{item.name}}-{{item.no}}</a-select-option>
          <template v-if="fild" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
        <span style="color:#f00;font-size:14px">在输入完成后，按下回车搜索</span>
      </a-modal>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import { useStore } from 'vuex'
import FollowAdd from '@/views/crmManagement/tool/customerFollow/add.vue'
import { employeeList } from '@/api/crmManagement/setRules'
import { allocate } from '@/api/crmManagement/invalid'
import { message } from 'ant-design-vue'

const empId = ref('')
const name = ref('')
const name1 = ref([])
const timer = ref(undefined)
const fild = ref(false) 
const options = ref([])
const mandatory = ref(false)
const reminShow = ref(false)
const customerId = ref(null)
const store = useStore()
const emit = defineEmits(['update:visible', 'searchAdd', 'resetBtn', 'setRemaekOk'])
const props = defineProps({
  x:{
    type:Number,
    default:0
  },
  item: {
    type: Object,
    default: {}
  },
  title: {
    type: String,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  iptData: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  totalClueNum: {
    type: Number,
    default: 0
  },
  customerClassClueList: {
    type: Object,
    default: []
  },
  dataLength: {
    type: Number,
    default: 0
  },
  colorIdx: {
    type: Number,
    default: null
  },
  dealID: {
    type: Number,
    default: null
  }
})

  // 强制分配
  const distribution = () => {
      allocate({
        customerId: customerId.value,
        empId: empId.value
      }).then(res => {
        if (res.code === 10000) {
          name1.value = []
          mandatory.value = false
          message.success('分配成功')
          emit('setRemaekOk',customerId.value)
        
        }
      }).catch(err => { console.log(err) })
    }
const memberChange = (e, val) => {
      if (e.length > 1) {
        e.splice(0, 1)
        val.splice(0, 1)
        e[0] = val[0].value
      }
      if (val.length > 0) {
        empId.value = val[0].key
      }
      options.value = []
    }
const assignment = e => {
      if (e !== '') name.value = e
    }
const search = e => {
      if (e.keyCode === 13) {
        fild.value = true
       timer.value = setTimeout(() => {
          employeeList({
            current: 1,
            name: name.value,
            size: 10
          }).then(res => {
            if (res.code === 10000) {
              options.value = res.data
              fild.value = false
            }
          }).catch(err => { console.log(err) })
        }, 500)
      }
    }
const remind = record => {
  customerId.value = record.customerId
  reminShow.value = true
}
const addSuccess = (e) => {
 props.data.forEach(item => {
  if(item.customerId === customerId.value){
    item.followContent = e
  }
 })
}
const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(null)

const searchAdd = (e) => {
  emit('searchAdd', e)
}
const resetBtn = () => {
  emit('resetBtn')
}
const changeBtn = (e) => {
  indexKey.value = e.colorType
  emit('changeBtn', e.colorType)
}


const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: 0,
    colorType: null
  },
])
function addEvent (element, eventType, callback) {
  if (element.addEventListener) {
    element.addEventListener(eventType, callback, false);
  } else if (element.attachEvent) {  // 兼容IE8及以下
    element.attachEvent('on' + eventType, callback);
  } else {
    element['on' + eventType] = callback;  // 兼容IE使用旧式方法
  }
}

const tableRef = ref(null)
onMounted(() => {
  if (props.dealID !== null) {
    tabstatus.value = [
      {
        name: '新成交',
        id: 1,
        total: props.item.dealNewClue,
        colorType: 0
      },
      {
        name: '重复成交',
        id: 1,
        total: props.item.dealRepeatClue,
        colorType: 1
      },
    ]
    
    indexKey.value = props.dealID
  } else {
    if (props.customerClassClueList.length > 0) {
      tabstatus.value[0].total = props.totalClueNum
      props.customerClassClueList.forEach((opt) => {
        let msg = {
          name: opt.customerClass.label,
          total: opt.clueNum,
          colorType: opt.customerClass.value
        }
        tabstatus.value.push(msg)
      })
    } else {
      tabstatus.value = []
    }
    indexKey.value = props.colorIdx
  }

  nextTick(() => {
    setTimeout(() => {
      const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
      addEvent(tableBody, 'scroll', (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          // 滚动条触底，执行加载数据的操作
          emit('theBottom')
        }
      })
    }, 1000)
  })

})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  height: 620px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>