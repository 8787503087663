import {
  getRequest,
  postBodyRequest
} from '@/utils/axios'
export function statisticBoard (params) {
  return postBodyRequest('/anal/month/target/statistic-board', params)
}
// 部门业务员指标明细完成统计
export function salesmanList (params) {
  return postBodyRequest('/anal/month/target/salesman/list', params)
}
// 部门指标明细完成统计
export function orgList (params) {
  return postBodyRequest('/anal/month/target/org/list', params)
}
// 客户指标明细完成统计
export function customerList (params) {
  return postBodyRequest('/anal/month/target/customer/list', params)
}
