<template>
  
    <div   @click="openList" style="margin-top:6px"  class=" cur-p customTags flex ju-between al-center">
      <span> {{ item.title }}</span>&nbsp;
      <CountTo color="#0066CC"  fontSize="18px" :endVal="item.num" />
    </div>
   <CustomModal v-if="cusVisible" :vehicleNumType="vehicleNumType" v-model:visible="cusVisible"  :subItemList="subItemList" :info="item" :data="data" ></CustomModal>
   <TaskModal v-if="taskVisible"   v-model:visible="taskVisible" :subItemList="subItemList" :info="item" :data="data" ></TaskModal>
</template>

<script setup>
import { ref } from 'vue'
import CustomModal from './components/CustomModal.vue' 
import TaskModal from './components/TaskModal.vue'
import CountTo from '@/components/countTo'


const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  subItemList:{
    type: Array, 
    default: [] 
  },
  vehicleNumType: {
    type: Number,
    default: 0
  }
})


const data = ref([])
const cusVisible = ref(false)    
const taskVisible = ref(false)
const openList = () => {
  if(props.item.pageType === 21){
    cusVisible.value = true
  }else{
    taskVisible.value = true
  }
 
}


</script>

<style lang="less" scoped>
.customTags {
  padding: 4px 10px;
  background: #EDF0F9;
  border-bottom: 1px solid #fff;
}
</style>