
import { postBodyRequest, getRequest, postBodyRequestPdf, postBodyRequestSpecial, fileRealUpload, exportRequestImg, fileUpload } from '@/utils/axios'

export function getOrgList (params) {
  return postBodyRequest('/cis/org/list', params)
}

export function getPositionList (params) {
  return postBodyRequest('/cis/position/list', params)
}

export function getCityList (params) {
  return getRequest('/cis/system/city', params)
}
// export function upload (params) {
//   return fileUpload(`/file/upload/${Math.floor(Math.random() * 1000)}/vehicle`, params)//
// }
export function uploadVehicle (params) {
  return fileUpload(`/file/upload/${Math.floor(Math.random() * 1000)}/vehicle`, params)//
}
export function getPCList (params) {
  return getRequest(`/cis/system/pc/${Math.floor(Math.random() * 1000)}`, params)
}
// export function uploadBpm (params) {
//   return fileUpload(`/file/upload/${Math.floor(Math.random() * 1000)}/vehicle`, params)//
// }
export function getCityStatusList (params) {
  return getRequest(`/cis/system/city-status/${Math.floor(Math.random() * 1000)}`, params)
}
// export function uploadPdf (params) {
//   return fileUpload(`/file/upload/${Math.floor(Math.random() * 1000)}/vehicle`, params)//
// }
export function getOnlyCityList (params) {
  return getRequest(`/cis/system/onlyCity/${Math.floor(Math.random() * 1000)}`, params)
}
export function inspectUpload (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${orderVehicleId}/file`, params)
}// 车辆检查图片
export function carPics (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${orderVehicleId}/page`, params)
}
export function vehicleDetail (orderId, params) {
  return getRequest(`/trans/order/${orderId}/statistics`, params)
}
// 车辆日志
export function getVehiclePage (params) {
  return postBodyRequest('/trans/order/vehicle/log/page', params)
}
export const fileUploadPublic = params => {
  return fileRealUpload(`/file/upload/${Math.floor(Math.random() * 1000)}/public`, params)
}
// 车辆发送需要的信息
export function getVehicleSendInfo (orderVehicleId) {
  return postBodyRequest(`/trans/order/vehicle/${orderVehicleId}/send`, null)
}
// 常规图片
export function conventional (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${orderVehicleId}/page/normal`, params)
}
// 删除图片
export function deleteVehiocleImg (id, params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${id}/delete`, params)
}
export function carPicsGroup (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${orderVehicleId}/page/injury`, params)
}
// 获取渠道列表
export function getChannelList (params) {
  return postBodyRequest('/market/channel/list', params)
}
export function fileDownload (params, fileName,type) {
  return exportRequestImg('/file/download', params, fileName,type)
}
// 托运用途
export function getShippingUse (params) {
  return postBodyRequest('/market/crm/label/customerLabelNameQuery', params)
}
// 新接口
// export function imageUpload (params) {
//   return fileUpload(`/file/upload/${Math.floor(Math.random() * 1000)}/vehicle`, params)//
// }

export function employeeList (params) {
  return postBodyRequest('/cis/employee/page', params)
}
// websocket
export function websocket (params) {
  return postBodyRequest('/bs/webSocket/unified_send', params)
}
// 获取承运商门店地址(订单修改使用)
export function carrierAddress (params) {
  return postBodyRequest('/carrier/carrier/address/store/address', params)
}
// 获取部门数据(树状)
export function getOrgTreeList (params) {
  return getRequest('/cis/org/list/tree', params)
}
// 所有承运商员工列表
export function getAllEmployeeList (params) {
  return postBodyRequest('/carrier/emp/getAllEmployeeList', params)
}
// 监控任务状态
export function getMonitorData (params) {
  return getRequest('/trans/order/vehicle-task/getMonitorTypeLevel', params)
}
// 获取车辆数组
export function getVehicles (orderId, params) {
  return getRequest(`/trans/order/vehicle/vin-no/${orderId}`, params)
}
export function dispatcherList (params) {
  return getRequest('/trans/order/config/dispatcher-list', params)
}