<template>
  <div>
    <div class="num1" v-if="num>0">
      ↑{{ num }}%
    </div>
    <div class="num2" v-if="num<0">
      ↓{{Math.abs(num)  }}%
    </div>
  </div>
</template>

<script setup> 
import { ref } from 'vue'
const props = defineProps({
  num: {
    type: Number ,
    default: 0
  }
})
 
</script>

<style lang="less" scoped>
.num1{ 
  color: #15ad31; 
  font-size: 12px;
  font-weight: normal;
  margin-left: 4px;
}
.num2{
  color: #d9001b; 
  font-size: 12px;
  font-weight: normal;
  margin-left: 4px;
}
</style>