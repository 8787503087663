<template>
  <div style="padding:10px 0px 10px 0;position: relative;">
    <!-- 搜索查询 -->
    <div class="search-div">
      <a-space v-if="executeType !== 2 || identicalCity">
        <a-input v-model:value="searchForm.carrierName" placeholder="请输入承运商名称" @pressEnter="searchCarrier" />
        <a-button type="primary" @click="searchCarrier">搜索</a-button>
      </a-space>
    </div>
    <div class="carrier-list-div">
      <div v-if="carrierList.length === 0 && !loading && isSearch">
        暂无承运商，请到“承运商管理-承运线路”添加线路！
      </div>
      <a-row :gutter="[24, 6]" v-for="(item, index) in   carrierList" :key="index">
        <a-col :span="19">
          <span>{{ item.carrierName }}</span>
          <span style="color:#0066CC" v-show="item.isSettled?.value === 1">[固]</span>
        </a-col>
        <a-col :span="5">
          <a-rate style="font-size:14px" :value="item.carrierLevel ? item.carrierLevel.value : 0" disabled />
        </a-col>
        <a-col :span="12">
          <span>负责人：{{ item.charge }}</span>
        </a-col>
        <a-col :span="12">
          <span>负责人电话：{{ item.chargeMobile }}</span>
        </a-col>
        <a-col :span="12">
          <span>业务类型：{{ item.carrierCapacityType ? item.carrierCapacityType.label : '' }}</span>
        </a-col>
        <a-col :span="12">
          <span>线路价格：￥{{ item.cost }}</span>
        </a-col>
        <a-col :span="12">
          <span>承运商地址：{{ item.carrierAddress }}</span>
        </a-col>
        <a-col :span="24">
          <a-button v-if="!type" type="primary" @click="choose(item)">选择该承运商</a-button>
          <a-button v-if="type === 1 && !chooseData.id" type="primary" @click="choose(item)">选择该承运商</a-button>
          <a-button v-if="type === 1 && chooseData.id " type="primary" @click="carrierChange">更换承运商</a-button>
        </a-col>
      </a-row>
    </div>
    <!-- 分页栏 -->
    <!-- <div class="paginate-div">
      <a-pagination size="small" :current="pagination.current" :total="pagination.total" :defaultPageSize="5"
        :show-total="total => `共 ${pagination.total} 条`" @change="paginationChange" />
    </div> -->
    <!-- 加载框 -->
    <div class="loading-div" v-show="loading"><a-spin size="large" /></div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from 'vue'
import { getPage as carrierPage, detailData } from '@/api/carrier/carrierList'

export default {
  props: {
    carrierId: String,
    carrierName: String,
    type: Number,
    executeType: Number,
    identicalCity:{
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const state = reactive({
      isSearch: false,
      loading: false,
      carrierId: '',
      type: props.type, // 用于判断发运模式操作与任务模式操作是否调取接口
      carrierList: [],
      chooseData: {},
      mirrorCarrierList: [],
      searchForm: {
        carrierName: ''
      },
      pagination: {
        current: 1,
        pageSize: 5,
        total: 1
      }
    })
    watch(() => props.type, (newVal, oldVal) => {
      state.carrierId = newVal
      state.searchForm.carrierName = props.carrierName
      state.type = props.type
      console.log(props);
    }, { deep: true })
    const searchCarrier = () => {
      if(!state.searchForm.carrierName) return
      state.pagination.current = 1
      getCarrierList()
    }
    const getCarrierList = () => {
      state.loading = true
      state.chooseData = {}
      carrierPage({
        ...state.searchForm,
        carrierId: state.carrierId,
        isBlock: 0,
        status: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.isSearch = true
          state.carrierList = res.data.records
          state.mirrorCarrierList = JSON.stringify(state.carrierList)
          state.pagination.total = res.data.total
          state.loading = false
        }
      })
    }
    const paginationChange = (e, v) => {
      state.pagination.current = e
      getCarrierList()
    }
    const choose = item => {
      state.chooseData = item
      if (state.type === 1) state.carrierList = [item]
      context.emit('confirmCarrier', item)
    }
    const carrierChange = () => {
      state.chooseData = {}
      context.emit('confirmCarrier', {})
      state.carrierList = JSON.parse(state.mirrorCarrierList)
    }

    return {
      ...toRefs(state),
      choose,
      searchCarrier,
      carrierChange,
      paginationChange
    }
  }
}
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

.ant-rate {
  color: #0066cc;
}

.loading-div {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.search-div {
  margin-bottom: 10px;
}

.carrier-list-div {
  padding-left: 10px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  :deep(.ant-row) {
    padding: 10px 0;
    margin-bottom: 15px;
    background-color: #fafafb;
  }
}

.paginate-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.celBox {
  border: 1px solid #1890ff !important;
  background: #1890ff !important;
}
</style>