<template>
  <div>
    <a-modal :visible="visible" title="渠道配置" @cancel="cancel" width="35%" @ok="submit" :confirmLoading="btnLoading" :bodyStyle="{padding:'10px'}">
  
      <div v-if="loading1" class="flex ju-center">
        <a-spin />
      </div>
      <div v-else class="checkBox">
        <div v-for="item in ProvinceList" :key="item.id"  >
         <div class="label"> {{ item.label }}</div>
         <div class="flex wrap " >
          <div v-for="item2 in item.children" :key="item2.id" style="width:25%;margin-bottom:5px">
            <a-checkbox v-model:checked="item2.checked" :value="item.id">{{ item2.label }}</a-checkbox>
          </div>
         </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { channelTree, configAdd } from '@/api/marketingConsole/marketingConsole'
import { message } from 'ant-design-vue'

const emit = defineEmits(['update:visible','success'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  selProvince: {
    type: Array,
    default: []
  },
  selEmployee: {
    type: Array,
    default: []
  },
})
const ProvinceList = ref([])
const loading1 = ref(false)
const btnLoading = ref(false) 
const cancel = () => {
  emit('update:visible', false)
} 
const submit = () => {
  btnLoading.value = true
  let list = []
   ProvinceList.value.forEach(item => {
      item.children.forEach(item1 => {
        if(item1.checked){
          list.push(item1)
        }
      })
  })
  let channelSubId = list.map(item => item.value)
  configAdd({
    channelSubId: channelSubId,
  }).then(res => {
    if (res.code !== 10000) return
    emit('success')
    message.success('保存成功，请等待页面数据稍后更新')
    cancel()
  }).finally(() => {
    btnLoading.value = false
  })

}
onMounted(() => {
  loading1.value = true
  channelTree().then(res => {
    if (res.code === 10000) {
      if (props.selProvince?.length > 0) {
        props.selProvince.forEach(item => {
          res.data.forEach(item2 => {
            item2.children.forEach (item3 => {
              if (item == item3.value) {
              item3.checked = true
              }
            })
          })
        })
      }
      ProvinceList.value = res.data
    }
  }).finally(() => {
    loading1.value = false
  })
})
</script>

<style lang="less" scoped>
.checkBox{
  max-height: 600px;
  overflow: auto;
}
.label{
  margin-top: 6px;
  margin-bottom: 3px;
}
</style>