<template>
  <div>
    <a-modal :visible="visible" :footer="null" width="50%" @cancel="cancel">
      <template #title>
        <div>
          {{ info.carrierName }}-任务详情
          <a class="m-l4" v-if="info.taskStatus?.value === 0" @click="revoke(0)">撤销</a>
          <a class="m-l4" v-if="info.taskStatus?.value === 1" @click="revoke(1)">要回并取消</a>
        </div>
      </template>
      <div>
        <span class="info">派单信息</span>
        <div class="itemBox m-t1">
          <div class="title">任务单号</div><span class="one">{{ info.taskId }}</span><span class="two">共{{dataSource.length}}辆</span><span class="two" v-if="info.isPeerCollection?.value === 1">代收金额：{{ info.peerCollectionAmt}}<a @click="CollectionAdd" class="m-l1">代收码</a></span>
        </div>
        <div class="itemBox">
          <div class="title">派单人</div><span class="one">{{ info.arranger }}</span><span class="two">￥{{ info.totalAmt }} <span v-if="info.oilAmt">（含油卡￥{{info.oilAmt}}）</span></span><span>{{ info.scheduleTime }}</span>
        </div>
        <div class="itemBox">
          <div class="title">起点-终点</div><span>{{info.lineStart}}—{{ info.lineEnd }}</span>
        </div>
        <div class="itemBox">
          <div class="title">任务段</div><span>{{ info.taskName }}</span>
        </div>
        <div class="itemBox">
          <div class="title">任务状态</div><span class="one">{{ info.taskStatus?.label }}</span><span class="two">最晚接单时间：{{ info.comReceivingTime }}</span><span class="two">接单时间：{{info.receivingTime}} </span><span>接单时效：{{ info.receivingPrescription }}</span>
        </div>
        <div class="itemBox">
          <div class="title">超时信息</div><span class="one">{{ info.timeOut }}</span><span class="two">规定完成时间：{{ info.comFinishTime }}</span><span class="two">实际完成时间： {{ info.finishTime }} </span><span>完成时效：{{ info.finishPrescription }}</span>
        </div>
        <div class="itemBox">
          <div class="title">结算信息</div><span>{{info.settlementStatus?.label}}</span>
        </div>
        <div class="itemBox">
          <div class="title">派单备注</div><span style="color:red">{{ info.remark }}</span>
        </div>
      </div>
      <div class="m-t1">
        <span>派单车辆</span>
        <SearchList style="margin-top:-10px;margin-left:-10px" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
        <a-table size="small" :columns="columns" bordered :row-key="record => record.id" :data-source="dataSource" :pagination="false">
          <template #vinNo="{ record }">
            <div> {{ record.vinNo }} </div>
            {{ record.brand }} {{record.model}}
          </template>
          <template #image="{ record }">
            <span v-if="record.pic?.value === 0">{{ record.pic?.label}}</span>
            <a v-else @click="lookVehicleImg(record)">{{record.picNum}}张</a>
          </template>
          <template #deliveryCard="{ record }">
            <span v-if="record.deliveryCard?.value === 0">{{ record.deliveryCard?.label}}</span>
            <a v-else @click="lookDeliveryCard(record)">查看</a>
          </template>
          <template #address="{ record }">
            <span v-if="record.address?.value === 0">{{ record.address?.label }}</span>
            <a v-else @click="lookVehicleGps(record)">查看</a>
          </template>
        </a-table>
      </div>
      <!-- 在途位置 -->
      <VehicleGps ref="vehicleGps" />
      <!-- 交车条 -->
      <PictureViewer v-model:visible="visibleLookDeliver" :lookImgID="lookImgID"></PictureViewer>
    </a-modal>
    <!-- 车辆图片 -->
    <VehicleImg ref="vehicleImgRef" />

    <!-- 撤销 -->
    <a-modal v-model:visible="RevVisible" title="备注" @ok="RevOk">
      <a-textarea v-model:value="RevRemark" placeholder="请输入" />
    </a-modal>
    <!-- 修改价格 -->
    <a-modal v-model:visible="priceVisible" title="修改价格" @ok="priceOk">
      <div v-for="item in changePriceData" :key="item.id" class="flex al-center">
        <div class="itenVinNo flex al-center"> {{ item.vinNo }}</div>
        <div class="m-l1 m-r1">
          <a-input-number placeholder="费用金额" v-model:value="item.price"></a-input-number>
        </div>
        <a-select style="width:140px" v-model:value="item.reason" placeholder="请选择">
          <a-select-option :value="1">Jack</a-select-option>
        </a-select>
      </div>
    </a-modal>
    <!-- 付款码 -->
    <PayModal ref="payQRCref" />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList';
import VehicleImg from '@/views/components/vehicleImg'
import { cloneDeep } from 'lodash-es';
import { vehicleTaskCancel, vehicleTaskBackToCancel, taskDeliveryPic, vehicleTaskInfo } from '@/api/transport/transport'
import { message } from 'ant-design-vue';
import PayModal from './components/payModal'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import PictureViewer from '@/components/PictureViewer'

const emit = defineEmits(['update:visible', 'statusAdd'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  lookID: {
    type: String,
    default: ''
  },

})
const visibleLookDeliver = ref(false)
const lookImgID = ref([])
const vehicleGps = ref(null)
const dataSource = ref([])
const info = ref({})
const vehicleImgRef = ref(null)
const RevVisible = ref(false)
const RevRemark = ref('')
const addInfo = ref({})
const priceVisible = ref(false)
const changePriceData = ref([])
const payQRCref = ref(null)
const taskStatus = ref(null)

// 查看交车条
const lookDeliveryCard = (record) => {
  taskDeliveryPic(record.orderVehicleId, {
    taskId: record.taskId
  }).then(res => {
    if (res.code !== 10000) return
    lookImgID.value = res.data.map(item => item.fileId)
    visibleLookDeliver.value = true
  })
}

// 代收码
const CollectionAdd = () => {
  let obj = {
    startAddress: info.value.lineStart,
    endAddress: info.value.lineEnd,
    taskId: info.value.taskId,
    orderId: info.value.orderId,
    payType: 1
  }
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}
//修改价格
const changePrice = () => {
  priceVisible.value = true
}
const priceOk = () => {
  console.log(changePriceData.value);
}
//  撤销
const revoke = (type) => {
  taskStatus.value = type
  addInfo.value = info.value
  RevVisible.value = true
}
const RevOk = () => {
  let req = null
  if(taskStatus.value === 0){
    req = vehicleTaskCancel
  }else if(taskStatus.value === 1){
    req = vehicleTaskBackToCancel
  }
  req({
    canceler: addInfo.value.arranger,
    remark: RevRemark.value,
    taskId: addInfo.value.taskId
  }).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    RevVisible.value = false
    cancel()
    emit('statusAdd')
  })
}

// 重置
const resetBtn = () => {
  dataSource.value = info.value?.trans
}
//搜索
const searchAdd = (e) => {
  if (e.vinNo && e.status) {
    dataSource.value = info.value?.trans.filter(item => item.vinNo.includes(e.vinNo))
    dataSource.value = dataSource.value.filter(item => item.transStatus.label === e.status)
    return
  }
  if (e.vinNo) {
    dataSource.value = info.value?.trans.filter(item => item.vinNo.includes(e.vinNo))
  }

  if (e.status) {
    console.log(info.value?.trans.filter(item => item.transStatus.label === e.status));
    dataSource.value = info.value?.trans.filter(item => item.transStatus.label === e.status)
  }
}
const lookVehicleImg = (record) => {
  setTimeout(() => {
    vehicleImgRef.value.vehicleImg(record.orderVehicleId, record.vinNo)
  }, 50)
}
const lookVehicleGps = (record) => {
  setTimeout(() => {
    vehicleGps.value.onvisible(true)
    vehicleGps.value.onorderVehicleId(record.orderVehicleId)
  }, 50)
}
const columns = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    align: 'center',
    slots: { customRender: 'vinNo' },
  },
  {
    title: '运费',
    dataIndex: 'totalAmt',
    align: 'center',
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center',
  },
  {
    title: '车辆状态',
    dataIndex: 'transStatus.label',
    align: 'center',
  },
  {
    title: '开始时间',
    dataIndex: 'taskStartTime',
    align: 'center',
  },
  {
    title: '结束时间',
    dataIndex: 'taskEndTime',
    align: 'center',
  },
  {
    title: '完成时效',
    dataIndex: 'finishPrescription',
    align: 'center',
  },
  {
    title: '照片',
    dataIndex: 'pic.label',
    slots: {
      customRender: 'image'
    },
    align: 'center',
  },
  {
    title: '位置',
    dataIndex: 'address',
    slots: { customRender: 'address' },
    align: 'center',
  },
  {
    title: '交车条',
    dataIndex: 'deliveryCard',
    slots: { customRender: 'deliveryCard' },
    align: 'center',
  },
])
const iptData = ref([
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "车辆状态",
    value: null,
    prop: "status",
    width: 150,
    opt: [
      {
        name: '待安排',
        id: '待安排'
      },
      {
        name: '已发运',
        id: '已发运'
      },
      {
        name: '运输中',
        id: '运输中'
      },
      {
        name: '中转代派',
        id: '中转待派'
      },
      {
        name: '已完成',
        id: '已完成'
      },
    ]
  },
])
onMounted(() => {
  vehicleTaskInfo(props.lookID).then(res => {
    if (res.code !== 10000) return
    info.value = res.data
    dataSource.value = cloneDeep(info.value?.trans)
    dataSource.value.forEach(item => {
      changePriceData.value.push({
        price: '',
        vinNo: item.vinNo,
        reason: null
      })
    })
  })



})
const cancel = () => {
  emit('update:visible', false)
}

</script>

<style lang="less" scoped>
.itemBox {
  padding: 6px 10px;
  background: rgba(242, 242, 242, 0.34);
  border: 1px solid rgb(238, 238, 238);
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #191919;
  margin-bottom: 8px;

  .title {
    color: #555555;
    width: 80px;
  }
}

.info {
  color: #191919;
  font-size: 14px;
  font-weight: 700;
}

.one {
  width: 200px;
}
.two {
  width: 230px;
}
.content-data {
  padding: 5px;
  padding-bottom: 0;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  flex-wrap: nowrap;
  background: rgba(242, 242, 242, 0.3);

  .plant-data {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .city-data {
      flex-wrap: nowrap;
      white-space: nowrap;
      margin: 0 5px;
    }

    .detail-data-plant {
      min-width: 200px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
}

.itenVinNo {
  width: 150px;
  height: 30px;
  border: 1px solid #d9d9d9;
  background: #f0f0f0;
  padding: 0 10px;
}
:deep(.ant-input-number) {
  width: 120px;
}
</style>