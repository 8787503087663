<template>
  <div>
    <!-- 小车操作详情 -->
    <WindowModal :windowShow="windowShow" :data="detail" @statusShow="model" :clearData="clearData">
      <template #titleContent>
        <div style="display:flex;justify-content: space-between;padding-right:40px">
          <div>
            <span>{{ detail.vinNo + ' ' + detail.brand + detail.model }}</span>
            <a style="margin-left:10px;font-size:14px" @click="showRecord">车辆日志</a>
            <a style="margin-left:10px;font-size:14px" @click="getUpdateImg">上传图片</a>
            <a style="margin-left:10px;font-size:14px" @click="lookVehicleImg">查看图片</a>
            <a style="margin-left:10px;font-size:14px" @click="orderVehicleGps">在途位置</a>
            <a style="margin-left:10px;font-size:14px" @click="openDetailed(detail)">异常列表</a>
            <a style="margin-left:10px;font-size:14px" v-show="dispatchDetail.isPlaceIn && dispatchDetail.isPlaceIn.value === 1" @click="pickVisible = true">取车委托</a>
            <a style="margin-left:10px;font-size:14px" @click="transVisible = true">运车委托</a>
            <a style="margin-left:10px;font-size:14px" v-show="dispatchDetail.isTakeOut && dispatchDetail.isTakeOut.value === 1" @click="takeVisible = true">送车委托</a>
            <a style="margin-left:10px;font-size:14px" @click="lookFollowList">跟单列表</a>
            <a style="margin-left:10px;font-size:14px" @click="handleCope">验车码</a>
            <a style="margin-left:10px;font-size:14px" v-if="dispatchDetail.isDelivery?.value === 1" @click="lookDelivety">查看交车条</a>
          </div>
          <div>
            <span>业务员：{{ detail.salesman }}</span>
            <span style="margin-left:10px">下单时间：{{ dispatchDetail.orderTime }}</span>
          </div>
        </div>
      </template>
      <template #leftContent>
        <div class="window-content">
          <TransportModel ref="transportModelRef" @focusCancel="focusCancel" :windowShow="windowShow" :data="detail" @statusShow="transportModelShowStatus" :tovId="tovId" />
          <!-- 信息日志 -->
          <a-modal v-model:visible="journalShow" width="40%" title="车辆日志" footer="" :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
            <div v-if="detail.orderVehicleId">
              <Record v-if="detail.orderVehicleId" ref="recordRef" :vehicleList="[]" :vehiclesId="detail.orderVehicleId" :orderId="detail.orderId" />
            </div>
          </a-modal>
          <!-- 跟单列表 -->
          <a-modal v-model:visible="followListShow" title="跟单列表" width="60%" footer="" :getContainer="false">
            <div>
              <ResultFollow ref="resultFollowRef" />
            </div>
          </a-modal>
          <!-- 上传图片 -->
          <InspectionImg ref="imgListRef" :orderVehicleId="detail.orderVehicleId" :loadInspectList="[]" />
          <!-- 车辆图片 -->
          <VehicleImg ref="vehicleImgRef" />
          <!-- 批量交车 -->
          <!-- 在途位置 -->
          <VehicleGps ref="vehicleGps" />
          <a-modal v-model:visible="pickVisible" title="取车委托" width="600px" footer="">
            <div>
              <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
              <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
              <p class="font-color2">交车人姓名：{{ dispatchDetail.consignor }}</p>
              <p class="font-color2">交车人电话：{{ dispatchDetail.consignorMobile }}</p>
              <p class="font-color2">交车人身份证：{{ dispatchDetail.consignorCertificateNumber }}</p>
              <p class="font-color2" v-if="dispatchDetail.orderRemark">订单备注：{{ dispatchDetail.orderRemark }}</p>
              <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
              <p class="font-color2">取车信息：{{ dispatchDetail.pickType ? dispatchDetail.pickType.label : '' }}&nbsp;{{
                dispatchDetail.placeInAddress }}</p>
              <p class="font-color2">温馨提示：物流只负责外观内饰的检查，车辆性能及其它内部问题我们没有技术能力检验，望理解！贵重物品不随车，小件物品请打包放好，有ETC请提前拔掉，谢谢配合！</p>
            </div>
          </a-modal>
          <a-modal v-model:visible="transVisible" title="运车委托" width="600px" footer="">
            <div>
              <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
              <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
              <p class="font-color2">起运地：{{dispatchDetail.startAddress}}</p>
              <p class="font-color2">目的地：{{dispatchDetail.endAddress}} {{ dispatchDetail.picker }} {{ dispatchDetail.pickerMobile }}</p>
              <p class="font-color2"><span v-show="dispatchDetail.paymentArrival > 0">
                  订单到付: <a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag>
                  <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span></span></p>
              <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>
            </div>
          </a-modal>
          <a-modal v-model:visible="takeVisible" title="送车委托" width="600px" footer="">
            <div>
              <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
              <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
              <p class="font-color2">收车人姓名：{{ dispatchDetail.picker }}</p>
              <p class="font-color2" v-if="dispatchDetail.pushTime">收车人电话：{{ dispatchDetail.pickerMobile }}</p>
              <p class="font-color2">收车人身份证：{{ dispatchDetail.pickerCertificateNumber }}</p>
              <p class="font-color2"><span v-show="dispatchDetail.paymentArrival > 0">
                  订单到付：<a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag></span>
                <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span>
              </p>
              <p class="font-color2" v-if="dispatchDetail.orderRemark ">订单备注：{{ dispatchDetail.orderRemark }}</p>
              <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
              <p class="font-color2">送车信息：{{ dispatchDetail.takeType ? dispatchDetail.takeType.label : '' }}
                <span v-if="dispatchDetail.takeOutAddress">&nbsp;{{ dispatchDetail.takeOutAddress }}</span>
              </p>
              <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>
            </div>
          </a-modal>
        </div>
      </template>
      <template #rightContent>
        <a-tabs v-model:activeKey="activeKey" @change="tabsChange">
          <template #tabBarExtraContent>
            <a-button v-if="!dispatchDetail.taskId" type="primary" size="small" @click="sendOrder" class="m-r2">外派</a-button>
          </template>
          <a-tab-pane :key="1" tab="发运模式">
            <OperateModel @departureSuccessful="departureSuccessful" ref="operateModelRef" :transList="dispatchDetail.details" :detail="detail" :dispatchDetail="dispatchDetail" @loadData="modelLoadData" />
          </a-tab-pane>
        </a-tabs>
      </template>
    </WindowModal>
    <a-modal v-model:visible="abnormalReportShow" width="50%" footer="" @cancel="abnormalReportRef.clearData()">
      <template #title>
        <div>
          {{ `${detail.vinNo || '暂无'}-异常列表` }}
        </div>
      </template>
      <div>
        <AbnormalReport ref="abnormalReportRef" :vinNo="detail.vinNo" :vehicleDetail="detail" @statusShow="abnormal" />
      </div>
      <!-- 派车图片查看 -->
    </a-modal>
    <!-- 派单 -->
    <a-modal v-model:visible="sendVisible" title="外派" footer="" width="40%" destroyOnClose :dialogStyle="{ left: '400px',top:'20px' }"> 
      <submitInfo ref="infoRef" @status="status" :title="false"></submitInfo>
    </a-modal>
    <!-- 交车信息 -->
    <a-modal v-model:visible="deliveryVisible" title="交车信息查看" width="40%" footer="" >
      <div class="delivery-content">
        <div v-show="!deliveryLoading && deliveryData.orderVehicleId">
          <a-form-item label="到收款金额" v-if="deliveryData.paidAmt > 0">
            <span style="color:red"> {{ deliveryData.paidAmt }}</span>
          </a-form-item>
          <span>收车条：</span>
          <a-image :width="100" style="height:100px" :src="deliveryData.fileUrl" :preview="deliveryData.preview" @click="handlePreview(deliveryData)" />
          <a-row :gutter="[24, 12]">
            <a-col :span="10">
              <span>收款对象：</span>
              {{ deliveryData.deliveryType && deliveryData.deliveryType.label }}
            </a-col>
            <a-col :span="7">
              {{ deliveryData.officeOrgName }}
            </a-col>
            <a-col :span="7" v-if="deliveryData.deliveryOfficeOrg != null">
              {{ deliveryData.deliveryOfficeOrg.label }}
            </a-col>
            <a-col :span="24">
              <span>交车人：</span>
              {{ deliveryData.creatorBy }}
            </a-col>
            <a-col :span="24">
              <span>交车时间：</span>
              {{ deliveryData.createTime }}
            </a-col>
            <a-col :span="24">
              <span>交车说明：</span>
              {{ deliveryData.remark }}
            </a-col>
          </a-row>
        </div>
        <h2 v-show="!deliveryLoading && !deliveryData.orderVehicleId" style="text-align: center">暂无交车条</h2>
        <div v-show="deliveryLoading" style="display:flex;justify-content: center;align-items:center">
          <a-spin size="large" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, nextTick} from 'vue'
import WindowModal from '@/views/components/windowModal/index.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'
import OperateModel from '@/views/components/transport/smallVehicleOperate/operateModel'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import VehicleImg from '@/views/components/vehicleImg'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import AbnormalReport from '@/views/components/transport/comon/abnormalReport'
import ResultFollow from '@/views/components/followOrder'
import submitInfo from '@/views/components/transport/taskAssignment/components/submitInfo.vue'
import { vehicleDeliveryInfo } from '@/api/transport/truck'
const emit  =  defineEmits(['departureSuccessful','focusCancel'])

const windowShow = ref(false)
const detail = ref({})
const transportModelRef = ref(null)
const operateModelRef = ref(null)
const dispatchDetail = ref({})
const activeKey = ref(1)
const tovId = ref('')
const journalShow = ref(false)
const recordRef = ref(null)
const imgListRef = ref(null)
const vehicleImgRef = ref(null)
const vehicleGps = ref(null)
const abnormalReportShow = ref(false)
const abnormalReportRef = ref(null)
const pickVisible = ref(false)
const transVisible = ref(false)
const takeVisible = ref(false)
const followListShow = ref(false)
const resultFollowRef = ref(null)
const sendVisible = ref(false)
const infoRef = ref(null)
const deliveryLoading = ref(false)
const deliveryVisible = ref(false)
const deliveryData = ref({})

const model = () => {
  dispatchDetail.value = {}
}

const focusCancel = (orderVehicleId) => {
  emit('focusCancel',orderVehicleId)
}
const handlePreview = (item) => {
      item.preview = true
      item.fileUrl = item.fileRealUrl
    }
const lookDelivety = () => {
      deliveryLoading.value = true
      deliveryVisible.value = true
      vehicleDeliveryInfo(dispatchDetail.value.orderVehicleId).then(res => {
        if (res.code === 10000 && res.data) {
        deliveryData.value = res.data
        }
      }).finally(() => { deliveryLoading.value = false })
    }
const departureSuccessful = () => {
  emit('departureSuccessful','modelLoadData')
}
const handleCope = () => {
  operateModelRef.value.handleCope()
}
// 外派
const sendOrder = () => {
  sendVisible.value = true
  nextTick(() => {
    infoRef.value.reqVehicleTaskType([dispatchDetail.value.orderVehicleId])
  })
}
// 跟单列表
const lookFollowList = () => {
  followListShow.value = true
  setTimeout(() => {
    resultFollowRef.value.loadData(detail.value.orderVehicleId)
  }, 10)
}
//异常列表
const openDetailed = record => {
  detail.value = record
  abnormalReportShow.value = true
  setTimeout(() => {
    if (abnormalReportRef.value) abnormalReportRef.value.openWindow()
  }, 50)
}
// 异常列表回传
const abnormal = e => {
  abnormalReportShow.value = false
}
// 在途位置gps
const orderVehicleGps = () => {
  setTimeout(() => {
    vehicleGps.value.onvisible(true)
    vehicleGps.value.onorderVehicleId(detail.value.orderVehicleId)
  }, 50)
}
// 查看图片
const lookVehicleImg = () => {
  setTimeout(() => {
    vehicleImgRef.value.vehicleImg(detail.value.orderVehicleId, detail.value.vinNo)
  }, 50)
}
// 车辆日志
const showRecord = () => {
  journalShow.value = true
  setTimeout(() => {
    recordRef.value.loadData()
  }, 10)
}
// 车辆图片
const getUpdateImg = () => {
  setTimeout(() => {
    imgListRef.value.inspectionImg = true
    imgListRef.value.loadData(detail.value.orderVehicleId)
  }, 100)
}
// 更新数据
const modelLoadData = (e,time,detail) => {
  emit('modelLoadData',time,detail)
  if (e) {
    if (e === true) {
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 200)
    } else {
      tovId.value = e
    }
  }
}
const tabsChange = e => {
  setTimeout(() => {
    if (e === 1) operateModelRef.value.getData()
  }, 10)
}
const onOk = () => {
  transportModelRef.value.getTaskListData()
  operateModelRef.value.getData()
}
const clearData = () => {
  setTimeout(() => {
    transportModelRef.value.clearData()
  }, 10)
  windowShow.value = false
}
const transportModelShowStatus = e => {
  
  if (e.orderId) dispatchDetail.value = e
  if (e === true) operateModelRef.value.operateData()
  if (typeof e == 'number') {
    activeKey.value = e
    setTimeout(() => {
      tabsChange(e)
    }, 1)
  }
}
defineExpose({
  windowShow,
  detail,
  onOk
})
</script>

<style lang="less" scoped>
</style>