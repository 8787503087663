<template>
  <div>
    <a-modal :visible="visible" title="干线清零" centered footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          {{ item.name }}({{ item.total }})
        </div>
      </div>
      <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a >{{ data.length }}</a>&nbsp;条</div>
        <a-table ref="tableRef" size="small" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500,x:1800}">
          <template #sort="{ index }">
            {{index+1}}
          </template>
          <template #vinNo="{ record }">
            <div class="flex al-center ju-center">
              <a-popover>
                <template #content>
                  {{ record.taskCarrierName }}
                </template>
                <div class="isExpatriate cur-p" v-if="record.taskId">
                  派
                </div>
              </a-popover>
              <div style="word-break:break-all"> {{ record.vinNo }}</div>
              <a-typography-paragraph :copyable="{ text: record.vinNo }">
              </a-typography-paragraph>
            </div>
            <div> {{ record.brand }}{{ record.model }}</div>
          </template>
          <template #transportNo="{ record }">
            <a @click="openBigCar(record)">{{ record.transportNo }}</a>
          </template>
          <template #startAddress="{ record }">
            {{ record.startAddress}}
            <div>{{ record.endAddress }}</div>
          </template>
          <template #picker="{ record }">
            <div>{{ record.picker }}</div>
            {{ record.pickerMobile }}
          </template>
          <template #takeOutAddress="{ record }">
            <div>{{ record.endAddress }}</div>
            <div>{{ record.takeOutAddress }}</div>
          </template>
          <template #consignor="{ record }">
            <div>{{ record.consignor }}</div>
            {{ record.consignorMobile }}
          </template>
          <template #departure="{ record }">
            <div>{{ record.departure }}</div>
            {{ record.departureTime }}
          </template>
          <template #arranger="{ record }">
            <div>{{ record.arranger }}</div>
            {{ record.scheduleTime }}
          </template>
          <template #settlementStatus="{ record }">
            <div>{{ record.orderSettlement?.label }}</div>
            <span :style="{color:colorSettlementStatus[record.settlementStatus?.value]}">{{ record.settlementStatus?.label }} <a v-if="record.settlementStatus?.value !== 2" @click="openPayQRC(record)">
                收款码
              </a></span>
          </template>
          <template #dispatchRemark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.dispatchId">
              <div class="remark-content">
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.dispatchRemark }}</span>
                  </template>
                  <span>{{ record.dispatchRemark }}</span>
                </a-tooltip>
                <a v-show="!record.dispatchRemark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.dispatchRemark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
          
          <template #add="{ record }">
            <a @click="model(record)">操作</a>
          </template>
        </a-table>
      </div>
      <!-- 新增/编辑调度备注 -->
      <SchedulingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId" />
      <!-- 小车详情 -->
      <SmallVehDetails ref="smallVehDetailsRef" @departureSuccessful="departureSuccessful"></SmallVehDetails>
      <!-- 支付二维码 -->
      <PayQRC ref="payQRCref" />
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import  { transportZero as timeoutVehicleAnl } from '@/api/newTransport/newTransport'
import { useStore } from 'vuex'
import SmallVehDetails from '@/components/smallVehDetails'
import SchedulingLogs from '@/views/components/transport/smallVehicleOperate/schedulingLogs.vue'
import { addressTran, transitionTimeYMD, IfNull } from '@/utils/util'
import PayQRC from '@/views/components/payQRC'
import { cloneDeep } from 'lodash-es'
const store = useStore()
const emit = defineEmits(['update:visible']) 
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  info: {
    type: Object,
    default: {}
  },
  tableIdx: {
    type: Number,
    default: null
  },
  vehicleNumType: {
    type: Number,
    default: 0
  }
})
const cancel = () => {
  emit('update:visible', false)
}

const payQRCref = ref(null)
const tableRef = ref(null)
const indexKey = ref(0)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)
const remarkShow = ref(false)
const logsVehicleId = ref('')
const isOverTime = reactive({
  isOverTime: '',
  transportType: '',
  taskType: '',
})
const remarkId = ref('')
const smallVehDetailsRef = ref(null)
const searchMsg = ref({
})
const colorSettlementStatus = {
  0: '#D20509',
  2: '#15AD31'
}


//收款码
const openPayQRC = (record) => {
  let obj = {
    startAddress: record.startAddress,
    endAddress: record.endAddress,
    orderId: record.orderId,
    ovId: record.orderVehicleId,
    vehicleList: [{ vinNo: record.vinNo }]
  }
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}
const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.nowCityId = msg.nowCityId ? msg.nowCityId.pop() : null
  msg.preDepartureTime = transitionTimeYMD(msg.preDepartureTime)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  searchMsg.value = msg
  reqWaitPlan()
}
const resetBtn = () => {
  searchMsg.value = {
  }
  reqWaitPlan()
}
const model = e => {
  smallVehDetailsRef.value.detail = e
  smallVehDetailsRef.value.windowShow = true
  setTimeout(() => {
    smallVehDetailsRef.value.onOk()
  }, 100)
}
const openDisRemarks = (record) => {
  isOverTime.isOverTime = record.isOverTime?.value
  isOverTime.transportType = record.transportType?.value
  isOverTime.taskType = record.taskType
  remarkShow.value = true
  logsVehicleId.value = record.orderVehicleId
}
const departureSuccessful = () => {
  reqWaitPlan()
}
const changeBtn = (e) => {
  ResetScroll()
  indexKey.value = e.colorType
  reqWaitPlan()
}

const reqWaitPlan = () => {
  loading.value = true
  timeoutVehicleAnl({
    ...searchMsg.value,
    auditType: indexKey.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const ResetScroll = () => {
  pageSize.value = 50
  dataSource.value = []
}
const setRemaekOk = (str, label, playTime) => {
  if (IfNull(str)) {
    dataSource.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.dispatchRemark = str;
      }
    })
  }
  if (label) {
    dataSource.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.labelName = label;
      }
    })
  }
  
}
onMounted(() => {
  if (props.info) {
    tabstatus.value[0].total = props.info.num
    if (props.info.subItemList) {
      props.info.subItemList.forEach((opt, index) => {
        let msg = {
          name: opt.title,
          total: opt.num,
          colorType: opt.colorType
        }
        tabstatus.value.push(msg)
      })
    }
  }
  indexKey.value = props.tableIdx
  reqWaitPlan()
  nextTick(() => {
    const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', (event) => {
      if (dataSource.value.length === 0) return
      if (pageSize.value > data.value.length) return
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 滚动条触底，执行加载数据的操作
        pageSize.value += 50
        dataSource.value = data.value.slice(0, pageSize.value)
        // 这里可以调用你的加载数据函数
      }
    });
  })
})

const tabstatus = ref([
  {
    name: '全部',
    id: 0,
    colorType: null,
    total: 0
  },

])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
    opt: store.state.app.labelList
  },
  {
    type: "address",
    placeholder: "当前城市",
    value: null,
    prop: "nowCityId",
    width: 200,
    changeOn: true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "预计发车时间",
    value: null,
    prop: "preDepartureTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "是否中途卸车",
    value: null,
    prop: "isPit",
    width: 200,
    opt:[
      {
      id: 1,
      name: '是'
      },
      {
      id: 0,
      name: '否'
      },
    ]
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    width: 70,
    align: 'center',
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
 
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '上段完成时间',
    dataIndex: 'lastUnloadTime',
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'nowCity',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'settlementStatus',
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    },
  },
  
  {
    title: '预计发车时间',
    dataIndex: 'preDepartureTime',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>