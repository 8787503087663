<template>
  <div>
    <div class=" itemBox">
      <div class="flex al-center">
        <div style="width: 85%" class="flex al-center">
          {{ item.title }}
          <a-tooltip  overlayClassName="memo-tooltip"  class="m-l1">
            <template #title>
              <div style="white-space:pre-wrap;">
                <div v-html="item.define"></div>
              </div>
            </template>
            <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
          </a-tooltip>
        </div>
        <div>
          <span class="cur-p" @click="open(null)">
            <CountTo color="#0066CC" fontSize="18px" :endVal="item.num" />
          </span>
        </div>
      </div>
      <div class="tips">
        <span> {{ item.subTitle }}</span>
      </div>
      <div style="color: #344563;font-size: 13px;">
        <span v-for="(opt,index) in item.subItemList" :key="opt.id" style="margin-right: 10px">
          {{ opt.title }}:
          <span>
            <span @click="open(opt)" class="cur-p" :style="{color:colorType[opt.colorType]}">{{ opt.num }}</span>
          </span>
          {{ opt.unit }}
        </span>
      </div>

      <div v-if="visible">
        <component :is="componentType" :vehicleNumType="vehicleNumType" :tableIdx="tableIdx" v-model:visible="visible" :info="item"></component>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref } from 'vue'
import OnTheway from './components/OnTheway.vue';
import CheckCarPhoto from './components/CheckCarPhoto.vue';
import DepartureCalculation from './components/DepartureCalculation.vue';
import DeliveryNote from './components/DeliveryNote.vue';
import TimeoutAnalysis from './components/TimeoutAnalysis.vue';
import Follow from './components/Follow.vue';
import MainCleaning from './components/MainCleaning.vue';
import CountTo from '@/components/countTo'
import { colorType } from '@/utils/enum.js'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  vehicleNumType:{
    type: Number,
    default: 0
  }
})
const componentType = ref(null)
const visible = ref(false)
const tableIdx = ref(null)
const open = (opt) => {
  switch (props.item.pageType) {
    case 1:
      componentType.value = OnTheway
      break;
    case 2:
      componentType.value = CheckCarPhoto
      break;
    case 3:
      componentType.value = DepartureCalculation
      break;
    case 4:
      componentType.value = DeliveryNote
      break;
      case 5:
      componentType.value = TimeoutAnalysis
      break;
      case 6:
      componentType.value = Follow
      break;
      case 50:
      componentType.value = MainCleaning
      break;
  }
  tableIdx.value = opt ? opt.colorType : null
  visible.value = true

}
</script>

<style lang="less" scoped>
.memo-tooltip {
      :global(.ant-tooltip) {
        max-width: 350px !important;
      }
    }
.itemBox {
  margin-top: 10px;
  padding: 10px;
  background: #edf0f9;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .tips {
    font-size: 12px;
    color: #999;
  }
}

.tipsIcon {
  width: 14px;
  height: 14px;
}

</style>