<template>
  <div class="tableBox">
    <div v-if="titleText" class="title">
      {{ titleText }}
    </div>
    <a-table size="small" :loading="loading" :columns="columns" bordered :row-key="(record,index) => index" :data-source="dataSource" :pagination="false" :scroll="{y:scrollNum}">
      <template #monthTargetAmt="{ record }">
        <span v-if="record.monthTargetAmt && record.monthTargetAmt!=0">{{ record.monthTargetAmt }}</span>
        <span v-else style="color: #777777">未设指标</span>
      </template>
      <template #completeRate="{ record }">
        {{ record.completeRate }} <span v-show="record.completeRate">%</span>
      </template>
      <template #add="{ record }">
        <a v-if="record.orgName !== '合计' && record.salesman !== '合计'" @click="lookAdd(record)">查看</a>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  dataSource: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: () => []
  },
  loading:{
    type: Boolean,
    default: false
  },
  titleText:{
    type: String,
    default: '指标明细统计'
  },
  scrollNum:{
    type: Number,
    default: 500
  }
})
const emit = defineEmits(['lookAdd'])
const lookAdd = (record) => {
  emit('lookAdd', record)
}
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
  padding-top:0;
}
.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom:10px ;
  padding-top:20px;
}
</style>