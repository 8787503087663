<template>
  <div>
    <div v-if="styleType == 1">
      <StyleOne :item="msg"  :channelSubId="channelSubId" :title="title" :idx="idx" />
    </div>
    <div v-if="styleType == 0">
      <StyleOne :item="msg" :channelSubId="channelSubId" :title="title" :idx="idx" />
    </div>
    <div v-if="styleType == 2">
      <StyleTwo :item="msg"  />
    </div>
    <div v-if="styleType == 7">
      <FunctionalArea @submit="submit"  :item="msg"  />
    </div>
  </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import StyleOne from './styleOne'
import StyleTwo from './styleTwo'
import FunctionalArea from './FunctionalArea'
const emit = defineEmits(['submit'])
const submit = () => {
  emit('submit')
}
const props = defineProps({
  styleType: {
    type: [String, Number],
    default: null
  },
  msg: {
    type: Object,
    default: {}
  },
  title:{
    type: String,
    default: ''
  },
  channelSubId:{
    type: String,
    default: ''
  },
  idx:{
    type: Number,
    default: null
  }
})

  </script>
  
  <style lang="less" scoped>
</style>