import './index.less'
import { SecurityScanFilled } from '@ant-design/icons-vue'

const locales = ['HY', 'HK', 'ALTV', 'HG','HN','DC','DF','AJ']
const languageLabels = {
  HY: '发运模块 H+Y',
  HK: '库存模块 H+K',
  ALTV: '快速查车 ALT+V',
  HG: '车辆定位 H+G',
  HN:'知识库 H+N', 
  DC: '调度中控台 D+C',
  DF: '指标看板 D+F',
  AJ:'营销中控台 AJ'
}

const languageIcons = {
  'zh-CN': '🇨🇳',
  'zh-TW': '🇭🇰',
  'en-US': '🇺🇸',
  'pt-BR': '🇧🇷'
}

const SelectLang = {
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-drop-down'
    }
  },
  name: 'SelectLang',

  render () {
    const { prefixCls } = this
    // const changeLang = ({ key }) => {
    //   this.$store.dispatch('setLang', key)
    // }
    const langMenu = (
      <a-menu
        class={['menu', 'ant-pro-header-menu']}
        selectedKeys={[this.$store.state.app.lang]}
        // onClick={changeLang}
      >
        {locales.map(locale => (
          <a-menu-item key={locale}>
            <span role="img" aria-label={languageLabels[locale]}>
              {languageIcons[locale]}
            </span>
            {'   ' + languageLabels[locale]}
          </a-menu-item>
        ))}
      </a-menu>
    )
    return (
      <a-dropdown overlay={langMenu} placement="bottomRight">
        <span class={prefixCls}>
          <SecurityScanFilled />
        </span>
      </a-dropdown>
    )
  }
}

export default SelectLang
