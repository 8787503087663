<template>
  <div>
    <div>
      <div v-for="(opt,index) in item.subItemList" :key="opt.id" class="iBox">
        <div class="flex ju-between al-center">
          {{ opt.title }}:
            <span @click="open(opt)" class="cur-p"> <CountTo color="#0066CC" fontSize="18px" :endVal="opt.num" /></span>
        </div>
        <div class="subTitle">
          {{ opt.subTitle }}
        </div>
      </div>
    </div>
    <Table v-if="visible" v-model:visible="visible" :title="title" :dataLength="data.length" @searchAdd="searchAdd" @resetBtn="resetBtn" 
     @theBottom="theBottom"  :loading="loading" @setRemaekOk="setRemaekOk"
    :iptData="iptData" :columns="columns" :data="dataSource"></Table>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import CountTo from '@/components/countTo'
import {  carryiptData0 , carrycolumns0} from './styleTwo'
import { clueList } from '@/api/marketingConsole/marketingConsole'
import Table from '../table'
import { transitionTimeYMD } from '@/utils/util'
import { cloneDeep } from 'lodash-es'
import { useStore } from 'vuex'
const store = useStore()

const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
})
const visible = ref(false)
const title = ref('')
const iptData = ref([])
const data= ref([])
const columns = ref([])
const loading = ref(false)
const dataSource = ref([])
const pageSize = ref(50)
const searchMsg = ref({})


const setRemaekOk = (e) => {
  dataSource.value.forEach((item,index) => {
    if(item.customerId === e){
      dataSource.value.splice(index,1)
    }
  })
}
const searchAdd = (e) => {
  ResetScroll()
  let msg = cloneDeep(e)
  msg.channelSubId =   msg.channelSubId ? msg.channelSubId[1]  : null
  msg.clueCreateTimeStart = transitionTimeYMD(msg.clueCreateTimeStart)
  msg.clueCreateTimeEnd = transitionTimeYMD(msg.clueCreateTimeEnd)
  searchMsg.value = msg
  reqChannelList()
}
const resetBtn = () => {
  ResetScroll()
  searchMsg.value = {}
  reqChannelList()
}
const ResetScroll = () => {
  pageSize.value = 50
  dataSource.value = []
}
const open = (opt) => {
  title.value = opt.title

  iptData.value = carryiptData0.value
  iptData.value.forEach(item => {
    if(item.prop === 'channelSubId'){
      item.opt = store.state.enumAll.ChannelTypeEnum
    }
  })
  columns.value = carrycolumns0.value
  visible.value = true
 
  reqChannelList()
}

const reqChannelList = () => {
  loading.value = true
  clueList({
    isOverTime:1,
    ...searchMsg.value
  }).then(res => {
    if(res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
</script>

<style lang="less" scoped>
.iBox {
  width: 100%;
  margin-top:  10px;
  padding: 10px;
  background: #edf0f9;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.subTitle{
  font-size: 13px;
  color: #999;
}
.remark{
  max-width: 120px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>