<template>
<div>
   <div v-if="styleType == 1"> 
    <KeyIssues :item="msg" :vehicleNumType="vehicleNumType" />
   </div>
   <div v-if="styleType == 2">
    <Custom :item="msg" :subItemList="subItemList" :vehicleNumType="vehicleNumType" /> 
   </div>
   <div v-if="styleType == 12"> 
    <AbnormalSituation :item="msg" :vehicleNumType="vehicleNumType" />
   </div>
   <div v-if="styleType == 3">
    <TestTask :item="msg" :vehicleNumType="vehicleNumType" />
   </div>
   <div v-if="styleType == 4 || styleType == 5 || styleType == 6">
    <PickUpCar :item="msg" :vehicleNumType="vehicleNumType" />
   </div>
 
   <div v-if="styleType == 7">
    <FunctionalArea :item="msg" :vehicleNumType="vehicleNumType" />
   </div>
   <div v-if="styleType == 8">
    <ComingSoon  :item="msg" :subItemList="subItemList" :vehicleNumType="vehicleNumType" />
   </div>
   <div v-if="styleType == 10">
    <Gps :subItemList="subItemList" :vehicleNumType="vehicleNumType" />
   </div>
   <div v-if="styleType == 11">
    <OuterTask :item="msg" :vehicleNumType="vehicleNumType" />
   </div>
   <div v-if="styleType == 13"> 
    <KeyIssues :item="msg" :vehicleNumType="vehicleNumType" />
   </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
import KeyIssues from './KeyIssues'
import AbnormalSituation from './AbnormalSituation'
import TestTask from './TestTask'
import PickUpCar from './PickUpCar'
import Custom from './Custom'
import FunctionalArea from './FunctionalArea'
import ComingSoon from './ComingSoon'
import Gps from './Gps'
import OuterTask from './OuterTask'

const props = defineProps({
  styleType: {
    type:[ String ,Number],
    default: null
  },
  msg:{
    type:Object,
    default:{}
  },
  subItemList:{
    type:Array,
    default:[]
  },
  vehicleNumType:{
    type:Number,
    default:0
  }
})

</script>

<style lang="less" scoped>

</style>