<template>
  <div class="flex ju-between wrap">
    <div v-for="opt in item.subItemList" :key="opt.id" class="optBox">
      <div class="flex-d ju-center itemBox al-center m-t1 m-b1 cur-p" @click="openModal(opt)">
        <img :src="require(`@/assets/images/mkConsole/fun${opt.pageType}.png`)" alt="">
        <div class="m-t1"> {{ opt.title }}</div>
      </div>
    </div>

    <a-modal title="线索查询" v-model:visible="clueInquiryVisible" :destroyOnClose="true" width="75%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <ClueInquiry />
    </a-modal>
    <a-modal title="消费记录" centered v-model:visible="rocVisible" :destroyOnClose="true" width="68%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <RecordsOfconsumption />
    </a-modal>
    <a-modal title="热门推荐" centered v-model:visible="popularVisible" :destroyOnClose="true" width="40%" footer="" :bodyStyle="{padding: '0px',background:'#EEEEEE'}">
      <Popular />
    </a-modal>
    <a-modal title="转化统计" centered v-model:visible="cssVisible" :destroyOnClose="true" width="68%" footer="" :bodyStyle="{padding: '0px',background:'#EEEEEE'}">
      <ConversionStatistics />
    </a-modal>
    <a-modal title="业务统计" centered v-model:visible="BissVisible" :destroyOnClose="true" width="68%" footer="" :bodyStyle="{padding: '0px',background:'#EEEEEE'}">
      <BusinessStatistics />
    </a-modal>
    <a-modal title="报价查询" v-model:visible="inquiryVisible" :destroyOnClose="true" width="40%" footer="" :bodyStyle="{padding: '0px',background:'#EEEEEE'}">
      <inquiry :endType="3" />
    </a-modal>
    <a-modal title="线路查询" v-model:visible="RiVisible" :destroyOnClose="true" width="35%" footer="" :bodyStyle="{padding: '0px',background:'#EEEEEE'}">
      <RouteInquiry />
    </a-modal>
    <a-modal title="指标统计" v-model:visible="mktVisible" :destroyOnClose="true" width="90%" footer="" :bodyStyle="{padding: '0px',background:'#EEEEEE'}">
      <MarketingIndicators />
    </a-modal>
    <!-- 新增线索 -->
    <AddNewClues @submit="submit" :tips="tips" v-if="searchAddShow" v-model:visible="searchAddShow"></AddNewClues>
  </div>
</template>

<script setup> 
import { ref } from 'vue'
import RecordsOfconsumption from './recordsOfconsumption'
import ClueInquiry from './clueInquiry'
import Popular from './popular'
import RouteInquiry from './routeInquiry'
import ConversionStatistics from './conversionStatistics'
import BusinessStatistics from './businessStatistics'
import AddNewClues from '@/views/components/addNewClues'
import MarketingIndicators from './marketingIndicators'
import inquiry from '@/layouts/components/newTransport/meun/FunctionalArea/components/quotationInquiry/inquiry'

const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const emit = defineEmits(['submit'])
const clueInquiryVisible = ref(false)
const rocVisible = ref(false)
const popularVisible = ref(false)
const cssVisible = ref(false)
const searchAddShow = ref(false)
const inquiryVisible = ref(false)
const RiVisible = ref(false)
const BissVisible = ref(false)
const mktVisible = ref(false)
const tips = ref(' 数据更新有延迟，请稍后查看')
const submit = () => {
  emit('submit')
}
const openModal = (item) => {
  switch (item.pageType) {
    case 71:
      clueInquiryVisible.value = true
      break;
    case 72:
      searchAddShow.value = true
      break;
    case 73:
      rocVisible.value = true
      break;
    case 74:
      inquiryVisible.value = true
      break;
    case 75:
      popularVisible.value = true
      break;
    case 76:
      cssVisible.value = true
      break;
    case 77:
      BissVisible.value = true
      break;
    case 78:
      RiVisible.value = true
      break;
    case 79:
      mktVisible.value = true
      break;
  }
}

</script>

<style lang="less" scoped>
.optBox {
  width: 33%;
  font-size: 14px;
  color: #332;
  img {
    width: 50px;
    height: 45px;
  }
}
</style>