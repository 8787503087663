<template>
  <div class="m-t1">
    <a-input v-model:value="positionCarNo"  placeholder="大车车牌"></a-input>
    <Color></Color>
    <div class="m-t1 m-b1">
      <a-radio-group v-model:value="gpsType">
      <a-radio :value="1">智运GPS</a-radio>
      <a-radio :value="4">途强GPS</a-radio>  
    </a-radio-group>
    </div>
    <div class="m-b2 m-t1">
      <a-button @click="searchBtn" type="primary">搜索</a-button>
    </div> 
    <div >
     <div v-for="opt in subItemList" :key="opt.id" class="flex " style="color:#287ED4">
         {{ opt.title }}&nbsp;&nbsp;
         <div>{{ opt.num }}{{ opt.unit }}</div>
     </div>
  </div>
  </div>

  <a-modal v-model:visible="visible" title="查询结果" footer="" width="22%">
      <div>
        {{presentPosition}}
      </div>
      <div class="flex ju-end m-t1">
        <a-button type="primary" @click="copyData">复制</a-button>
      </div>
  </a-modal>
</template>

<script setup>
import { ref } from 'vue'
import { transTransportTruckGpsCarNoInfo } from '@/api/transport/transport'
import { IfNull } from '@/utils/util'    
import { message } from 'ant-design-vue';
import { copyText } from '@/utils/util'
import Color from './color'
const props = defineProps({
  subItemList:{
    type: Object,
    default: {}
  }
})

const color = ref('rgba(255, 255, 255, 1)');
const positionCarNo = ref('')
const gpsType = ref(1)
const visible = ref(false)
const presentPosition = ref('')

const copyData = () => {
  copyText(presentPosition.value)
  message.success('文本内容已复制')
}
const searchBtn = () => {
  if(!IfNull(positionCarNo.value)){
    return message.error('请输入车牌号')
  }
  transTransportTruckGpsCarNoInfo({ carNo: positionCarNo.value},positionCarNo.value,gpsType.value ).then( res => {
    if(res.code !== 10000) return
    presentPosition.value = res.data
    visible.value = true
  })
}
</script>

<style lang="less" scoped>
.carNo{
  color: #0066CC;
}
</style>