import { ref } from 'vue'
  
// 0全部 1公海 2私海 3合作 4临时 5无效 6成交
export const carryiptData0 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间起",
    value: null,
    prop: "receiveTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间止",
    value: null,
    prop: "receiveTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt:[]
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepetitive",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "是否成交",
    value: null,
    prop: "isDeal",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "inputAuto",
    placeholder: "无效原因",
    value: null,
    prop: "fallReason",
    width: 200,
    key:'label',
    keyVal:'label',
    opt:[]
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "前业务员",
    value: null,
    prop: "faller",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
export const carrycolumns0 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed:'left'
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
     fixed:'left'
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
     fixed:'left'
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '领取信息',
    dataIndex: 'receiveTime',
    slots: {
      customRender: 'receiveTime'
    },
    align: 'center',
  },
  {
    title: '领取时效',
    dataIndex: 'receivedTime',
    slots: {
      customRender: 'receivedTime'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: "前业务员",
    dataIndex: "faller",
    slots: {
      customRender: 'faller'
    },
    align: 'center',
  },
  {
    title: '掉落时间',
    dataIndex: 'fallTime',
    align: 'center',
  },
  {
    title: '无效原因',
    dataIndex: 'fallReason',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
  },
  {
    title: '是否成交',
    dataIndex: 'isDeal.label',
    align: 'center',
  },
  {
    title: '成交时效',
    dataIndex: 'dealTime',
    slots:{
      customRender: 'dealTime'
    },
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '首次成交时间',
    dataIndex: 'firstTransTime',
    align: 'center',
  },
  {
    title: '最近一次成交时间',
    dataIndex: 'lastTransTime',
    align: 'center',
  },
  {
    title: '毛利',
    dataIndex: 'totalGrossProfit',
    slots:{
      customRender: 'totalGrossProfit'
    },
    align: 'center',
  },
  {
    title: '跟进',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
     fixed:'right'
  },
])
export const carryiptData1 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt:[]
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepetitive",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
export const carrycolumns1 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '跟进',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'distribution',
    slots:{
      customRender:'distribution'
    },
    width: 80,
    align: 'center',
  },
])
export const carryiptData2 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间起",
    value: null,
    prop: "receiveTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间止",
    value: null,
    prop: "receiveTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt: []
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepetitive",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
export const carrycolumns2 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '领取信息',
    dataIndex: 'receiveTime',
    slots: {
      customRender: 'receiveTime'
    },
    align: 'center',
  },
  {
    title: '领取时效',
    dataIndex: 'receivedTime',
    slots: {
      customRender: 'receivedTime'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '跟进',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
  },
])
export const carryiptData3 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间起",
    value: null,
    prop: "receiveTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间止",
    value: null,
    prop: "receiveTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt: []
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepetitive",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "是否成交",
    value: null,
    prop: "isDeal",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
export const carrycolumns3 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
      fixed:'left'
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
      fixed:'left'
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
      fixed:'left'
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '领取信息',
    dataIndex: 'receiveTime',
    slots: {
      customRender: 'receiveTime'
    },
    align: 'center',
  },
  {
    title: '领取时效',
    dataIndex: 'receivedTime',
    slots: {
      customRender: 'receivedTime'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
  },
  {
    title: '是否成交',
    dataIndex: 'isDeal.label',
    align: 'center',
  },
  {
    title: '成交时效',
    dataIndex: 'dealTime',
    slots:{
      customRender: 'dealTime'
    },
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '首次成交时间',
    dataIndex: 'firstTransTime',
    align: 'center',
  },
  {
    title: '最近一次成交时间',
    dataIndex: 'lastTransTime',
    align: 'center',
  },
  {
    title: '毛利',
    dataIndex: 'totalGrossProfit',
    slots:{
      customRender: 'totalGrossProfit'
    },
    align: 'center',
  },
  {
    title: '跟进',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
      fixed:'right'
  },
])
export const carryiptData4 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间起",
    value: null,
    prop: "receiveTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间止",
    value: null,
    prop: "receiveTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt: []
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepetitive",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "input",
    placeholder: "前业务员",
    value: null,
    prop: "faller",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
export const carrycolumns4 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '领取信息',
    dataIndex: 'receiveTime',
    slots: {
      customRender: 'receiveTime'
    },
    align: 'center',
  },
  {
    title: '领取时效',
    dataIndex: 'receivedTime',
    slots: {
      customRender: 'receivedTime'
    },
    align: 'center',
  },
  {
    title: '前业务员',
    dataIndex: 'faller',
    align: 'center',
  },
  {
    title: '掉落时间',
    dataIndex: 'fallTime',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
  },

  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '跟进',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'distribution',
    slots:{
      customRender:'distribution'
    },
    align: 'center',
    width: 80,
  },
])
export const carryiptData5 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间起",
    value: null,
    prop: "receiveTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间止",
    value: null,
    prop: "receiveTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt: []
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepetitive",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "inputAuto",
    placeholder: "无效原因",
    value: null,
    prop: "fallReason",
    key:'label',
    width: 200,
    opt: [
    ]
  },
  {
    type: "input",
    placeholder: "前业务员",
    value: null,
    prop: "faller",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
export const carrycolumns5 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '领取信息',
    dataIndex: 'receiveTime',
    slots: {
      customRender: 'receiveTime'
    },
    align: 'center',
  },
  {
    title: '领取时效',
    dataIndex: 'receivedTime',
    slots: {
      customRender: 'receivedTime'
    },
    align: 'center',
  },
  {
    title: '前业务员',
    dataIndex: 'faller',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '无效原因',
    dataIndex: 'fallReason',
    align: 'center',
  },
  {
    title: '跟进',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'distribution',
    slots:{
      customRender:'distribution'
    },
    align: 'center',
    width: 80,
  },
])
export const carryiptData6 = ref([
  {
    type: "selectTime",
    placeholder: "创建时间起",
    value: null,
    prop: "clueCreateTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "创建时间止",
    value: null,
    prop: "clueCreateTimeEnd",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间起",
    value: null,
    prop: "receiveTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "领取时间止",
    value: null,
    prop: "receiveTimeEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt:[]
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
export const carrycolumns6 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
     fixed:'left'
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center',
     fixed:'left'
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
     fixed:'left'
  },
  {
    title: '创建信息',
    dataIndex: 'clueCreateTime',
    slots: {
      customRender: 'clueCreateTime'
    },
    align: 'center',
  },
  {
    title: '领取信息',
    dataIndex: 'receiveTime',
    slots: {
      customRender: 'receiveTime'
    },
    align: 'center',
  },
  {
    title: '领取时效',
    dataIndex: 'receivedTime',
    slots: {
      customRender: 'receivedTime'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '成交时效',
    dataIndex: 'dealTime',
    slots:{
      customRender: 'dealTime'
    },
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '首次成交时间',
    dataIndex: 'firstTransTime',
    align: 'center',
  },
  {
    title: '最近一次成交时间',
    dataIndex: 'lastTransTime',
    align: 'center',
  },
  {
    title: '毛利',
    dataIndex: 'totalGrossProfit',
    slots:{
      customRender: 'totalGrossProfit'
    },
    align: 'center',
  },
])