import { postBodyRequest, getRequest } from '@/utils/axios'

export function edit (params) {
  return postBodyRequest('/market/crm/customer/edit', params)
}
export function detail (customerId, params) {
  return getRequest(`/market/crm/customer/${customerId}`, params)
}

export function fuzzy (params) {
  return postBodyRequest('/market/crm/customer/private-coop/fuzzy', params)
}

