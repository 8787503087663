<template>
  <div>
    <a-modal :visible="visible" :title="title" @cancel="cancel" footer="" width="60%" bodyStyle="padding:0px;background: #F4F5F7;">
      <div class="flex ju-between">
        <div style="width:69%" class="leftBox">
          <div class="m-b2">历史上报记录</div>
          <div style="margin-bottom:10px" class="content-data" v-for="item in abnormalList" :key="item.explainTime">
            <a-row :gutter="[24, 8]">
              <a-col :span="3">
                <span class="title-color">
                  <span v-show="!item.amount">异常类型</span>
                  <span v-show="item.amount > 0">费用类型</span>
                </span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.exceptionType?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">所属任务段</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.transportType?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">审核状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.auditStatus?.label || '' }}</span>
                <a-tooltip>
                  <template #title>{{ item.auditRemark || '没有审核备注' }}</template>
                  <MessageOutlined :style="{ marginLeft: '5px' }" />
                </a-tooltip>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">异常原因</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.exceptionSubType?.label }}</span>
              </a-col>
              <a-col :span="3" v-show="item.amount > 0">
                <span class="title-color">费用金额</span>
              </a-col>
              <a-col :span="5" v-show="item.amount > 0">
                <span class="data-color">{{ item.amount }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">原因</span>
              </a-col>
              <a-col :span="5">
                <div class="data-color" style="width:150px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  <a-tooltip placement="left">
                    <template #title>{{ item.exceptionExplain }}</template>
                    {{ item.exceptionExplain }}
                  </a-tooltip>
                </div>
              </a-col>
              <a-col :span="3">
                <span class="title-color">异常凭证</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color"><a-image v-show="item.fileRealUrl" :width="20" :height="20" :src="item.fileRealUrl" /></span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">上报人</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.explainer }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">上报时间</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.explainTime }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">审核人</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.follower }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">审核时间</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.followTime || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">所属承运商</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.carrierName || '' }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <div style="width:30%" class="rightBox">
          <div v-if="props.auditType === 0">
            <p>异常原因：{{details.exceptionType?.label}}</p>
            <p>备注：{{ details.remark }}</p>
            <p>上报时间：{{ details.reportTime }}</p>
            <p>上报人：{{ details.reporter }}</p>
          </div>
          <div v-if="props.auditType === 1">
            <p>异常原因：{{details.exceptionType?.label}}</p>
            <p>备注：{{ details.remark }}</p>
            <p>上报时间：{{ details.reportTime }}</p>
            <p>上报人：{{ details.reporter }}</p>
            <p>审核结果：{{ details.auditStatus?.label }}</p>
            <p v-if="details.auditRemark">审核备注：{{ details.auditRemark }}</p>
            <p>审核人：{{ details.auditor }}</p>
            <p>审核时间：{{ details.auditTime }}</p>
          </div>
          <div v-if="props.auditType === 2">
            <p>异常原因：{{details.exceptionType?.label}}</p>
            <p>备注：{{ details.remark }}</p>
            <p>上报时间：{{ details.reportTime }}</p>
            <p>上报人：{{ details.reporter }}</p>
            <p>审核结果：{{ details.auditStatus?.label }}</p>
            <p>审核备注：{{ details.auditRemark }}</p>
            <p>审核人：{{ details.auditor }}</p>
            <p>审核时间：{{ details.auditTime }}</p>
          </div>
          <div v-if="props.auditType === 3">
            <div>发起分析</div>
            <div>
              <FormList @getVal="getVal" ref="formListRef" :fromList="fromList"></FormList>
              <div class="flex ju-end m-t1">
                <a-button @click="submit" type="primary">提交</a-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import FormList from '@/components/formList'
import { analysisAdd, exceptionPage, analysisDetail } from '@/api/transport/transport'
import { message } from 'ant-design-vue';
import { MessageOutlined } from '@ant-design/icons-vue'
const emit = defineEmits(['update:visible', 'success'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  orderVehicleId: {
    type: String,
    default: ''
  },
  ovaId: {
    type: Number,
    default: null
  },
  auditType: {
    type: Number,
    default: null
  }
})
const abnormalList = ref([])
const formListRef = ref(null)
const details = ref({})

const getVal = (e) => {
  analysisAdd({
    exceptionType: e.exceptionType,
    orderVehicleId: props.orderVehicleId,
    remark: e.remark
  }).then(res => {
    if (res.code !== 10000) return
    message.success('提交成功')
    cancel()
    emit('success')
  })
}
const reqExceptionPage = () => {
  exceptionPage({
    current: 1,
    size: 100,
    reportType: 2,
    orderVehicleId: props.orderVehicleId
  }).then(res => {
    if (res.code !== 10000) return
    abnormalList.value = res.data.records
    if (res.data.records) {
      let ids = res.data.records.map(item => item.exceptionSubType.value)
      let flag1 = ids.includes(1)
      if (flag1) {
        fromList.value[0].opt.unshift(list1)
      }
      let flag3 = ids.includes(3)
      if (flag3) {
        fromList.value[0].opt.unshift(list3)
      }
      let flag10 = ids.includes(10)
      if (flag10) {
        fromList.value[0].opt.unshift(list10)
      }
    }
  })
}
const reqAnalysisDetail = () => {
  if (props.auditType === 3) return
  analysisDetail({
    id: props.ovaId
  }).then(res => {
    if (res.code !== 10000) return
    details.value = res.data
  })
}
onMounted(() => {
  reqExceptionPage()
  reqAnalysisDetail()
})
const submit = () => {
  formListRef.value.getValRef()
}
const cancel = () => {
  emit('update:visible', false)
}
let list1 =
{
  name: '客户原因',
  id: 1
}

let list3 =
{
  name: '自然因素',
  id: 3
}
let list10 =
{
  name: '单台凑板',
  id: 10
}

const fromList = ref([
  {
    label: "选择原因",
    name: "exceptionType",
    type: "select",
    placeholder: "请选择",
    value: null,
    prop: "exceptionType",
    width: "100%",
    opt: [
      {
        name: '同行原因',
        id: 2
      },
      {
        name: '个人原因',
        id: 4
      },
      {
        name: '公司原因',
        id: 5
      },
      {
        name: '车辆异常',
        id: 6
      },
      {
        name: '定位不准',
        id: 8
      },

      {
        name: '其他原因',
        id: 7
      },
    ],
    rules: [{ required: true, message: "请选择", trigger: "blur", type: "number" }],
  },
  {
    label: "备注",
    name: "remark",
    type: "textarea",
    placeholder: "填写备注",
    value: null,
    prop: "remark",
    width: "100%",
    rows: 3,
    rules: [{ required: true, message: "请填写", trigger: "blur", type: "string" }],
  },
])
</script>

<style lang="less" scoped>
.content-data {
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 3px;
  background: rgba(242, 242, 242, 0.3);
}

.abnormal-content {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}

.leftBox {
  background: #fff;
  padding: 20px;
  max-height: 700px;
  overflow: auto;
}
.rightBox {
  padding: 20px;
  background: #fff;
}
</style>