<template>
  <div>
    <!-- 批量验车完成 -->
    <a-modal title="批量验车完成" :visible="batchAccomplishShow" width="60%" footer="" :bodyStyle="{ paddingRight: 0 }" @cancel="cancel" destroyOnClose>
      <BatchTable ref="batchTableRef"   :batchType="1" @operateData="operateData" />
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import BatchTable from '@/views/components/transport/smallVehicleOperate/batchTable'
const props = defineProps({
  batchAccomplishShow:{
    type:Boolean,
    default:false
  }
})
const emit = defineEmits(['update:batchAccomplishShow'])

const batchTableRef = ref(null)

const cancel = () => {
  emit('update:batchAccomplishShow', false)
}
</script>

<style lang="less" scoped>
</style>