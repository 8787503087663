<template>
  <div>
    <div class="outerBox flex ju-between">
      {{ item.title }} <span>
        <CountTo color="#0066CC" fontSize="16px" :endVal="item.num" />
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import CountTo from '@/components/countTo'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})

</script>

<style lang="less" scoped>
.outerBox {
  padding: 25px 10px;
  background: #edf0f9;
  border-radius: 4px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}
</style>