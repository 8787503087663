<template>
<div>
  <div class="rightBox">
    <div class="Rtitle">
      <div>干线低价线路推荐，用于指导推广素材制作（价格不能低于500）</div>
    </div>
    <div  class="iptNum">
      <a-input-number style="width:160px" placeholder="价格"  v-model:value="price"  />
      <a-button class="m-l2" :loading="loading" @click="getLineQueryPrice" type="primary">立即查询</a-button>
    </div>
    <div class="listBox" >
       <template v-if="falsg&&data.length===0">
        <a-empty />
       </template>
       <template v-else>
        <div v-for="item in data" :key="item.id" class="flex al-center itemBox">
            <div style="width:70%">{{item.startCity}}-{{ item.endCity }}</div>
            <div style="width:30%" class="flex al-center">
              <div style="width:50%">￥{{ item.finalPrice }}</div>
              <div>{{  item.prescription }}天</div>
            </div>
        </div>
       </template>
    </div>
   </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
import {  lineQueryPrice } from '@/api/marketingConsole/marketingConsole'
const loading = ref(false)
const price = ref(null)
const data = ref([])
const falsg = ref(false)
const getLineQueryPrice  = () => {
  loading.value = true
  lineQueryPrice({
    price:price.value
  }).then( res => {
    if( res.code !== 10000) return
    data.value = res.data
    falsg.value = true
  }).finally(() => {
    loading.value = false
  })
}
</script>

<style lang="less" scoped>
.rightBox{
  background: #fff;
}

.Rtitle{
padding: 10px;
color: #6b778c;
font-size: 14px;
span{
  font-weight: 700;
  color: #344563;
  font-size: 14px;
}
}

.iptNum{
  padding:  10px;
}

.listBox{
  padding: 10px;
  min-height: 100px;
  max-height: 600px;
  overflow: auto;
}

.itemBox{
  margin-bottom: 10px;
  padding: 10px;
  background: #EEEEEE;
}
</style>