<template>
  <div>
    <!-- <a-modal v-model:visible="chooseStatus" title="安排验车人" :width="800"> -->
    <div>
      <div style="display:flex;">
        <p style="margin-right:10px">
          <a-input v-model:value="search.carrierName" :disabled="chooseType === 0" placeholder="请输入承运商"
            style="width:150px" />
        </p>
        <p style="margin-right:10px">
          <a-input v-model:value="search.name" style="width: 200px" placeholder="输入司机姓名" />
        </p>
        <a-button type="primary" @click="searchBtn">搜索</a-button>
      </div>
      <a-table :showHeader="false" :columns="driver" :row-key="record => record.id" :data-source="inspectorList"
        size="small" :loading="loading" :pagination="driverPagination" @change="handleChange">
        <template #operation="{ record }">
          <div>
            <div @click="choose(record)" class="cleBox" :class="changeIdName === record.id ?'changeCleBox':''"><span>选择</span></div>
          </div>
        </template>
        <template #carrierName="{ record }">
          <div>
            <span>{{ record.carrierName }}</span>
            <a @click="addDriverBtn(record)"> &emsp;+新增</a>
          </div>
        </template>
      </a-table>
    </div>
    <div v-if="info.driverInfo.carrierId"> 
     <div class="m-b1">
          <a-input v-model:value="searchCarNo" style="width: 200px" placeholder="请输入车牌号" />
        <a-button class="m-l1" type="primary" @click="searchCar">搜索</a-button>
        <a-button class="m-l1" @click="onAddVehicle">新增</a-button>
     </div>
      <a-table :data-source="dataSource" v-model:columns="columns" :pagination="columnsPagination" :loading="loading" bordered
      @change="colChange" :rowKey="(record, index) => { return index }" size="small">
      <template #carNo="{ record }">
        <div>
          <a @click="lookBigCarData(record)">{{ record.carNo }}</a>
          <span>({{ record.gpsType?.label }})</span>
        </div>
      </template>
      <template #latestLocation="{ record }">
        <div v-show="record.startAddress && record.endAddress">
          <div>{{ record.startAddress + '——' + record.endAddress }}</div>
          <div>
            <span v-show="record.dispatcher">{{ record.dispatcher + ' ' }}</span>
            <span v-show="record.createTime">装车时间：{{ record.createTime }}</span>
          </div>
        </div>
      </template>
      <template #operation="{ record}">
            <div @click="chooseCar(record)" class="cleBox" :class="changeId === record.id ?'changeCleBox':''"><span>选择</span></div>
      </template>
    </a-table>
    </div>
    <!-- </a-modal> -->
    <!-- 新增司机 -->
    <a-modal v-model:visible="addDriverStatus" title="新增司机" centered @ok="driverSave">
      <a-form :model="carrierDriver" :label-col="{ style: { width: '100px' } }" :rules="rules">
        <a-col>
          <a-form-item label="承运商" style="display:flex">
            <a-input style="width:80%" v-model:value="carrierDriver.searchCarrier" placeholder="请输入承运商" />
          </a-form-item>
          <a-form-item label="粘贴内容" style="display:flex">
            <a-textarea v-model:value="copyValue" placeholder="将复制内容粘贴在此" :rows="4" />
            <a @click="autoEvaluation">自动赋值</a>
          </a-form-item>
          <a-form-item label="司机姓名" name="name">
            <a-input v-model:value="carrierDriver.name" placeholder="请填写司机姓名" />
          </a-form-item>
          <a-form-item label="身份证号">
            <a-input v-model:value="carrierDriver.idNumber" placeholder="请填写身份证号码" />
          </a-form-item>
          <a-form-item label="联系电话" name="mobile">
            <a-input v-model:value="carrierDriver.mobile" placeholder="请填写司机电话" />
          </a-form-item>
          <a-form-item label="司机类型">
            <a-select v-model:value="driverTypeData" style="width: 250px" :options="driverType" placeholder="请选择司机类型"
              @change="driverTypeChange" />
          </a-form-item>
        </a-col>
      </a-form>
    </a-modal>
      <!-- 承运商车辆添加 -->
    <Addvehicle @loadData="refresh" :carrierId="carrierId" ref="_addVehicle" />
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { reactive, toRefs, onMounted ,ref} from 'vue'
import { Freepage, addDriver as add } from '@/api/carrier/driverList'
import { carrierTruckFreePage } from '@/api/transport/truck'
import { mobileValidate, idNumberValidate } from '@/utils/validate'
import { inspectionChange, inspectionChangeEdit } from '@/api/transport/inspection'
import Addvehicle from '@/views/carrier/toolDetail/vehicleList/add.vue'
export default {
  props: { taskId: String, carrier: Object, editData: Object, appCarrier: Number, truckStatus: Object },
  components: {
    Addvehicle
  },
  setup (props, context) {
    const state = reactive({
      changeIdName:'',
      changeId:'',
      dataSource:[],
      driverTypeData: '',
      carrierId: '',
      copyValue: '',
      inspectorList: [],
      carrierDriver: { id: '' },
      chooseType: null,
      inputDis: false,
      loading: false,
      btnLoading: false,
      chooseStatus: false,
      addDriverStatus: false,
      carrierName: '',
      search: {
        name: '',
        carrierName: ''
      },
      rules: {
        name:{ required: true, message: '请填写司机名称', trigger: 'blur' },
        idNumber: [{ required: true, message: '请填写身份证号码', trigger: 'blur' }, { pattern: idNumberValidate, message: '请填写正确的身份证号码', trigger: 'blur' }],
        mobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }]
      },
      driver: [
        {
          title: '司机名称',
          dataIndex: 'name',
          width: '20%',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: '电话号码',
          dataIndex: 'mobile'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          slots: {
            customRender: 'carrierName'
          }
        },
        // {
        //   title: '司机类型',
        //   width: '12%',
        //   dataIndex: 'driverType.label'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      driverType: [
        {
          value: 1,
          label: '大板车'
        },
        {
          value: 2,
          label: '小板车'
        },
        {
          value: 3,
          label: '地跑'
        }
      ],
      driverPagination: {
        current: 1,
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '8', '10'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      columnsPagination: {
        current: 1,
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '8', '10'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      columns: [
        {
          title: '运输类型',
          dataIndex: 'truckType.label'
        },
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          slots: {
            customRender: 'carNo'
          }
        },
        {
          title: '运输信息',
          dataIndex: 'latestLocation',
          slots: {
            customRender: 'latestLocation'
          }
        },
        {
          title: '剩余空位',
          dataIndex: 'vacancy'
        },
        {
          title: '车辆状态',
          dataIndex: 'truckStatus.label'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      info:{
        carInfo:{},
        driverInfo:{}
      }
    })
    const searchCarNo = ref('')
    
    const searchBtn = () => {
      state.changeId = ''
      state.info.carInfo = {}
      state.info.driverInfo = {}
      state.dataSource = []
      state.driverPagination.current = 1
      state.changeIdName = ''
      loadData()

    }
    const loadData = () => {
      if (state.chooseType === 1) state.search.carrierName = props.carrier.carrierName
      state.loading = true
      Freepage({
        current: state.driverPagination.current,
        size: state.driverPagination.pageSize,
        name: state.search.name,
        carrierName: state.search.carrierName
        // carrierId: props.carrier.carrierId,
      }).then(res => {
        if (res.code === 10000) {
          state.inspectorList = res.data.records
          state.driverPagination.current = res.data.current
          state.driverPagination.pageSize = res.data.size
          state.driverPagination.total = res.data.total
          state.loading = false
        }
      })
    }
    const refresh = () => {
      reqFreepageList()
    }
    // 新增承运商车辆
    const _addVehicle = ref(null)
    const onAddVehicle = () => {
      _addVehicle.value.addShow = true
    }
    const chooseCar = (item) => {
      state.changeId = item.id
      state.info.carInfo = item
    }
    // 选择司机
    const carrierId = ref('')
    const choose = record => {
      state.changeIdName = record.id
      carrierId.value = record.carrierId
      state.columnsPagination.current = 1
      state.info.driverInfo = record
      state.changeId = ''
      state.info.carInfo = {}
      searchCarNo.value = ''
      reqFreepageList()
      // state.btnLoading = true
      // context.emit('status', record)
      // setTimeout(() => {
      //   if (props.truckStatus) {
      //     inspectionChangeEdit({
      //       orderVehicleId: props.carrier.orderVehicleIds[0],
      //       carrierId: record.carrierId,
      //       carrierName: record.carrierName,
      //       driverId: record.id,
      //       driverName: record.name,
      //       driverType: record.driverType,
      //       isNull: record.driverType,
      //       orderId: props.carrier.orderId,
      //       driverTel: record.mobile,
      //       driverIdNumber: record.idNumber,
      //       taskId: props.taskId || null
      //     }).then(res => {
      //       if (res.code === 10000) {
      //         // console.log(res)
      //         props.editData = {}
      //         props.carrier = {}
      //         state.btnLoading = false
      //         message.success(res.msg)
      //         context.emit('status', true)
      //       }
      //     }).catch(err => console.log(err))
      //   } else {
      //     inspectionChange({
      //       carrierId: record.carrierId,
      //       carrierName: record.carrierName,
      //       driverId: record.id,
      //       driverName: record.name,
      //       driverType: record.driverType,
      //       isNull: record.driverType,
      //       orderId: props.carrier.orderId,
      //       driverTel: record.mobile,
      //       driverIdNumber: record.idNumber,
      //       orderVehicleIds: props.carrier.orderVehicleIds,
      //       taskId: props.taskId || null
      //     }).then(res => {
      //       if (res.code === 10000) {
      //         // console.log(res)
      //         message.success(res.msg)
      //         props.editData = {}
      //         props.carrier = {}
      //         state.btnLoading = false
      //         if (props.appCarrier) context.emit('status', record)
      //         else context.emit('status', true)
      //         // emit('inspectTableChange')
      //       }
      //     }).catch(err => { console.log(err) })
      //   }
      // }, 1000)
    }
    const colChange = (pag) => {
      state.columnsPagination.current = pag.current
      state.columnsPagination.pageSize = pag.pageSize
      reqFreepageList()
    }
  
    const searchCar = () => {
      reqFreepageList()
      state.changeId = ''
      state.info.carInfo = {}
    }
    const reqFreepageList = () => {
      carrierTruckFreePage ({
          carNo:searchCarNo.value,
          carrierId: carrierId.value,
          current: state.columnsPagination.current,
          size: state.columnsPagination.pageSize,
        }).then(res => {
          if (res.code === 10000) {
            state.dataSource = res.data.records
            state.columnsPagination.total = res.data.total
          }
        })
    }
    onMounted(() => {
      if (props.carrier) {
        state.chooseType = props.carrier.chooseType  // 1为系统推荐2位人工选择
        state.search.carrierName = props.carrier.carrierName
      }
      loadData()
    })
    // 保存新增司机
    const driverSave = () => {
      state.carrierDriver.carrierId = state.carrierId
      add(state.carrierDriver)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addDriverStatus = false
            loadData()
          }
        }).catch(err => {
          console.log(err)
        })
    }
    // 表格分页
    const handleChange = (pag, filters, sorter) => {
      state.driverPagination.current = pag.current
      state.driverPagination.pageSize = pag.pageSize
      loadData()
    }
    // 新增司机车辆类型选择
    const driverTypeChange = (e, v) => {
      state.carrierDriver.driverType = v
    }

    // 新增司机
    const addDriverBtn = (record) => {
      state.addDriverStatus = true
      state.carrierDriver.searchCarrier = record.carrierName
      state.carrierId = record.carrierId
    }
    const searchChange = val => {
      console.log(val)
    }
    const contractorChange = (value) => {
      console.log(value)
    }
    const carrierChange = (value) => {
      console.log(value)
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
    }
    const autoEvaluation = () => {
      if (state.copyValue != '') {
        let str = state.copyValue.replaceAll('：', ':')
        state.copyValue = str
        let arr = state.copyValue.split('\n')
        let arrCopy = []
        arr.forEach(item => {
          arrCopy.push(item.split(':'))
        })
        state.carrierDriver.name = arrCopy[0][1]
        state.carrierDriver.idNumber = arrCopy[2][1]
        state.carrierDriver.mobile = arrCopy[1][1]
        state.copyValue = ''
      }else message.error('粘贴内容为空，无法赋值')
    }
    return {
      ...toRefs(state),
      loadData,
      choose,
      searchBtn,
      driverSave,
      searchChange,
      addDriverBtn,
      handleChange,
      carrierChange,
      autoEvaluation,
      contractorChange,
      driverTypeChange,
      handleTableChange,
      chooseCar,
      colChange,
      carrierId,
      searchCar,
      searchCarNo,
      onAddVehicle,
      _addVehicle,
      refresh
    }
    defineExpose({
    });
  }
}
</script>

<style lang="less" scoped>
.cleBox{
   cursor: pointer;
    padding: 1px 3px;
    font-size: 14px;
    border-radius: 4px;
    border:1px solid #d9d9d9;
    display: flex;
    justify-content: center;
}
.cleBox:hover{
    border:1px solid #40a9ff;
    color: #40a9ff;
}
.changeCleBox{
  border:1px solid #40a9ff;
    color: #40a9ff;
}
</style>
