<template>
  <div>
    <!-- 批量放空 -->
    <a-modal title="批量放空" :visible="emptyShow" width="80%" footer="" destroyOnClose @cancel="cancel">
      <div class="flex">
        <div style="width:70%">
          <EmptyTable :batchType="1" @operateData="emptyData" />
        </div>
        <div style="width:1%;height:100%;background:red"></div>
        <div v-show="emptyIsEnd" style="width:28%;padding:20px">
          <span class="m-t4" style="font-size:16px;color:#333">放空费</span>
          <div class="m-t3"><span style="color:red">*</span>单台放空费:</div>
          <div style="width:300px" class="m-t1"> <a-input v-model:value="emptyNum" suffix="元" /></div>
          <div class="m-t2" style="color:#D9001B">注：请填写单台车辆的放空费</div>
          <div class="flex m-t4 pos-rel" style="left:250px">
            <a-button type="primary" @click="submitEmpty">确定</a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import EmptyTable from '@/views/components/transport/smallVehicleOperate/emptyTable'
import { ventBatch } from '@/api/transport/transport'
import { message } from 'ant-design-vue';
const props = defineProps({
  emptyShow: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:emptyShow'])

const emptyIsEnd = ref(false)
const emptyNum = ref('')
const emptyInfo = ref({})

//批量放空接口
const submitEmpty = () => {
  if (!emptyNum.value) {
    message.error('请输入单台放空费')
    return
  }
  ventBatch({
    amt: emptyNum.value,
    orderId: emptyInfo.value.orderId,
    orderVehicleIds: emptyInfo.value.orderVehicleIds
  }).then(res => {
    if (res.code === 10000) {
      message.success('操作成功')
      cancel()
    }
  })
}
const emptyData = (e) => {
  emptyIsEnd.value = true
  emptyInfo.value = e
}
const cancel = () => {
  emit('update:emptyShow', false)
}


</script>

<style lang="less" scoped>
</style>