<template>
  <div>
    <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="m-b1">当前筛选出 <a>{{dataSource.length}}</a> 条</div>
      <a-table :columns="columns" :row-key="record => record.id" size="small" @change="handleTableChange" :pagination="pagination"  :data-source="dataSource" bordered :loading="loading">
        <template #sort="{index}">
          {{ index + 1 }}
        </template>
        <template #createTime="{record}">
          {{ record.createTime  }} 
          <div>{{record.firstSaleName }}</div>
        </template>
        <template #startAddress="{record}">
          <span v-if="record.startAddress">起：{{ record.startAddress }}</span>
          <div v-if="record.endAddress"> 终：{{ record.endAddress }}</div>
        </template>
        <template #remark="{record}">
          <a-tooltip v-show="record.remark" color="#fff">
            <template #title>
              <span style="color:#000">{{ record.remark }}</span>
            </template>
            <div class="remark">{{ record.remark }}</div>
          </a-tooltip>
        </template>
        <template #add="{record}">
          <span>
            {{ record.followContent }}
          </span>
          <a @click="remind(record)">跟进</a>
        </template>
      </a-table>
    </div>
    <!-- 提醒跟进记录 -->
    <a-drawer title="跟进记录" width="80%" placement="right" v-model:visible="reminShow" :destroyOnClose="true">
      <FollowAdd :customerId="customerId" :followType="9" @addSuccess="addSuccess" />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'
import { cluPage } from '@/api/marketingConsole/marketingConsole'
import FollowAdd from '@/views/crmManagement/tool/customerFollow/add.vue'
import { message } from 'ant-design-vue';

const loading = ref(false)
const dataSource = ref([])
const reminShow = ref(false)
const customerId = ref(null)
const searchMsg = ref({})
const pagination = ref({
  current: 1,
  pageSizeOptions: ['10', '20', '30'],
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const handleTableChange = (pag, filters, sorter) => {
  pagination.value.current = pag.current
  pagination.value.pageSize = pag.pageSize
  loadData()
}
const searchAdd = (e) => {
  if (!e.phone && !e.wechat) {
    message.error('请输入电话或微信号,再进行搜索')
    return
  }
  pagination.value.current = 1
  searchMsg.value = e
  loadData()
}
const addSuccess = (e) => {
  dataSource.value.forEach(item => {
  if(item.customerId === customerId.value){
    item.followContent = e
  }
 })
}
const loadData = () => {
  loading.value = true
  cluPage(
    {
      current: pagination.value.current,
      size: pagination.value.pageSize,
      ...searchMsg.value
    }
  ).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const remind = record => {
  customerId.value = record.customerId
  reminShow.value = true
}
const resetBtn = () => {
  pagination.value.current = 1
  dataSource.value = []
}

const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 50
  },
  {
    title: '电话',
    dataIndex: 'phone',
    align: 'center',
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    align: 'center',
  },
  {
    title: '创建信息',
    dataIndex: 'createTime',
    slots: {
      customRender: 'createTime'
    },
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '渠道来源',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '所在位置',
    dataIndex: 'customerClass.label',
    align: 'center',
  },
  {
    title: '是否重复',
    dataIndex: 'isRepetitive.label',
    align: 'center',
  },
  {
    title: '是否成交',
    dataIndex: 'isDeal.label',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots: {
      customRender: 'remark'
    },
    align: 'center',
    width: 120
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    },
    align: 'center',
  },
])
const iptData = ref([
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "phone",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  background: #fff;
  padding: 10px;
}

.remark {
  max-width: 120px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>