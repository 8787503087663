<template>
  <div>
    <a-modal :width="750" @cancel="onNoAddForm" v-if="addShow" v-model:visible="addShow" destroyOnClose centered footer="">
      <template #title>
        <span style="font-size:20px">{{editShow===0?'新增车辆':'编辑车辆'}}</span>
      </template>
      <a-form layout="vertical" ref="formRef" :model="addForm">
        <a-row :gutter="[24,12]">
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="大车车牌">
              <a-input placeholder="请填写大车车牌" v-model:value="addForm.carNo" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="车辆类型">
              <a-select v-model:value="addForm.truckType.value" @change="onTruckType" :options="$store.state.enumAll.truckType" placeholder="请选择车辆类型" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="车辆型号">
              <a-cascader :placeholder="addForm.model" :options="$store.state.app.carBrand" :show-search="{ filter }" @change="brandChange" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="GPS类型">
              <a-select v-model:value="addForm.gpsType.value" @change="GPSchange" :options="$store.state.enumAll.gpsType" placeholder="GPS类型" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="车位">
              <a-input v-model:value="addForm.place" type="number" :min="1" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
          </a-col>
          <a-col :span="12" v-if="editShow!=1">
            <a-form-item label="行驶证" @click="flag=1">
              <a-upload v-model:file-list="drivingLicenseFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="drivingLicenseImg" style="width:150px;height:150px" :src="drivingLicenseImg" />
                <div v-else>
                  <loading-outlined v-if="drivingLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=1">
            <a-form-item label="营运证" @click="flag=2">
              <a-upload v-model:file-list="operationCertificateFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="operationCertificateImg" style="width:150px;height:150px" :src="operationCertificateImg" />
                <div v-else>
                  <loading-outlined v-if="businessLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=1">
            <a-form-item label="车辆保单" @click="flag=3">
              <a-upload v-model:file-list="insuranceFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="insuranceImg" style="width:150px;height:150px" :src="insuranceImg" />
                <div v-else>
                  <loading-outlined v-if="insuranceLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=1">
            <a-form-item label="车牌照片(车头)" @click="flag=4">
              <a-upload v-model:file-list="truckHeadFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="truckHeadImg" style="width:150px;height:150px" :src="truckHeadImg" />
                <div v-else>
                  <loading-outlined v-if="truckHeadLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=1">
            <a-form-item label="车牌照片(挂车)" @click="flag=5">
              <a-upload v-model:file-list="truckTailFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="truckTailImg" style="width:150px;height:150px" :src="truckTailImg" />
                <div v-else>
                  <loading-outlined v-if="carRearLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div style="display:flex;justify-content: flex-end;margin-top:15px">
        <a-button size="large" style="margin-right:15px" @click="addShow=false">取消</a-button>
        <a-button size="large" class="searchLoading-button" :loading="btnLoad" @click="addData">{{editShow===0?'新增':'保存'}}</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { fileUploadPublic as fileUpload } from '@/api/global'
import { addvehicles, carrierDetail, getCarrierIdTruckId, edit, editPhoto } from '@/api/carrier/vehiclesList'

export default {
  props: { carrierId: String },
  setup (props, context) {
    const state = reactive({
      carrierId: props.carrierId,
      loading: false,
      btnLoad: false,
      detailShow: false,
      addShow: false,
      editShow: 0,
      drivingLoading: false,
      businessLoading: false,
      insuranceLoading: false,
      truckHeadLoading: false,
      carRearLoading: false,
      flag: 0,
      empty: {},
      detailImg: [],
      detailImgEdit: [],
      listData: [],
      drivingLicenseFile: [],
      operationCertificateFile: [],
      insuranceFile: [],
      truckHeadFile: [],
      truckTailFile: [],
      drivingLicenseImg: '',
      operationCertificateImg: '',
      insuranceImg: '',
      truckHeadImg: '',
      truckTailImg: '',
      addForm: {
        truckType: {

        },
        gpsType: {},
        carNo: '',
        place: '0',
        drivingLicense: '',
        operationCertificate: '',
        insurance: '',
        truckHead: '',
        truckTail: '',
        model: "请选择车辆类型"
      },
    })
    onMounted(()=>{
      if(props.carrierId) state.carrierId = props.carrierId
    })
    const onImgDetail = record => {
      carrierDetail(record.id)
        .then(res => {
          if (res.code === 10000) {
            if (res.data.drivingLicenseUrl === null) {
              res.data.drivingLicenseUrl = {}
            }
            if (res.data.operationCertificateUrl === null) {
              res.data.operationCertificateUrl = {}
            }
            if (res.data.insuranceUrl === null) {
              res.data.insuranceUrl = {}
            }
            if (res.data.truckHeadUrl === null) {
              res.data.truckHeadUrl = {}
            }
            if (res.data.truckTailUrl === null) {
              res.data.truckTailUrl = {}
            }
            state.drivingLicenseImg = res.data.drivingLicenseUrl.previewUrl
            state.operationCertificateImg = res.data.operationCertificateUrl.previewUrl
            state.insuranceImg = res.data.insuranceUrl.previewUrl
            state.truckHeadImg = res.data.truckHeadUrl.previewUrl
            state.truckTailImg = res.data.truckTailUrl.previewUrl

            state.addForm.drivingLicense = res.data.drivingLicenseUrl.id
            state.addForm.operationCertificate = res.data.operationCertificateUrl.id
            state.addForm.insurance = res.data.insuranceUrl.id
            state.addForm.truckHead = res.data.truckHeadUrl.id
            state.addForm.truckTail = res.data.truckTailUrl.id

          }
        }).catch(err => { console.log(err) })
    }


    const brandChange = (e, v) => {
      console.log('brandChange', e, v);

      if (v.length <= 1) state.addForm.model = v[0].label
      else state.addForm.model = v[0].label + v[1].label
    }
    const GPSchange = (e, v) => {
      state.addForm.gpsType = { label: v.label, value: v.value }
    }
    const onTruckType = (e, v) => {
      state.addForm.truckType = { label: v.label, value: v.value }
    }
    // 图片上传
    const handleChange = info => {
      const formData = new FormData()
      switch (state.flag) {
        case 1:
          state.drivingLoading = true
          formData.append('file', state.drivingLicenseFile[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.drivingLicenseImg = res.data.url
                state.addForm.drivingLicense = res.data.id
                state.drivingLoading = false
                state.drivingLicenseFile = []
              }
            }
          })
          break
        case 2:
          state.businessLoading = true
          formData.append('file', state.operationCertificateFile[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.operationCertificateImg = res.data.url
                state.addForm.operationCertificate = res.data.id
                state.businessLoading = false
                state.operationCertificateFile = []
              }
            }
          })
          break
        case 3:
          state.insuranceLoading = true
          formData.append('file', state.insuranceFile[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.insuranceImg = res.data.url
                state.addForm.insurance = res.data.id
                state.insuranceLoading = false
                state.insuranceFile = []
              }
            }
          })
          break
        case 4:
          state.truckHeadLoading = true
          formData.append('file', state.truckHeadFile[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.truckHeadImg = res.data.url
                state.addForm.truckHead = res.data.id
                state.truckHeadLoading = false
                state.truckHeadFile = []
              }
            }
          })
          break
        case 5:
          state.carRearLoading = true
          formData.append('file', state.truckTailFile[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.truckTailImg = res.data.url
                state.addForm.truckTail = res.data.id
                state.carRearLoading = false
                state.truckTailFile = []
              }
            }
          })
          break
      }
    }

    const beforeUpload = (file, fileList) => {
      switch (state.flag) {
        case 1:
          state.drivingLicenseFile.push(file)
          break
        case 2:
          state.operationCertificateFile.push(file)
          break
        case 3:
          state.insuranceFile.push(file)
          break
        case 4:
          state.truckHeadFile.push(file)
          break
        case 5:
          state.truckTailFile.push(file)
          break
      }
      return false
    }

    //添加
    const onAdd = () => {
      addvehicles({ ...state.addForm, carrierId: props.carrierId })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)

            state.empty = JSON.parse(JSON.stringify(state.addForm))
            onNoAddForm()
            loadData()
          }
        }).catch(err => { console.log(err) }).finally(() => { state.btnLoad = false })
    }
    const addData = () => {
      state.btnLoad = true
      if (state.editShow === 1) {
        onEdit()
      } else if (state.editShow === 2) {
        onEditPhoto()
      } else {
        onAdd()
      }
    }
    const onEdit = () => {
      edit({ ...state.addForm })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onNoAddForm()
          }
        }).catch(err => { console.log(err) }).finally(() => { state.btnLoad = false })

    }
    const onEditPhoto = () => {
      editPhoto({ ...state.addForm })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onNoAddForm()
          }
        }).catch(err => { console.log(err) }).finally(() => { state.btnLoad = false })

    }
    const onDetail = (e) => {
      state.addShow = true
      state.editShow = 1
      getCarrierIdTruckId(e.carrierId, e.id)
        .then(res => {
          if (res.code === 10000) {
            console.log('车辆编辑', res);
            state.addForm = res.data
          }
        }).catch(err => { console.log(err) })
    }
    const onDetailImg = (e) => {
      onImgDetail(e)
      state.editShow = 2
      state.addForm.carrierId = e.carrierId
      state.addForm.id = e.id
      state.addShow = true
    }
    const onNoAddForm = () => {
      state.addShow = false
      state.editShow = 0
      state.addForm = {
        truckType: {},
        gpsType: {},
        carNo: '',
        place: '0',
        drivingLicense: '',
        operationCertificate: '',
        insurance: '',
        truckHead: '',
        model: '请选择车辆型号',
        truckTail: ''
      }
      state.drivingLicenseImg = ''
      state.operationCertificateImg = ''
      state.insuranceImg = ''
      state.truckHeadImg = ''
      state.truckTailImg = ''
      onLoadData()
    }
    // 刷新父组件
    const onLoadData = () => {
      context.emit('loadData')
    }
    return {
      ...toRefs(state),
      addData,
      GPSchange,
      brandChange,
      handleChange,
      beforeUpload,
      onDetail,
      onNoAddForm,
      onAdd,
      onEdit,
      onDetailImg,
      onTruckType,
      onLoadData
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
.searchLoading-button:hover {
  color: #FFF;
}
</style>
