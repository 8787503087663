<template>
  <div ref="modal" class="classmodal">
    <a-modal :getContainer="()=>$refs.modal" :maskClosable="false" centered :visible="visible" footer="" @cancel="cancel" width="85%" :bodyStyle="{ padding:'10px 10px 20px 10px ',background:'#EDF0F9' }">
      <template #title>
        <div class="titleBox flex al-center">
          <div @click="openConfig" class="m-l2 cur-p" style="color:#6b778c">渠道配置</div>
          <img @click="openColor" class="m-l2 cur-p" style="width:18px;height:18px" src="@/assets/images/newTransport/colorChange.png" alt="">

        </div>
      </template>
      <div v-if="loading" class="flex al-center ju-center" style="height: 300px">
        <a-spin />
      </div>
      <div v-if="data.length>0" class="contenBox">
        <div v-for="layout in pcLayout" :key="layout.id" style="width:19.5%;">
          <div v-for="(item,index) in layout" :key="item.id" class="conten" style="max-height: 600px;overflow: auto;">
            <div class="topBox" :style="{background:item.back}">
              <div class="titleText flex ju-between">
                <span class="flex al-center">
                  <img style="width: 18px;height:18px" :src="require(`@/assets/images/mkConsole/icon${item.styleType}.png`)" alt="">
                  <span class="m-l1">{{ item.title }}</span>
                </span>
                <Screen v-if="item.clueDataStatisticsList" :idx="item.idx" :clueDataStatisticsList="item.clueDataStatisticsList" @change="change($event,item)"></Screen>
              </div>
              <div v-if="item.clueDataStatisticsList">
                <Meun :msg="screenAdd(item.clueDataStatisticsList,item.idx)" :idx="item.idx" :channelSubId="item.channelSubId" :title="item.title" :styleType="item.styleType"></Meun>
              </div>
              <div v-else>
                <Meun :msg="item" :styleType="item.styleType"></Meun>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <ConfigMoadl v-if="configVisible" :selProvince="dispatchProvinceList" v-model:visible="configVisible"> </ConfigMoadl>
      <ColorMoadl v-model:visible="colorVisible" :data="data" :ColorStr="ColorStr"></ColorMoadl>
    </a-modal>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, onBeforeUnmount } from 'vue'
import CountTo from '@/components/countTo'
import Meun from './meun'
import { consoleStatistics } from '@/api/marketingConsole/marketingConsole'
import ConfigMoadl from './components/configAdd/configMoadl'
import ColorMoadl from '../newTransport/components/colorMoadl'
import { message } from 'ant-design-vue'
import Screen from './components/screen'


const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const modal = ref(null)
const ColorStr = "marketingCenterColor"
const btnLoading = ref(false)
const settTiming = ref(30)
const colorData = ref([])
const colorVisible = ref(false)
const loadingNum = ref(0)
const loading = ref(false)
const data = ref([])
const pcLayout = ref([])
const dispatchProvinceList = ref([])
const configVisible = ref(false)

const screenAdd = (data, idx) => {
  let list = []
  data.forEach(item => {
    if (item.timeType.value === idx) {
      list = item
    }
  })
  return list
}
const change = (e, item) => {
  item.idx = e
  if (item.styleType === 0) {
    data.value.forEach(item => {
      if (item.clueDataStatisticsList) {
        item.clueDataStatisticsList.forEach(opt => {
          if (opt.timeType.value === e) {
            item.idx = e
          }
        })
      }
    })
  }
  let list = []
  data.value.forEach(item => {
    let msg = {
      title: item.title,
      idx: item.idx
    }
    list.push(msg)
  })
  localStorage.setItem('screenData', JSON.stringify(list))
}
const openColor = () => {
  colorVisible.value = true
}
const openConfig = () => {
  configVisible.value = true
}

const cancel = () => {
  clearTimer()
  emit('update:visible', false)
}


const reqPageAll = () => {
  if (!loadingNum.value) {
    loading.value = true
  }
  btnLoading.value = true
  consoleStatistics({
  }).then(res => {
    if (res.code === 10000) {
      settTiming.value = 30
      debounceFn(reqPageAll, settTiming.value)
      dispatchProvinceList.value = res.data.channelIdList
      if (localStorage.getItem(ColorStr)) {
        colorData.value = JSON.parse(localStorage.getItem(ColorStr))
      }
      res.data.dataStatistics.forEach(item => {
        if (item.clueDataStatisticsList) {
          if (localStorage.getItem('screenData')) {
            let list = JSON.parse(localStorage.getItem('screenData'))
            let value = item.clueDataStatisticsList.map(item => item.timeType.value)
            list.forEach(opt => {
              if (opt.title === item.title) {
                if (value.indexOf(opt.idx) !== -1) {
                  item.idx = opt.idx
                } else {
                  item.idx = item.clueDataStatisticsList[0].timeType.value
                }
              }
            })
            if (!item.idx) {
              item.idx = item.clueDataStatisticsList[0].timeType.value
            }
          } else {
            item.idx = item.clueDataStatisticsList[0].timeType.value
          }
        }
        item.back = '#fff'
        colorData.value.forEach(opt => {
          if (opt.title === item.title) {
            item.back = opt.back
          }
        })
      })
      data.value = res.data.dataStatistics
      let list = []
      res.data.pcLayout.forEach((item) => {
        let arr = []
        item.forEach(opt => {
          arr.push(data.value[opt])
        })
        list.push(arr)
      })
      pcLayout.value = list
    } else {
      if (res.code === 10101) {
        clearTimer()
        return
      }
      settTiming.value = 30
      debounceFn(reqPageAll, settTiming.value)
    }
  }).finally(() => {
    btnLoading.value = false
    loading.value = false
    loadingNum.value = 1
  })
}

// 延时去执行某个函数 （DC）
const DCtimer = []
let timer = null
const debounceFn = (fn, delay) => {
  console.log(DCtimer);
  // console.log(modal.value);
  clearTimer()
  timer = setTimeout(() => {
    if (modal.value) {
      fn()
    }
  }, delay * 1000)
  DCtimer.push(timer)
}

const clearTimer = () => {
  if (DCtimer.length > 0) {
    DCtimer.forEach((item, index) => {
      if (timer === item) {
        DCtimer.splice(index, 1)
      }
      clearTimeout(item)
    })
  }
}
onBeforeUnmount(() => {
  clearTimer()
});
onMounted(() => {
  reqPageAll()

})

</script>

<style lang="less" scoped>
.waterfall {
  position: relative;
}
.waterfall-item {
  position: absolute;
  padding: 10px;
  background: red;
}
.classmodal {
  :deep(.ant-modal-header) {
    padding: 10px !important;
  }
}

.titleBox {
  font-weight: normal;
  font-size: 13px;
  height: 34px;
}

.taskInfo {
  width: 90%;
  color: #344563;
}

.contenBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.conten {
  margin-bottom: 10px;

  .topBox {
    width: 100%;
    min-height: 100px;
    background: #fff;
    padding: 15px 10px;
    border-radius: 2px;
  }
}

.titleText {
  color: #333;
  font-size: 15px;
  font-weight: bold;
}
.itemBox {
  margin-top: 10px;
  padding: 10px;
  background: #f7f8fc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .tips {
    font-size: 12px;
    color: #999;
  }
}

.itemTitle {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
}

.stateStyle {
  padding: 4px 8px;
  background: #edf0f9;
  border-radius: 4px;
  width: 48%;
  margin-bottom: 10px;
  border-left: 5px solid transparent;
}

.arrBox {
  padding: 2px 8px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 12px;
}

.customTags {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
  width: 49%;
}

.line {
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 8px 0;
}

.outerBox {
  padding: 25px 10px;
  background: #f7f8fc;
  border-radius: 4px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}

.tipsIcon {
  width: 16px;
  height: 16px;
}

.custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.changeMar {
  margin-right: 0;
}

.dropdownBox {
  padding: 10px;
  width: 280px;
  box-shadow: 0 2px 5px #333;
  background: #fff;
}
</style>