<template>
  <div>
    <a-modal :visible="visible" centered title="在途跟踪" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          {{ item.name }}({{ item.total }})
        </div>
      </div>
      <SearchList @searchAdd="searchAdd" :loading="loading" @resetBtn="resetBtn" :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a >{{ data.length }}</a>&nbsp;条</div>
        <a-table ref="tableRef" :dataSource="dataSource" size="small" :rowKey="item => item.transTruckId" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:600}">
          <template #sort="{  index }">
            {{ index + 1 }}
          </template>
          <template #carNo="{ record }">
            <a-typography-paragraph v-if="record.carNo" :copyable="{ text: record.carNo }">
              <a @click="open(record)">{{ record.carNo }}</a>
        </a-typography-paragraph>
          </template>
          <template #startAddress="{  record }">
            {{ record.startAddress }}
            <div>{{ record.endAddress }}</div>
          </template>
          <template #add="{ record }">
            <a @click="add(record)">新增</a>
          </template>
          <template #dispatcher="{ record }">
            {{ record.dispatcher }}
            <div>{{ record.departureTime }}</div>
          </template>
        </a-table>
      </div>
      <!-- 新增位置信息 -->
      <a-modal v-model:visible="addShow" title="新增位置信息" width="25%" @ok="submit" :confirmLoading="btnLoading" destroyOnClose>
        <FormList :fromList="fromList" ref="forListRef" @getVal="getVal"></FormList>
      </a-modal>
      <!-- 大车操作 -->
      <LoadingVehilce @confirmAdd="confirmAdd" :windowShow="loadingVehShow" :transTruckId="detailData.transTruckId" @statusShow="loadingVeh" />
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import enumAll from '@/store/modules/enumAll'
import FormList from '@/components/formList'
import { onWayTrack } from '@/api/newTransport/newTransport'
import LoadingVehilce from '@/views/components/transport/bigVehicleOperate/loadingVehilce.vue'
import { truckGpsAdd } from '@/api/transport/truck'
import { message } from 'ant-design-vue'
import { addressTran, transitionTimeYMD } from '@/utils/util'
import { cloneDeep } from 'lodash-es'

const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  info: {
    type: Object,
    default: {}
  },
  tableIdx: {
    type: Number,
    default: null
  },
})

const indexKey = ref(null)
const tableRef = ref(null)
const loading = ref(false)
const forListRef = ref(null)
const addShow = ref(false)
const dataSource = ref([])
const data = ref([])
const searchMsg = ref({})
const loadingVehShow = ref(false)
const detailData = ref({})
const btnLoading = ref(false)
const pageSize = ref(50)
const tabstatus = ref([
  {
    name: '全部',
    id: 0,
    total: 0,
    colorType: null
  },
])

const changeBtn = (e) => {
  ResetScroll()
  indexKey.value = e.colorType
  reqOnWayTrack()
}
const loadingVeh = () => {
  loadingVehShow.value = false
}
const open = (record) => {
  loadingVehShow.value = true
  detailData.value = record
}
const searchAdd = (e) => {
  ResetScroll()
  let msg = cloneDeep(e)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  searchMsg.value =msg
  reqOnWayTrack()
}
const resetBtn = () => {
  ResetScroll()
  searchMsg.value = {}
  reqOnWayTrack()
}
const getVal = (val) => {
  if(val.address.length < 2){
    message.error('请选择到市')
    return
  }
  let formNowLocation = {
    province: val.address[0],
    city:val.address[1]? val.address[1] : '',
    area:val.address[2]? val.address[2] : '',
    details: val.details
  }
  var NowLocation = {
    transTruckId: detailData.value.transTruckId,
    addressDTO: formNowLocation,
    remark: '手动新增'
  }
  btnLoading.value = true
  truckGpsAdd(NowLocation).then(res => {
    if (res.code === 10000) {
      reqOnWayTrack()
      message.success('添加成功')
      addShow.value = false
    }
  }).finally(() => { btnLoading.value = false })
}
const submit = () => {
  forListRef.value.getValRef()
}
const add = (record) => {
  detailData.value = record
  addShow.value = true
}
const cancel = () => {
  emit('update:visible', false)
}
const confirmAdd = () => {
  reqOnWayTrack()
}
const reqOnWayTrack = () => {
  loading.value = true
  onWayTrack(
    {
     ...searchMsg.value,
     subType: indexKey.value
    }
  ).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const ResetScroll = () => {
  pageSize.value = 50
}
onMounted(() => {
  if (props.info) {
    tabstatus.value[0].total = props.info.num
    if (props.info.subItemList) {
      props.info.subItemList.forEach((opt, index) => {
        let msg = {
          name: opt.title,
          total: opt.num,
          colorType: opt.colorType
        }
        tabstatus.value.push(msg)
      })
    }
  }
  indexKey.value = props.tableIdx
  reqOnWayTrack()
  nextTick(() => {
    const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', (event) => {
      if (dataSource.value.length === 0) return
      if (pageSize.value > data.value.length) return
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 滚动条触底，执行加载数据的操作
        pageSize.value += 50
        dataSource.value = data.value.slice(0, pageSize.value)
        // 这里可以调用你的加载数据函数
      }
    });
  })
})
const fromList = ref([
  {
    label: '所在地区',
    prop: "address",
    type: 'address',
    name: 'address',
    value: null,
    placeholder: '请选择',
    changeOn: true,
    rules: [{
      required: true,
      message: '请选择所在地区',
      trigger: 'blur',
      type: 'array'
    }]
  },
  {
    label: '详细地址',
    prop: "details",
    type: 'input',
    width: '100%',
    name: 'details',
    value: null,
    placeholder: '请输入',
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "车辆类型",
    value: null,
    prop: "truckType",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt: enumAll.state.nweTruckType
  },
  {
    type: "input",
    placeholder: "承运商名称",
    value: null,
    prop: "carrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "大车车牌号",
    value: null,
    prop: "carNo",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    width: 50,
    slots: {
      customRender: 'sort',
    }
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '大车车牌',
    dataIndex: 'carNo',
    align: 'center',
    width: 100,
    slots: {
      customRender: 'carNo'
    }
  },
  {
    title: '车辆类型',
    dataIndex: 'truckType.label',
    align: 'center',
    width: 100,
  },
  {
    title: '已装小车',
    dataIndex: 'vinNos',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'carrierName',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '超时信息',
    dataIndex: 'overdueText',
    align: 'center',
  },
  {
    title: '最新位置',
    dataIndex: 'location',
    align: 'center',
  },
  {
    title: '发车信息',
    dataIndex: 'dispatcher',
    slots: {
      customRender: 'dispatcher'
    },
    align: 'center',
  },
  {
    title: 'GPS类型',
    dataIndex: 'gpsType.label',
    align: 'center',
    width: 100,
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}
.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
.ant-typography {
  margin: 0;
}
</style>