<template>
  <div>
    <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <a-button type="primary" @click="addListStatus = true">+录入消费</a-button>
      </template>
    </SearchList>
    <div class="tableBox m-t1">
      <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="dataSource" bordered :pagination="pagination" :loading="loading" :scroll="{ y: 600 }" @change="handleTableChange">
      <template #sort="{index}">
        {{ index+1 }}
      </template>
      <template #add="{record}">
        <a @click="edit(record)">编辑</a>
      </template>
    </a-table>
    </div>
    <a-modal v-model:visible="editStatus" title="编辑" @ok="saveEdit" :confirmLoading="btnLoading">
      <a-form-item label="消费时间">
        <span style="color:#BCC1CA">{{editObj.consumptionDate}}</span>
      </a-form-item>
      <a-form-item label="搜索推广">
        <a-input v-model:value="editObj.expenditure" suffix="元" />
      </a-form-item>
    </a-modal>

    <a-modal v-model:visible="addListStatus" title="录入消费" @ok="addList" @cancel="channelChild = [],consumptionDate=null" :destroyOnClose="true" :confirmLoading="btnLoading">
      <a-form-item>
        <template #label>
          <div>
            消费日期<span style="color:#f00">*</span>
          </div>
        </template>
        <a-date-picker style="width:100%" v-model:value="consumptionDate" />
      </a-form-item>
      <a-form-item label="渠道分类">
        <a-select allowClear :options="$store.state.enumAll.ChannelTypeEnum" :changeOnSelect="true" @change="addListChange" placeholder="选择渠道" />
      </a-form-item>
      <a-form-item v-for="item in channelChild" :key="item.value" :label="item.label">
        <a-input v-model:value="item.expenditure" suffix="元" />
      </a-form-item>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import SearchList from '@/components/searchList'
import { getPromotionCostList, editData, addCost } from '@/api/crmManagement/drainageClue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { transitionTimeYMD, transitionTimeYM } from '@/utils/util'
import { cloneDeep } from 'lodash-es'
import moment from 'moment'

const btnLoading = ref(false)
const consumptionDate = ref(null)
const addListStatus = ref(false)
const editStatus = ref(false)
const store = useStore()
const loading = ref(false)
const dataSource = ref([])
const channel = ref({
  channelId: '',
  channelSubId: '',
})
const editObj = ref({
  consumptionDate: '',
  expenditure: '',
})
const search = ref({
  channelId: ''
})
const channelChild = ref([])
const searchMsg = ref()
const pagination = ref({
  current: 1,
  pageSizeOptions: ['10', '20', '50'],
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const addList = () => {
  if (!consumptionDate.value) {
    message.error('请先选择日期时间')
    return
  }
  let marketPromotionCostList = []
  channelChild.value.forEach((item, index) => {
    marketPromotionCostList.push({
      channelId: item.value,
      expenditure: item.expenditure
    })
  })
  let addData = {
    consumptionDate: transitionTimeYMD(consumptionDate.value),
    marketPromotionCostList: marketPromotionCostList
  }
  btnLoading.value = true
  addCost(addData)
    .then(res => {
      if (res.code === 10000) {
        message.success('设置' + res.msg)
        addListStatus.value = false
        channelChild.value = []
        loadData()
      }
    }).finally(() => {
      btnLoading.value = false
    })

}
const addListChange = (e, v) => {
  if (e.lengch > 1) search.value.channelId = e[1]
  v.children.forEach(item => {
    item.expenditure = ''
  })
  channelChild.value = v.children
}
const saveEdit = () => {
  btnLoading.value = true
  editData({ ...editObj.value })
    .then(res => {
      if (res.code === 10000) {
        message.success('修改' + res.msg)
        loadData()
        editStatus.value = false
      }
    }).finally(() => {
      btnLoading.value = false
    })
}
const edit = record => {
  editStatus.value = true
  editObj.value.consumptionDate = record.consumptionDate
  editObj.value.expenditure = record.expenditure
  editObj.value.id = record.id
  editObj.value.channelId = record.channelId
}
const handleTableChange = (pag, filters, sorter) => {
  pagination.value.current = pag.current
  pagination.value.pageSize = pag.pageSize
  loadData()
}
const searchAdd = (e) => {
  let msg = cloneDeep(e)
  if (msg.channel) {
    msg.channelId = msg.channel[0]
    msg.channelSubId = msg.channel.length > 1 ? msg.channel[1] : null
  }
  msg.consumptionDateStart = transitionTimeYMD(msg.consumptionDateStart)
  msg.consumptionDateEnd = transitionTimeYMD(msg.consumptionDateEnd)
  searchMsg.value = msg
  pagination.value.current = 1
  loadData()
}
const resetBtn = () => {
  searchMsg.value = {
    consumptionDateStart: transitionTimeYM(new Date()) + '-01',
    consumptionDateEnd: transitionTimeYMD(new Date()) 
  }
  pagination.value.current = 1
  loadData()
}
const loadData = () => {
  loading.value = true
  getPromotionCostList({
    current: pagination.value.current,
    size: pagination.value.pageSize,
    ...searchMsg.value
  }).then(res => {
    if (res.code === 10000) {
      dataSource.value = res.data.records
      pagination.value.total = res.data.total
    }
  }).catch(err => { console.log(err) })
    .finally(() => {
      loading.value = false
    })
}
onMounted(() => {
  searchMsg.value = {
    consumptionDateStart: transitionTimeYM(new Date()) + '-01',
    consumptionDateEnd: transitionTimeYMD(new Date()) 
  }
  loadData()
})
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '消费时间',
    align: 'center',
    dataIndex: 'consumptionDate'
  },
  {
    title: '渠道分类',
    align: 'center',
    dataIndex: 'channelName'
  },
  {
    title: '渠道名称',
    align: 'center',
    dataIndex: 'subChannelName'
  },
  {
    title: '推广消费(元)',
    align: 'center',
    dataIndex: 'expenditure'
  },
  {
    title: '录入人',
    align: 'center',
    dataIndex: 'operator',
  },
  {
    title: '录入时间',
    align: 'center',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    }
  }
])
const iptData = ref([
  {
    type: "selectTime",
    placeholder: "消费时间起",
    value: moment(transitionTimeYM(new Date()) + '-01'),
    prop: "consumptionDateStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "消费时间止",
    value: moment(new Date()),
    prop: "consumptionDateEnd",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channel",
    width: 200,
    changeOn: true,
    opt: store.state.enumAll.ChannelTypeEnum
  },
  {
    type: "input",
    placeholder: "录入人",
    value: null,
    prop: "operator",
    width: 200,
  },
])
</script>

<style lang="less" scoped>
.tableBox{
  padding: 10px;
  background: #fff;
}
</style>