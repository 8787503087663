<template>
  <div>
    <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="title" v-if="Object.keys(total).length !== 0">
      线索总数 <a>{{ total.clueTotal }}</a> 个&nbsp;&nbsp;
      总成交 <a>{{ total.clueDeal }}</a> 个&nbsp;&nbsp;
      总毛利 <a>￥{{ total.grossProfit }}</a> 元&nbsp;&nbsp;
      消费金额 <a>￥{{ total.promotionCostTotal }}</a>元&nbsp;&nbsp;
      投产比 <a>{{ total.productionRatio }}</a>
    </div>
      <div style="width: 100%; height:500px" id="main">

      </div>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import moment from 'moment'
import { ConversionStatistics, ConversionCelcan } from './echarts';
import SearchList from '@/components/searchList'
import { cvsStatistics } from '@/api/marketingConsole/marketingConsole'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es';
import { transitionTimeYM } from '@/utils/util'
import { message } from 'ant-design-vue';
const loading = ref(false)
const store = useStore()
const searchMsg = ref({})
const details = ref([])
const total = ref({})
const open = (e) => {

}

const resetBtn = () => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  reqCvsStatistics()
}
const searchAdd = (e) => {
  if (!e.month) {
    message.error('请先选择月份再进行搜索')
    return
  }
  let msg = cloneDeep(e)
  msg.month = transitionTimeYM(msg.month)
  if (msg.channelSubId) {
    msg.channelSubId = msg.channelSubId[1]
  }
  searchMsg.value = msg
  reqCvsStatistics()
}
const GenerateGraph = (details) => {
  let xname = [];
  let data1 = [];
  let data2 = [];
  let data3 = [];
  let data4 = [];
  details.forEach(item => {
    xname.push(item.xname)
    data1.push(item.clueDeal)
    data2.push(item.clueTotal)
    data3.push(item.rate)
    data4.push(item.grossProfit)
  })
  ConversionStatistics('main',  xname,data1,data2,data3,data4)
}
const reqCvsStatistics = () => {
  loading.value = true
  cvsStatistics({
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    total.value = res.data.total
    details.value = res.data.details
    GenerateGraph(details.value)
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  reqCvsStatistics()
})

const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "线索月份",
    value: moment(new Date()),
    prop: "month",
    width: 200,
  },
  {
    type: "select",
    placeholder: "统计方式",
    value: null,
    prop: "periodType",
    width: 200,
    opt: [
      { name: '按日', id: 3 },
      { name: '按周', id: 2 },
      { name: '按月', id: 1 }
    ]
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt: store.state.enumAll.ChannelTypeEnum
  },
  {
    type: "select",
    placeholder: "是否重复",
    value: null,
    prop: "isRepeat",
    width: 200,
    opt: [
      { name: '是', id: 1 },
      { name: '否', id: 0 }
    ]
  },
])
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  color: #6b778c;
  padding: 20px 10px;
  background: #fff;
  font-weight: 700;
}
</style>