<template>
  <div class="countTo" :style="{background:flag ? colorChange :'none'}">
    <countTo :startVal="startVal"  :style="{ color:color,fontSize:fontSize,fontWeight:700}"  :endVal="endVal" :duration="duration"></countTo>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { CountTo } from 'vue3-count-to'
const props = defineProps({
  color:{
    type:String,
    default:'red'
  },
  fontSize:{
   type:String,
    default:'16px'
  },
  endVal:{
    type:Number,
    default:10
  },
  duration:{
    type:Number,
    default:2000
  },
  loadingNum:{
    type:Number,
    default:1
  }
  })
  const colorChange = ref('')
const startVal = ref(0)
const flag = ref(false)
const resetBtn = () => {
  flag.value = true
  let timer = setTimeout(()=>{
    flag.value = false
    clearTimeout(timer)
  },2000)
}
watch(() => props.endVal,(val,oldVal)=> {
    startVal.value = oldVal
    if(val !== oldVal &&  props.loadingNum){
      if(val<oldVal){
        colorChange.value = '#00fd38'
      }else{
        colorChange.value = 'red'
      }
      resetBtn()
    }
})
</script>

<style lang="less" scoped>
.countTo{
  display: inline-block;
  padding: 5px;
}
.chenge{
  background: red;
}
</style>