<template>
  <div class="fz-14" >
    <template v-if="item.subTitle">
      <div class="m-t1 m-b1"><span @click="openList" class="cur-p">
        <CountTo color="#0066CC" fontSize="16px" :endVal="item.num" />
        &nbsp;{{ item.unit }}</span>{{item.title}}</div>
      <div style="color:#999">{{ item.subTitle }}</div>
      <div class="line"></div>
    </template>
    <template v-if="!item.subTitle">
      <div class=" flex ju-between al-center">{{item.title}}<span @click="openList(1)" class="cur-p">
        <CountTo color="#0066CC" fontSize="16px" :endVal="item.num" />&nbsp;{{ item.unit }}</span></div>
    </template>
    <div class="flex wrap">
      <div v-for="city in item.subItemList" :key="city.id" @click="openList(1,city)" class="cityBox cur-p">
        {{city.title}}&nbsp;&nbsp;<a>{{ city.num }}</a>
      </div>
    </div>
    <ComingMoadl :vehicleNumType="vehicleNumType" v-model:visible="visible" :cityObjectId="cityObjectId" :objectId="objectId" v-if="visible" :subItemList="subItemList"></ComingMoadl>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ComingMoadl from './components/ComingMoadl'
import CountTo from '@/components/countTo'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  subItemList:{
    type: Array,
    default: []
  },
 vehicleNumType:{
    type: Number,
    default: 0
 }
})
const visible = ref(false)
const objectId = ref(null)
const cityObjectId = ref(null)
const openList = (type,city) => {
  if(type === 1 ){
    objectId.value = props.item.objectId
    cityObjectId.value = city ? city.objectId : null
  }
  visible.value = true
}
</script>

<style lang="less" scoped>
.line {
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 8px 0;
}

.cityBox {
  padding: 2px 6px;
  border: 1px solid #eee;
  font-size: 12px;
  border-radius: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>