import { postBodyRequest, postRequest, getRequest } from '@/utils/axios'
// 中控台
export function consoleStatistics (params) {
  return postBodyRequest('/anal/clue-center-console/statistics', params)
}
// 线索列表
export function clueList (params) {
  return postBodyRequest('/anal/clue-center-console/clue-list', params)
}
// 渠道list
export function channelList (params) {
  return postBodyRequest('/market/channel/list', params)
}
// 热门推荐
export function hotRecommend (params) {
  return postBodyRequest('/anal/clue-center-console/hot-recommend', params)
}
// 转化统计
export function cvsStatistics(params) {
  return postBodyRequest('/anal/clue-center-console/conversion-statistics', params)
}
// 渠道tree
export function channelTree(params) {
  return getRequest('/market/channel/tree', params)
}
//参数配置配置参数
export function configAdd(params) {
  return postBodyRequest('/anal/clue-center-console/config-add', params)
}

// 线路查询
export function lineQueryPrice(params) {
  return postBodyRequest('/anal/clue-center-console/line-query-price', params)
}
// 业务统计
export function salesStatistics(params) {
  return postBodyRequest('/anal/clue-center-console/sales-statistics', params)
}
// 线索查询
export function cluPage (params) {
  return postBodyRequest('/market/crm/clue/page', params)
}
// 无效统计
export function salesInvalid (params) {
  return postBodyRequest('/anal/clue-center-console/sales-invalid', params)
}
// 业务员月指标-分页
export function mtcPage(params) {
  return postBodyRequest('/market/market-target-config/page', params)
}
// 业务员月指标-添加
export function mtcAdd(params) {
  return postBodyRequest('/market/market-target-config/add', params)
}
// 业务员月指标-编辑
export function mtcEdit(params) {
  return postBodyRequest('/market/market-target-config/edit', params)
}
// 查看
export function detailsId(id) {
  return postBodyRequest(`/market/market-target-config/details/${id}`)
}
// 指标-线索-消费 统计
export function targetClueConsumption(params) {
  return postBodyRequest(`/anal/clue-center-console/target-clue-consumption`,params)
}